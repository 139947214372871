<template>
  <div>
    <div class="container space-top">
      <div class="row-1-col">
        <div class="row-item">
          <div class="page-header">
            <h1>SCCM-3 Applikationsdistributioner</h1>
          </div>
          <div class="row-flex space-top-sm">
            <RFilter configName="veda" v-model="serverFilters.veda_ids" :width="'450'" />
            <RFilter configName="sccm_deployed_applications" v-model="serverFilters.deployed_applications" />
            <RFilter configName="sccm_deployed_application_states" v-model="serverFilters.states" />
            <RFilter configName="sccm_deployed_application_config" v-model="serverFilters.config" />
          </div>
        </div>
      </div>
      <div class="row-flex">
        <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data</a>
      </div>
      <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
      <span v-if="notFound && showData">Hittade inga distributioner</span>
      <div v-if="showData && !notFound">
        <div class="row-1-col space-top">
          <ValueBox class="row-item" :value="chartData.deviceCount" text="Antal distributioner" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
        </div>
        <div class="row-4-col space-top">
          <ValueBox class="row-item" :value="chartData.deviceCount_success" text="Lyckade" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <ValueBox class="row-item" :value="chartData.deviceCount_waiting" text="Pågående" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <ValueBox class="row-item" :value="chartData.deviceCount_reqNotMet" text="Förutsättningar ej uppfyllda" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <!-- <ValueBox
            class="row-item"
            :value="chartData.deviceCount_unknown"
            text="Okänd"
            valueColor="#5d237d"
            textColor="#5d237d"
            backgroundColor="#f5f3ee"
          /> -->
          <ValueBox class="row-item" :value="chartData.deviceCount_nonsuccess" text="Misslyckade" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
        </div>
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="tableData" :columns="columns" :disableFiltering="false" csvFilename="SCCM-3 - Export -Applikationsdistributioner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

export default {
  name: "Sccm3",
  props: { endpoint: String },
  data() {
    return {
      isLoading: false,
      tableData: [],
      crossfilter: null,
      chartData: {
        deviceCount: "0",
        deviceCount_success: "0",
        deviceCount_nonsuccess: "0",
        deviceCount_waiting: "0",
        // deviceCount_unknown: "0",
        deviceCount_reqNotMet: "0",
      },
      autoload: false,
      tableDataIsFor: null,

      columns: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "Verksamhetsid",
          show: false,
        },
        {
          property: "ApplicationName",
          displayName: "Applikationsnamn",
          show: true,
        },
        {
          property: "DeviceName",
          displayName: "Enhetsnamn",
          show: true,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
        },
        {
          property: "State",
          displayName: "Status",
          show: true,
        },
        {
          property: "StateMessage",
          displayName: "Utökad status",
          show: true,
        },
        {
          property: "DesiredConfigTypeLabel",
          displayName: "Händelse",
          show: true,
        },
        {
          property: "StartTime",
          displayName: "Starttid",
          show: true,
        },
      ],
      filters: {},
      serverFilters: {
        veda_ids: [],
        deployed_applications: [],
        states: [],
        config: [],
      },
    };
  },
  // watch: {
  //   async "serverFilters.veda_ids"() {
  //     await this.maybeUpdateData();
  //   },
  //   async "serverFilters.deployed_applications"() {
  //     await this.maybeUpdateData();
  //   },
  // },
  methods: {
    async maybeUpdateData() {
      if (this.autoload && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = {};
      if (currentFilters.veda_ids.length) {
        reqParams.veda_ids = currentFilters.veda_ids;
      }
      if (currentFilters.deployed_applications.length) {
        reqParams.deployed_applications = currentFilters.deployed_applications;
      }
      if (currentFilters.states.length) {
        reqParams.deployed_application_states = currentFilters.states;
      }
      if (currentFilters.config.length) {
        reqParams.deployed_application_config = currentFilters.config;
      }
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/sccm/deploymentresults", { params: reqParams });

      this.tableData = response.data;
      this.tableDataIsFor = currentFilters;

      await this.initCrossfilter();

      await this.calculateComponentsData();

      this.isLoading = false;
    },
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.tableData);
      this.crossfilter.createDimensionByProperty("StateDimension", "State");
    },
    calculateComponentsData() {
      const deviceCount = this.tableData.length;

      const deviceCount_success = this.crossfilter.countOnValue("StateDimension", "Lyckad", true);
      const deviceCount_nonsuccess = this.crossfilter.countOnValue("StateDimension", "Misslyckad", true);
      const deviceCount_waiting = this.crossfilter.countOnValue("StateDimension", "Pågående", true);

      // let deviceCount_unknown = this.crossfilter.countOnValue(
      //   "StateDimension",
      //   "Okänd",
      //   true,
      // );

      const deviceCount_reqNotMet = this.crossfilter.countOnValue("StateDimension", "Förutsättningar ej uppfyllda", true);
      const chartData = {
        deviceCount: deviceCount.toString(),
        deviceCount_success: deviceCount_success.toString(),
        deviceCount_nonsuccess: deviceCount_nonsuccess.toString(),
        deviceCount_waiting: deviceCount_waiting.toString(),
        // deviceCount_unknown: deviceCount_unknown.toString(),
        deviceCount_reqNotMet: deviceCount_reqNotMet.toString(),
      };

      this.chartData = chartData;
    },
  },
  computed: {
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor);
    },
    showLoadButton() {
      return !_.isEqual(this.serverFilters, this.tableDataIsFor) && !this.autoload;
    },
    notFound() {
      return !this.tableData.length;
    },
  },
  async mounted() {
    await this.maybeUpdateData();
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
