export default {
  releases: [
    {
      version: "1.19.0",
      date: "2024-11-04",
      notes: [
        "Nu visas även lokala nätverksdiskar i Rapporter över nätverksdiskar.",
        "Tabellexport genererar nu en .xlsx fil istället för .csv.",
        "Rapporten LEV-6 visar nu även rollerna BB och BG1-7.",
      ],
    },
    {
      version: "1.16.0",
      date: "2023-10-24",
      notes: ["Fixat fel där skrivare från PaperCut inte återfanns inte rapport Skrivare-3."],
    },
    {
      version: "1.15.4",
      date: "2023-10-18",
      notes: ["Fixat fel där roller tillhörande specifika verksamheter felaktigen inte visades i rapport LEV-3."],
    },
    {
      version: "1.15.3",
      date: "2023-06-21",
      notes: ["Rapport Skrivare-2 inkluderar nu skrivare från SLNX.", "Rapport Skrivare-2 exluderar nu borttagna skrivare."],
    },
    {
      version: "1.15.0",
      date: "2023-03-15",
      notes: ["Tog bort api-nyckeln från vyn Mitt Konto."],
    },
    {
      version: "1.14.1",
      date: "2022-12-12",
      notes: ["Löst problem med AD-5.", "Korrigerat AD-8 så den visar mindre dubletter."],
    },
    {
      version: "1.14.0",
      date: "2022-11-18",
      notes: ["Klass och årskurs är nya kolumner i AD-1 till AD-3.", "E-post visas nu alltid i samtliga AD-2-rapporter.", "Alla IN-rapporter har tagits bort."],
    },
    {
      version: "1.13.4",
      date: "2022-10-24",
      notes: ["Korrigerat så att SCCM-rapporterna visas även om den innehåller användare som tillhör andra verksamheter."],
    },
    {
      version: "1.13.1",
      date: "2022-05-16",
      notes: ["Mindre systemförändring."],
    },
    {
      version: "1.13.0",
      date: "2022-04-01",
      notes: ["Förbättrat datafiltrering."],
    },
    {
      version: "1.12.0",
      date: "2022-04-01",
      notes: ["Uppdaterat hur vi publiserar nya ändringar av rapportering till produktion."],
    },
    {
      version: "1.11.0",
      date: "2021-12-10",
      notes: ["Skapat ny rapport för statistik av skrivar-/kopiator-/faxnyttjande på användarnivå"],
    },
    {
      version: "1.10.0",
      date: "2021-10-27",
      notes: ["Internt byte av databas.", "Många små fixar."],
    },
    {
      version: "1.9.0",
      date: "2021-06-22",
      notes: [
        "Fixa problem med tidsformat i vissa G-Suite-, Intune- och SCCM-rapporter",
        "SCCM-3: Lägg till kolumner för Händelse och Starttid, och förtydliga rapportbeskrivning.",
        "Många små fixar.",
      ],
    },
    {
      version: "1.8.0",
      date: "2020-11-11",
      notes: ["Skrivare-1: Löst problem där vissa verksamheter inte såg några skrivare", "Skrivare-1: Löst problem där vissa verksamheter inte såg några skrivare"],
    },
    {
      version: "1.7.0",
      date: "2020-11-06",
      notes: ["DMNX-1 omdöpt till Skrivare-1", "DMNX-2 omdöpt till Skrivare-2"],
    },
    {
      version: "1.6.0",
      date: "2020-10-20",
      notes: ["Rapport DMNX-1 lanserad", "Rapport DMNX-2 lanserad", "SCCM-4: Korrekt filtrering enligt roller. Kolumnrubriker förtydligade."],
    },
    {
      version: "1.5.0",
      date: "2020-09-24",
      notes: ["SPV-556: Rapportbeskrivning för SCCM-2 uppdaterad för tydliggöra rapportens innehåll", "Rapport SCCM-3 lanserad", "SPV-560: Korrekt visning av epostadress"],
    },
    {
      version: "1.4.0",
      date: "2020-09-16",
      notes: ["SPV-560: IN-2 inkluderar opersonliga enheter", "Rapport LEV-3 lanserad", "SPV-564: Ta bort kolumn/fält för klass i AD-1, AD-2, AD-3 och AD-5 samt underrapporter"],
    },
    {
      version: "1.3.0",
      date: "2020-09-11",
      notes: [
        "SCCM-4: COS-nummer och Enhetsnamn länkar till AD7",
        "SPV-562 SCCM-4: Kolumnen Enhetsägarid har bytt namn till Användarid för att bättre reflektera kolumens innehåll",
        "Rapport Lev-6 lanserad",
        "SPV-563: Användare med CIS-roll kan nu se data i Intune rapporter",
        "SPV-503: AD-7, AD-8 och AD-8.1 visar primär användare tillsammans med varje enhet om exakt en sådan finns",
      ],
    },
    {
      version: "1.2.0",
      date: "2020-09-02",
      notes: [
        "SCCM-4: Lägg till COS-nummer",
        "IN-1, IN-2, CR-1 och CR-2.2: Lägg till fakturareferens.",
        "IN-2 och CR-2.2: Lägg till filter för fakturareferens.",
        "AD-7, AD-8 och AD-8.1: Ändra synkronisering till 1 gång/dygn. Lägg till COS-nummer, fakturareferens och status.",
        "AD-7: Visa primäranvändare.",
        "IN-2: Förtydliga etikett för sortering av stapeldiagram.",
        "AD-1, AD-2, AD-3, AD-5, samt vissa underrapporter: Lägg till telefonnummer.",
        "AD-1 och underrapporter: Lägg till länk till AD-8.",
        "AD-8: Lägg till filter för primär användare.",
        "Lägg till sök-funktionalitet i rapportlistan.",
        "CR-4: Namnändring till Förteckning över Google-konton",
      ],
    },
    {
      version: "1.1.0",
      date: "2020-08-17",
      notes: ["Rapport AD-9 lanserad."],
    },
    {
      version: "1.0.0",
      date: "2020-06-25",
      notes: ["Första allmänt tillgängliga versionen av rapportverktyget i produktion. Verktyget innehåller 53 rapporter. Vilka som är synliga beror på användarens behörigheter."],
    },
  ],
};
