<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>Skrivare-1 Skrivare</h1>
            </div>
            <div class="row-flex space-top-sm">
              <RFilter configName="veda" v-model="filters.veda.selected" />
              <RFilter configName="dmnx_model" v-model="filters.model.selected" />
            </div>
          </div>
        </div>
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="chartData.tableData" :columns="columns" :disableFiltering="false" csvFilename="Skrivare-1 Export" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

export default {
  name: "Dmnx1",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      chartData: null,
      columns: [
        {
          property: "Model",
          displayName: "Modell",
          show: false,
        },
        {
          property: "Manufacturer",
          displayName: "Tillverkare",
          show: false,
        },
        {
          property: "SubCategory",
          displayName: "Artikel",
          show: false,
        },
        {
          property: "SubCategoryName",
          displayName: "Beskrivning",
          show: true,
        },
        {
          property: "SubCategoryCapacity",
          displayName: "Volym/månad",
          show: true,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
        },
        {
          property: "IpAddress",
          displayName: "IP-adress",
          show: false,
        },
        {
          property: "VedaName",
          displayName: "Verksamhet",
          show: true,
        },
        {
          property: "LocationVeda",
          displayName: "VerksamhetsId",
          show: false,
        },
        {
          property: "CopyBlackCount",
          displayName: "Kopiering svartvitt",
          show: true,
        },
        {
          property: "CopyColorFullCount",
          displayName: "Kopiering färg",
          show: true,
        },
        {
          property: "CopyColorMonoCount",
          displayName: "Kopiering färg(mono)",
          show: false,
        },
        {
          property: "CopyColorTwinCount",
          displayName: "Kopiering färg(twin)",
          show: false,
        },
        {
          property: "PrintBlackCount",
          displayName: "Utskrifter svartvitt",
          show: true,
        },
        {
          property: "PrintColorFullCount",
          displayName: "Utskrifter färg",
          show: true,
        },
        {
          property: "PrintColorMonoCount",
          displayName: "Utskrifter färg(mono)",
          show: false,
        },
        {
          property: "PrintColorTwinCount",
          displayName: "Utskrifter färg(twin)",
          show: false,
        },
        {
          property: "TotalCount",
          displayName: "Kopiering/Utskrifter total",
          show: true,
        },
        {
          property: "TonerLevelBlack",
          displayName: "Toner black",
          show: true,
        },
        {
          property: "TonerLevelYellow",
          displayName: "Toner yellow",
          show: true,
        },
        {
          property: "TonerLevelCyan",
          displayName: "Toner cyan",
          show: true,
        },
        {
          property: "TonerLevelMagenta",
          displayName: "Toner magenta",
          show: true,
        },
      ],
      filters: {
        veda: { selected: [] },
        model: { selected: [] },
      },
    };
  },
  watch: {
    "filters.veda.selected"() {
      this.crossfilter.filterDimensionArrayContains("VedaIdDimension", this.filters.veda.selected);
      this.calculateReportComponentsData();
    },
    "filters.model.selected"() {
      this.crossfilter.filterDimensionArrayContains("ModelDimension", this.filters.model.selected);
      this.calculateReportComponentsData();
    },
  },
  methods: {
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.reportData);
      this.crossfilter.createDimensionByProperty("VedaIdDimension", "LocationVeda");
      this.crossfilter.createDimensionByProperty("ModelDimension", "Model");
    },
    calculateReportComponentsData() {
      const tableData = this.crossfilter.allFiltered();

      const chartData = { tableData };
      this.chartData = chartData;
    },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/dmnx/print")]).then(
      this.$http.spread(eq1Response => {
        this.reportData = eq1Response.data;
        this.initCrossfilter();
        this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
