<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="user_uid" name="Resursägares AnvändarID (UID)" width="330" qpsParamName="managedBy_uid" v-model="serverFilters.managedBy_uid" v-if="showFilter_user_uid" />
          <RFilter
            name="Resursägares namn"
            component="TextFilter"
            width="330"
            qpsEncodingMethod="Straight"
            qpsParamName="managedBy_name"
            :pattern="/^.{1,1000}$/"
            v-model="serverFilters.managedBy_displayName"
            v-if="showFilter_user_name"
          />
          <RFilter configName="veda" v-model="serverFilters.managedBy_veda_ids" v-if="showFilter_veda_ids" />
        </div>
      </div>
    </div>
    <div class="row-flex">
      <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <div v-if="showData" class="row-1-col space-top">
      <h2>Gruppdiskar</h2>
      <TablePaginated :tableData="tableData" :columns="volumeColumns" :disableFiltering="false" :csvFilename="csvFilename" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as adconf from "./AdViewConfig.js";

const variation = rpt => ({
  title: {
    Ad6: "AD-6 Förteckning över gruppdiskar",
    Ad6_1: "AD-6.1 Förteckning över gruppdiskar per verksamhet",
    Ad6_2: "AD-6.2 Förteckning över gruppdiskar per ägare",
  }[rpt],

  csvFilenameFun() {
    return {
      Ad6: "AD-6 Export Förteckning över gruppdiskar",
      Ad6_1: "AD-6.1 Export Förteckning över gruppdiskar per verksamhet",
      Ad6_2: "AD-6.2 Export Förteckning över gruppdiskar per ägare",
    }[rpt];
  },

  autoload: false,

  serverFilters: {},

  showFilter_user_uid: ["Ad6", "Ad6_2"].includes(rpt),
  showFilter_user_name: ["Ad6", "Ad6_2"].includes(rpt),
  showFilter_veda_ids: ["Ad6", "Ad6_1"].includes(rpt),

  volumeColumns: adconf.showColumns(adconf.volumeViewConfig, rpt, {
    cn: "yyy",
    distinguishedName: "hnn",
    description: "yyy",
    uNCName: "yhh",
    keywords: "hhh",
    whenChanged: "hhh",
    whenCreated: "hhh",
    managedByUid: "hyn",
    volumegroupSamR: "hnn",
    volumegroupSamRW: "hnn",
    volumegroupSamFULL: "hnn",
  }),
});

export default {
  name: "Ad6",
  data() {
    return _.defaultsDeep(variation(this.$route.name), {
      isLoading: false,
      serverFilters: {
        managedBy_uid: "",
        managedBy_displayName: "",
        managedBy_veda_ids: [],
      },
      filters: {},
      tableData: [],
      tableDataIsFor: null,
    });
  },
  watch: {
    async "serverFilters.managedBy_uid"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.managedBy_displayName"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.managedBy_veda_ids"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      if (this.autoload && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = {};
      if (currentFilters.managedBy_uid) {
        reqParams.managedBy_uids = [currentFilters.managedBy_uid];
      }
      if (currentFilters.managedBy_displayName) {
        reqParams.managedBy_displayName = currentFilters.managedBy_displayName;
      }
      if (currentFilters.managedBy_veda_ids) {
        reqParams.managedBy_veda_ids = currentFilters.managedBy_veda_ids;
      }
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/ad/volumes", { params: reqParams });
      this.tableData = response.data;
      this.tableDataIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor);
    },
    showLoadButton() {
      return !_.isEqual(this.serverFilters, this.tableDataIsFor) && !this.autoload;
    },
    csvFilename() {
      return this.csvFilenameFun();
    },
  },
  async mounted() {
    await this.maybeUpdateData();
  },
};
</script>

<style></style>
