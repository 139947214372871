<template>
  <div>
    <div class="container space-top">
      <div class="page-header">
        <h1>MDM-5 Förteckning över ASM applikationer</h1>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-4-col space-top">
          <ValueBox class="row-item" :value="chartData.applicationCount" text="Applikationer" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <ValueBox class="row-item" :value="chartData.removedFromAppStoreCount" text="Borttagna applikationer" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <!-- <ValueBox
            class="row-item"
            value="0"
            text="Antal unika enheter"
            valueColor="#5d237d"
            textColor="#5d237d"
            backgroundColor="#f5f3ee"
          />
          <ValueBox
            class="row-item"
            value="0"
            text="Antal unika enheter"
            valueColor="#5d237d"
            textColor="#5d237d"
            backgroundColor="#f5f3ee"
          />-->
        </div>
        <!-- <div class="row-1-col space-top">
          <div class="row-item">
            <h2>Operativsystem</h2>
            <BarChart
              class="padding-top-sm"
              :chartData="chartData.operatingSystemBarChartData"
              :displayXGridLines="false"
              :displayYGridLines="true"
              fontColor="#444444"
              gridLinesColor="#F1F1F1"
            />
          </div>
        </div>-->
        <div class="row-2-col space-top">
          <div class="row-item">
            <h2>Mest inköpta applikationer</h2>
            <table>
              <tr>
                <th>Namn</th>
                <th>Antal skolor som köpt</th>
              </tr>
              <tr v-for="(toppApp, index) in chartData.topApplications" v-bind:key="index">
                <td>{{ toppApp.key }}</td>
                <td>{{ toppApp.value }}</td>
              </tr>
            </table>
          </div>
          <div class="row-item">
            <h2>Mest installerade applikationer</h2>
            <table>
              <tr>
                <th>Namn</th>
                <th>Antal använda licenser</th>
              </tr>
              <tr v-for="(toppApp, index) in chartData.topApplicationsLicensedUsed" v-bind:key="index">
                <td>{{ toppApp.key }}</td>
                <td>{{ toppApp.value }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="chartData.tableData" :columns="columns" :disableFiltering="false" csvFilename="MDM-5 Export" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

const TOP_COUNT = 10;

export default {
  name: "In5",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      chartData: null,
      columns: [
        {
          property: "Name",
          displayName: "Namn",
          show: true,
        },
        {
          property: "Version",
          displayName: "Version",
          show: true,
        },
        {
          property: "MinimumOsVersion",
          displayName: "Kräver",
          show: true,
        },
        {
          property: "Price",
          displayName: "Pris",
          show: true,
        },
        {
          property: "TotalLicenses",
          displayName: "Licenser",
          show: true,
        },
        {
          property: "UsedLicenses",
          displayName: "Använda licenser",
          show: true,
        },
        {
          property: "FreeLicenses",
          displayName: "Tillgängliga licenser",
          show: true,
        },
        {
          property: "RemovedFromAppStore",
          displayName: "Borttagen från App Store",
          show: true,
        },
      ],
      //   filters: {
      //     model: {
      //       selected: [],
      //     },
      //     veda: {
      //       selected: [],
      //     },
      //   },
    };
  },
  //   watch: {
  //     "filters.veda.selected"() {
  //       this.crossfilter.filterDimensionArrayContains(
  //         "VedaIdDimension",
  //         this.filters.veda.selected,
  //       );
  //       this.calculateReportComponentsData();
  //     },
  //     "filters.model.selected"() {
  //       this.crossfilter.filterDimensionArrayContains(
  //         "ModelDimension",
  //         this.filters.model.selected,
  //       );
  //       this.calculateReportComponentsData();
  //     },
  //   },
  methods: {
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.reportData);

      this.crossfilter.createDimensionByProperty("NameDimension", "Name");

      this.crossfilter.createDimensionByProperty("RemovedFromAppStoreDimension", "RemovedFromAppStore");

      //   this.crossfilter.createDimensionByProperty(
      //     "OperatingSystemFullMajorVersionDimension",
      //     "OperatingSystemFullMajorVersion",
      //   );

      //   this.crossfilter.createDimensionByProperty(
      //     "OperatingSystemFullDimension",
      //     "OperatingSystemFull",
      //   );

      //   this.crossfilter.createDimensionByProperty("ModelDimension", "Model");
    },

    calculateReportComponentsData() {
      const tableData = this.crossfilter.allFiltered();

      //   var operatingSystemBarChartData = this.crossfilter.barCharReduceCount({
      //     dimensionName: "OperatingSystemFullDimension",
      //     sort: true,
      //     removeZeroValues: true,
      //   });

      const removedFromAppStoreCount = this.crossfilter.countOnValue("RemovedFromAppStoreDimension", true);

      const topApplications = this.crossfilter.groupReduceCount("NameDimension");

      const topApplicationsLicensedUsed = this.crossfilter.groupReduceSum("NameDimension", "UsedLicenses");

      const chartData = {
        tableData,
        applicationCount: tableData.length.toString(),
        removedFromAppStoreCount: removedFromAppStoreCount.toString(),
        topApplications: _.orderBy(topApplications, ["value"], ["desc"]).slice(0, TOP_COUNT),
        topApplicationsLicensedUsed: _.orderBy(topApplicationsLicensedUsed, ["value"], ["desc"]).slice(0, TOP_COUNT),
        // operatingSystemBarChartData: operatingSystemBarChartData,
        // latestIosCount: latestIosCount.toString(),
      };

      this.chartData = chartData;
    },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/mdm/asmApplications")]).then(
      this.$http.spread(intuneDevicesResponse => {
        this.reportData = intuneDevicesResponse.data;
        this.initCrossfilter();
        this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
