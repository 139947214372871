<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>Mitt konto</h1>
        </div>
      </div>
    </div>
    <table>
      <tbody>
        <tr>
          <td>Användarnamn</td>
          <td>{{ username }}</td>
        </tr>
        <tr>
          <td>Behörigheter</td>
          <td v-if="authorizations.length == 0">
            <i>Inga behörigheter</i>
          </td>
          <td v-else>
            <table>
              <thead>
                <tr>
                  <th>Roll-kod</th>
                  <th>Roll-typ</th>
                  <th>Roll-titel</th>
                  <th>Veda-ID</th>
                  <th>Fakturareferens</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="JSON.stringify([role, veda, invoiceref])" v-for="{ role, veda, invoiceref } in authorizations">
                  <td>{{ role }}</td>
                  <td>{{ roles_dict[role].type }}</td>
                  <td>{{ roles_dict[role].title }}</td>
                  <td>{{ veda }}</td>
                  <td>{{ invoiceref }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <!-- tr v-if="$store.getters.isGlobalAdmin">
          <td>DEBUG - Screenwidth</td>
          <td>{ { screenWidth } }</td>
        </tr -->
      </tbody>
    </table>
    <h2 v-show="canImpersonate || impersonated">Impersonation</h2>
    <div v-show="canImpersonate && !impersonated" class="row-flex">
      <TextFilter name="Användarnamn" width="230" :pattern="/^[A-Za-z0-9_.-]{2,20}$/" v-model="impersonateInput" />
      <a class="button-standard noselect" @click="doImpersonate">Impersonate</a>
    </div>
    <div v-show="impersonated" class="row-1-col">
      <div>
        <table>
          <tbody>
            <tr>
              <td>Autentiserad som användarID</td>
              <td>{{ impersonatedby }}</td>
            </tr>
            <tr>
              <td>Effektivt användarID</td>
              <td>{{ username }}</td>
            </tr>
            <tr>
              <td>Information</td>
              <td>
                Impersonation är aktivt. Du är inloggad med användarkonto
                <b>{{ impersonatedby }}</b> men kommer se rapporterna som om du vore inloggad med användarkonto <b>{{ username }}</b
                >, med följande undantag:
                <ul>
                  <li>Om {{ username }} har rollen CAS, får du ändå inte tillgång till den.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row-flex">
        <a class="button-standard noselect" @click="doEndImpersonation">Avsluta impersonation</a>
      </div>
    </div>
  </div>
</template>

<script>
import { roles } from "../../../shared";
const roles_dict = roles.roles_dict;

export default {
  name: "MyAccount",
  components: {},
  data() {
    return {
      roles_dict,
      impersonateInput: "",
    };
  },
  methods: {
    async doImpersonate() {
      const sam = this.impersonateInput;
      const response = await this.$http.get("/api/authentication/impersonate", { params: { sam } });
      const newtoken = response.data;
      this.$store.dispatch("impersonateUser", newtoken);
      //      this.impersonateInput = "";
    },
    doEndImpersonation() {
      this.$store.dispatch("endImpersonation");
    },
  },
  computed: {
    username() {
      return this.$store.getters.authenticatedUserSamAccountName;
    },
    authorizations() {
      return this.$store.getters.authenticatedUserAuthorizations;
    },
    API_token() {
      return this.$store.getters.authenticationToken;
    },
    impersonated() {
      return this.$store.getters.impersonated;
    },
    impersonatedby() {
      return this.$store.getters.authenticatedUserImpersonatedBy;
    },
    canImpersonate() {
      for (const authorization of this.authorizations) {
        if (authorization.role === "IMPERSONATE") {
          return true;
        }
      }
      return false;
    },
    // screenWidth() {
    //   return window.innerWidth;
    // },
  },
};
</script>
