"use strict";
const roles = require("./roles.js");

// Role test functions used by
// - ./authorizeRoles.js
// - ../backend/middlewares/authorizationRules/

// Allow FJ test user in dev, and customer's test users in production
exports.test = ro => ro.FJDEV || ro.COSTEST;

// Allow only FJ test user in dev
exports.dev = ro => ro.FJDEV;

// Allow anyone
exports.allowall = ignored__ro => true;

// Access categories.
// - test_category_X requires COSTEST access AND a role in the category.
// - category_X is meant for production that would allow all users with an
//   appropriate role access, with oru without COSTEST.

exports.roles_general = ro => roles.roles_general.some(r => ro[r]);
exports.dev_roles_general = ro => exports.dev(ro) && exports.roles_general(ro);
exports.test_roles_general = ro => exports.test(ro) && exports.roles_general(ro);
