<template>
  <div>
    <div class="row-flex space-bottom-sm">
      <RFilter
        configName="barchart_order_xy"
        :values="[
          { display: sortOnXLabel, value: 'x' },
          { display: sortOnYLabel, value: 'y' },
        ]"
        v-model="sort"
        v-if="enableSorting"
      />
    </div>
    <BarChartHelper :chart-data="datacollection" :options="options" chart-id="canvas-barchart" v-if="datacollection != null"></BarChartHelper>
  </div>
</template>

<script>

import * as _ from "lodash";
import BarChartHelper from "./BarChart.js";

const xSortingFormatDefault = function (arr) {
  return arr;
};

export default {
  props: {
    chartData: Object,
    displayXGridLines: Boolean,
    displayYGridLines: Boolean,
    fontColor: String,
    gridLinesColor: String,
    enableSorting: { type: Boolean, default: false },
    xSortingFormat: { type: Function, default: xSortingFormatDefault },
    sortOnXLabel: { type: String, default: "Sortera på X-värde" },
    sortOnYLabel: { type: String, default: "Sortera på Y-värde" },
  },
  components: { BarChartHelper },
  data() {
    return {
      yscale: "linear",
      sort: "",
      datacollection: null,
      options: null,
      barColors: ["#c40064", "#fdbbd9", "#00837c", "#c2eee7"],
    };
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    drawChart() {
      this.fillOptions();
      this.fillData();
    },

    fillOptions() {
      this.options = {
        scales: {
          yAxes: [
            {
              type: this.yscale,
              gridLines: {
                display: this.displayYGridLines,
                color: this.gridLinesColor,
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                beginAtZero: true,
                fontColor: this.fontColor,
                fontFamily: "'Open Sans', sans-serif",
                fontSize: 14,
                callback(value) {
                  return value.toLocaleString("SV");
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: this.displayXGridLines,
                color: this.gridLinesColor,
              },
              ticks: {
                autoSkip: true,
                // maxRotation: 0,
                // minRotation: 0,
                fontColor: this.fontColor,
                fontFamily: "'Open Sans', sans-serif",
                fontSize: 14,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: this.chartData.datasets.length > 1 },
        tooltips: {
          callbacks: {
            label(tooltipItem) {
              return tooltipItem.yLabel.toLocaleString("SV");
            },
          },
        },
      };
    },

    fillData() {
      const chartDatasets = [];

      this.chartData.datasets.forEach((item, index) => {
        chartDatasets.push({
          backgroundColor: this.barColors[index % this.barColors.length],
          hoverBackgroundColor: this.barColor,
          borderColor: "#333333",
          barPercentage: 0.9,
          maxBarThickness: 20,
          data: item.data,
          label: item.label,
        });
      });

      if (this.enableSorting && chartDatasets.length === 1) {
        const sortedCollections = _.zip(
          ..._.orderBy(_.zip(this.chartData.labels, this.xSortingFormat(this.chartData.labels), chartDatasets[0].data), ([ignored__x, xFormatted, y]) => (this.sort === "x" ? xFormatted : y), ["desc"]),
        );
        chartDatasets[0].data = sortedCollections[2];
        this.datacollection = {
          labels: sortedCollections[0],
          datasets: chartDatasets,
        };
      } else {
        this.datacollection = {
          labels: this.chartData.labels,
          datasets: chartDatasets,
        };
      }
    },
  },

  watch: {
    chartData() {
      this.drawChart();
    },
    sort() {
      this.drawChart();
    },
    yscale() {
      this.drawChart();
    },
  },
};
</script>

<style></style>
