<template>
  <div class="container space-top space-bottom">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>Behörighetsstyrning</h1>
        </div>
      </div>
    </div>
    <span>
      <h4>Behörighetsstyrning</h4>
      <p>
        Behörigheter i <a href="https://rapport.edu.stockholm.se" target="_blank">Rapportportalen</a> baseras på
        verksamhetens roller i <a href="https://33900.edu.stockholm.se" target="_blank">33900-portalen</a>.
      </p>
      <p>
        Det är verksamhetens IT-verksamhetsansvarig (ITVA) som administrerar verksamhetens lokala roller, och Central
        administratör staden (CAS) som administrerar de centrala rollerna i 33900-portalen.
      </p>
    </span>
    <span>
      <h4>Lokala roller</h4>
      <p>
        Följande roller har behörighet att se rapporter, användare, resurser, digitala enheter och skrivare för den
        eller de verksamheter där rollen innehas:
        <ul>
          <li>IT-verksamhetsansvarig (ITVA)</li>
          <li>IT-kontaktperson (ITKP)</li>
          <li>Behörighetsadministratör (BA)</li>
          <li>Behörig beställare (BB)</li>
          <li>Behörig godkännare (BG)</li>
          <li>Lokal uppföljning (LU)</li>
          <li>Lokalt IT-stöd (LIS)</li>
          <li>MDM-administratör (MDM)</li>
        </ul>
      </p>
    </span>
    <span>
      <h4>Centrala roller</h4>
      <p>
        Följande roller har behörighet att se rapporter, användare, resurser, digitala enheter och skrivare för samtliga
        verksamheter:
        <ul>
          <li>Central administratör staden (CAS)</li>
          <li>Centralt IT-stöd (CIS)</li>
          <li>Central uppföljning (CU)</li>
          <li>Central applikationsförvaltare (CA)</li>
        </ul>
      </p>
    </span>
    <span>
      <h4>Skyddade personuppgifter</h4>
      <p>
        Av säkerhetsskäl visas användare med skyddade personuppgifter inte i rapportportalen.
      </p>
    </span>
  </div>
</template>

<script>

export default {
  name: "AuthorizationRules",
};
</script>
