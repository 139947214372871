<template>
  <div>
    <div class="container space-top">
      <div class="page-header">
        <h1>MDM-7 Förteckning över MacOS enheter</h1>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="row-flex space-top-sm">
              <RFilter configName="veda" v-model="filters.veda.selected" />
              <RFilter configName="intune_macos_model" v-model="filters.model.selected" />
              <RFilter configName="intune_macos_osmajorversion" v-model="filters.osmajorversion.selected" />
            </div>
          </div>
        </div>
        <div class="row-4-col space-top">
          <ValueBox class="row-item" :value="chartData.deviceCount" text="Enheter" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <ValueBox class="row-item" :value="chartData.isStudentCount" text="Elevenheter" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <ValueBox class="row-item" :value="chartData.isEmployeeCount" text="Pesonalenheter" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <ValueBox class="row-item" :value="chartData.isNonpersonalCount" text="Opersonliga enheter" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <!-- <ValueBox
            class="row-item"
            :value="chartData.latestIosCount"
            text="Enheter med iOS 13.x"
            valueColor="#5d237d"
            textColor="#5d237d"
            backgroundColor="#f5f3ee"
          />-->
        </div>

        <div class="row-1-col space-top">
          <div class="row-item">
            <h2>Operativsystem</h2>
            <BarChart
              class="padding-top-sm"
              :chartData="chartData.operatingSystemBarChartData"
              :displayXGridLines="false"
              :displayYGridLines="true"
              fontColor="#444444"
              gridLinesColor="#F1F1F1"
              :enableSorting="true"
              :xSortingFormat="operatingSystemxSortingFormat"
            />
          </div>
        </div>
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="chartData.tableData" :columns="columns" :disableFiltering="false" csvFilename="MDM-7 Export" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

function routeToIn6(arg) {
  arg.root.$router.push({
    path: "/mdm6",
    query: {
      iossn: arg.object.Serialnumber,
      cos: arg.object.CosNumber ? arg.object.CosNumber : "",
    },
  });
}

export default {
  name: "Mdm7",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      chartData: null,
      columns: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaID",
          displayName: "Verksamhetsid",
          show: true,
        },
        {
          property: "model",
          displayName: "Modell",
          show: true,
        },
        {
          property: "OperatingSystemFull",
          displayName: "Operativsystem",
          show: true,
        },
        {
          property: "operating_system_build",
          displayName: "Build", // kompileringsnummer?
          show: true,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
          onclick: routeToIn6,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
          onclick: routeToIn6,
        },
        {
          property: "userPrincipalName",
          displayName: "Användare",
          show: true,
        },
        {
          property: "Enrolled",
          displayName: "Registrerad",
          show: true,
        },
        {
          property: "LastSynchronization",
          displayName: "Senast synkroniserad",
          show: true,
        },
      ],
      filters: {
        model: { selected: [] },
        veda: { selected: [] },
        osmajorversion: { selected: [] },
      },
    };
  },
  watch: {
    "filters.veda.selected"() {
      this.crossfilter.filterDimensionArrayContains("VedaIdDimension", this.filters.veda.selected);
      this.calculateReportComponentsData();
    },
    "filters.model.selected"() {
      this.crossfilter.filterDimensionArrayContains("ModelDimension", this.filters.model.selected);
      this.calculateReportComponentsData();
    },
    "filters.osmajorversion.selected"() {
      this.crossfilter.filterDimensionArrayContains("OperatingSystemFullMajorVersionFilterDimension", this.filters.osmajorversion.selected);
      this.calculateReportComponentsData();
    },
  },
  methods: {
    operatingSystemxSortingFormat(arr) {
      return arr.map(part =>
        part
          .split(" ")[1]
          .split(".")
          /* eslint-disable-next-line no-magic-numbers */
          .map(versionPart => `0000${versionPart}`.slice(-4))
          .join("."),
      );
    },

    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.reportData);

      this.crossfilter.createDimensionByProperty("VedaIdDimension", "VedaID");

      this.crossfilter.createDimensionByProperty("IsStudentDimension", "IsStudent");

      this.crossfilter.createDimensionByProperty("IsEmployeeDimension", "IsEmployee");

      this.crossfilter.createDimensionByProperty("IsNonpersonalDimension", "IsNonpersonal");

      this.crossfilter.createDimensionByProperty("OperatingSystemFullMajorVersionDimension", "OperatingSystemFullMajorVersion");

      this.crossfilter.createDimensionByProperty("OperatingSystemFullMajorVersionFilterDimension", "OperatingSystemFullMajorVersion");

      this.crossfilter.createDimensionByProperty("OperatingSystemFullDimension", "OperatingSystemFull");

      this.crossfilter.createDimensionByProperty("ModelDimension", "model");
    },

    calculateReportComponentsData() {
      const tableData = this.crossfilter.allFiltered();

      const operatingSystemBarChartData = this.crossfilter.barCharReduceCount({ dimensionName: "OperatingSystemFullDimension", sort: true, removeZeroValues: true });

      const isStudentCount = this.crossfilter.countOnValue("IsStudentDimension", 1);

      const isEmployeeCount = this.crossfilter.countOnValue("IsEmployeeDimension", 1);

      const isNonpersonalCount = this.crossfilter.countOnValue("IsNonpersonalDimension", 1);

      const latestIosCount = this.crossfilter.countOnValue("OperatingSystemFullMajorVersionDimension", "macOS 10.x");

      const chartData = {
        tableData,
        deviceCount: tableData.length.toString(),
        operatingSystemBarChartData,
        latestIosCount: latestIosCount.toString(),
        isStudentCount: isStudentCount.toString(),
        isEmployeeCount: isEmployeeCount.toString(),
        isNonpersonalCount: isNonpersonalCount.toString(),
      };

      this.chartData = chartData;
    },
  },
  computed: {},
  async mounted() {
    const intuneDevicesResponse = await this.$http.get("/api/reporting/mdm/macosDevices");
    this.reportData = intuneDevicesResponse.data;
    this.initCrossfilter();
    this.calculateReportComponentsData();
    this.isLoading = false;
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
