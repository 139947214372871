<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="device_cosnumber" v-model="filters.cos" />
          <RFilter name="DatorID (CN)" component="TextFilter" width="250" qpsEncodingMethod="Straight" qpsParamName="cn" :pattern="/^[A-Z0-9]{6,16}$/" v-model="filters.cn" />
        </div>
      </div>
    </div>
    <div class="row-flex">
      <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <span v-if="notSingle">{{ notSingleMessage }}</span>
    <div v-if="showData" class="row-1-col space-top">
      <h2>Data</h2>
      <TableSingleObject :tableData="dataobj" :fields="deviceFields" :csvFilename="csvFilename_single" showMissing />
    </div>
    <div v-if="showData" class="row-1-col space-top">
      <h3>Grupper</h3>
      <TablePaginated :tableData="dataobj.MemberOf_enriched" :columns="devicegroupColumns" :disableFiltering="false" :csvFilename="csvFilename_table_groups" paramPrefix="grptbl" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as adconf from "./AdViewConfig.js";
import * as adutils from "./AdUtils.js";

const variation = rpt => ({
  title: { Ad7: "AD-7 Enskild Windows-dator" }[rpt],
  csvprefix_single: { Ad7: "AD-7 Export Windows-dator " }[rpt],
  csvprefix_table_groups: { Ad7: "AD-7 Export Grupper för Windows-dator " }[rpt],
  csvprefix_table_primaryusers: { Ad7: "AD-7 Export Primära användare för Windows-dator " }[rpt],
  filters: {},
  noneMessage: { Ad7: "Ingen sådan Windows-dator kunde hittas." }[rpt],
  tooManyMessage: { Ad7: "Mer än en sådan Windows-dator hittades. Gör sökningen mer specifik." }[rpt],

  deviceFields: adconf.showFields(adconf.deviceViewConfig, rpt, {
    CosNumber: "y",
    cn: "y",
    distinguishedName: "y",
    whenChanged: "y",
    whenCreated: "y",
    dNSHostName: "y",
    lastLogonTimestamp: "y",
    logonCount: "y",
    operatingSystem: "y",
    operatingSystemVersion: "y",
    InvoiceReference: "y",
    InstallationStatus: "y",
    PrimaryUserDistinguishedName: "y",
    PrimaryUserUid: "y",
    PrimaryUserSn: "y",
    PrimaryUserGivenName: "y",
    PrimaryUserNumberOf: "y",
  }),

  devicegroupColumns: adconf.showColumns(adconf.devicegroupViewConfig, rpt, {
    displayName: "y",
    description: "y",
    sn: "h",
    sAMAccountName: "y",
    cn: "h",
    distinguishedName: "h",
    whenChanged: "h",
    whenCreated: "h",
    managedBy: "h",
  }),

  deviceprimaryuserColumns: adconf.showColumns(adconf.deviceprimaryuserViewConfig, rpt, {
    distinguishedName: "h",
    sn: "y",
    givenName: "y",
  }),
});

export default {
  name: "Ad7",
  data() {
    return _.defaultsDeep(variation(this.$route.name), {
      isLoading: false,
      filters: {
        cn: "",
        cos: "",
      },
      dataobj: null,
      dataobjIsFor: null,
    });
  },
  methods: {
    async updateData() {
      const currentFilters = _.cloneDeep(this.filters);
      const reqParams = {};
      let dosearch = false;
      if (currentFilters.cos) {
        reqParams.cosnumbers = [currentFilters.cos];
        dosearch = true;
      }
      if (currentFilters.cn) {
        reqParams.cns = [currentFilters.cn];
        dosearch = true;
      }
      // If filters are cleared then show nothing
      if (!dosearch) {
        this.dataobj = null;
        this.dataobjIsFor = null;
        return;
      }
      // Otherwise, fetch user object
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/adcached/devices", { params: { ...reqParams } });
      this.dataobj = adutils.single(response.data);
      if (_.isObject(this.dataobj)) {
        const response2 = await this.$http.get("/api/reporting/adcached/groupsfordevice", { params: { device_dn: this.dataobj.distinguishedName } });
        this.dataobj.MemberOf_enriched = response2.data;
      }
      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    notSingle() {
      return _.isEqual(this.filters, this.dataobjIsFor) && (this.dataobj === "none" || this.dataobj === "too many");
    },
    showData() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    notSingleMessage() {
      if (this.dataobj === "none") {
        return this.noneMessage;
      }
      if (this.dataobj === "too many") {
        return this.tooManyMessage;
      }
      return "";
    },
    showLoadButton() {
      return !_.isEqual(this.filters, this.dataobjIsFor);
    },
    csvFilename_single() {
      return this.csvprefix_single + (this.dataobj.CosNumber || this.dataobj.cn);
    },
    csvFilename_table_groups() {
      return this.csvprefix_table_groups + (this.dataobj.CosNumber || this.dataobj.cn);
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
