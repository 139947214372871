<template>
  <div class="container space-top space-bottom">
    <h1>Obehörig</h1>
  </div>
</template>

<script>


export default {
  name: "Unauthorized",
  components: {},
};
</script>
