<template>
  <div
    class="valuebox"
    :style="{
      backgroundColor: this.backgroundColor,
      cursor: clickable ? 'pointer' : 'auto',
    }"
    @click="
      if (clickable) {
        $emit('click');
      }
    "
  >
    <div class="sthlm-font-header space-top-sm valuebox-center-text" :style="{ color: this.valueColor }">
      {{ isNaN(this.value) ? this.value.toString() : Number(this.value).toLocaleString("SV") }}
    </div>
    <div class="space-bottom-sm valuebox-center-text" :style="{ color: this.textColor }">
      {{ this.text }}
    </div>
  </div>
</template>

<script>


export default {
  name: "ValueBox",
  props: {
    value: String,
    text: String,
    backgroundColor: String,
    valueColor: String,
    textColor: String,
    clickable: Boolean,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.valuebox {
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 10px;
}

.valuebox-center-text {
  text-align: center;
}
</style>
