<template>
  <div id="app" class="wrapper first">
    <div v-if="$store.getters.authenticated">
      <div id="imagepreload" style="display: none">
        <img :src="require('@/assets/images/sthlmhouses.jpg')" />
      </div>
      <Menubar></Menubar>
      <ErrorHandler />
      <router-view />
    </div>
  </div>
</template>

<script>


export default {};
</script>

<style>
@import "./assets/css/general.css";
@import "./assets/css/variables.css";
@import "./assets/css/background.css";
@import "./assets/css/font.css";
@import "./assets/css/container.css";
@import "./assets/css/row.css";
@import "./assets/css/table.css";
@import "./assets/css/button.css";
@import "./assets/css/textInput.css";
@import "./assets/css/link.css";
@import "./assets/css/badge.css";
@import "./assets/css/header.css";
@import "./assets/css/list.css";
@import "./assets/css/tab.css";
@import "./assets/css/alert.css";

body {
  margin: 0px;
}

.pointer {
  cursor: pointer;
}

/* RADIO */
.radio-group {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

/* ETC */
.space-bottom {
  margin-bottom: 40px;
}

.space-top {
  margin-top: 40px;
}

.space-bottom-sm {
  margin-bottom: 20px;
}

.space-top-sm {
  margin-top: 20px;
}

.padding-bottom {
  padding-bottom: 40px;
}

.padding-top {
  padding-top: 40px;
}

.padding-bottom-sm {
  padding-bottom: 20px;
}

.padding-top-sm {
  padding-top: 20px;
}
</style>
