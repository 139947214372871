<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="group_sam" v-model="serverFilters.group_sam" v-if="showFilter_group_sam" />
          <RFilter configName="group_dn" v-model="serverFilters.group_dn" v-if="showFilter_group_dn" />
          <RFilter configName="veda" v-model="serverFilters.veda_ids" v-if="showFilter_veda_ids" />
          <RFilter
            name="Kontokategori"
            component="ValueFilter"
            nodeEndpoint="/api/reporting/ad/filter/employeetype"
            width="300"
            qpsEncodingMethod="CommaSeparatedStrings"
            qpsParamName="et"
            v-model="serverFilters.employeeTypes"
            v-if="showFilter_employeetype"
          />
          <RFilter
            name="Klass"
            component="ValueFilter"
            :nodeEndpoint="'/api/reporting/ad/filter/class?veda_prefix=' + veda_prefix_class"
            width="400"
            qpsEncodingMethod="CommaSeparatedStrings"
            qpsParamName="class"
            v-model="serverFilters.class"
            v-if="showFilter_class"
          />
          <RFilter
            name="Kurs"
            component="ValueFilter"
            :nodeEndpoint="'/api/reporting/ad/filter/kurs?veda_prefix=' + veda_prefix_kurs"
            width="400"
            qpsEncodingMethod="CommaSeparatedStrings"
            qpsParamName="kurs"
            v-model="serverFilters.kurs"
            v-if="showFilter_kurs"
          />
          <RFilter
            name="Program"
            component="ValueFilter"
            :nodeEndpoint="'/api/reporting/ad/filter/program?veda_prefix=' + veda_prefix_program"
            width="400"
            qpsEncodingMethod="CommaSeparatedStrings"
            qpsParamName="program"
            v-model="serverFilters.program"
            v-if="showFilter_program"
          />
          <RFilter
            name="Årskurs"
            component="ValueFilter"
            :nodeEndpoint="'/api/reporting/ad/filter/ak?veda_prefix=' + veda_prefix_ak"
            width="400"
            qpsEncodingMethod="CommaSeparatedStrings"
            qpsParamName="ak"
            v-model="serverFilters.ak"
            v-if="showFilter_ak"
          />
          <RFilter
            name="Ämne"
            component="ValueFilter"
            :nodeEndpoint="'/api/reporting/ad/filter/amne?veda_prefix=' + veda_prefix_amne"
            width="400"
            qpsEncodingMethod="CommaSeparatedStrings"
            qpsParamName="amne"
            v-model="serverFilters.amne"
            v-if="showFilter_amne"
          />
          <RFilter
            name="Projekt"
            component="ValueFilter"
            :nodeEndpoint="'/api/reporting/ad/filter/proj?veda_prefix=' + veda_prefix_proj"
            width="400"
            qpsEncodingMethod="CommaSeparatedStrings"
            qpsParamName="proj"
            v-model="serverFilters.proj"
            v-if="showFilter_proj"
          />
        </div>
      </div>
    </div>
    <div class="row-flex">
      <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <div v-if="showData" class="row-1-col space-top">
      <h2>Användare</h2>
      <TablePaginated :tableData="tableData" :columns="userColumns" :disableFiltering="false" :csvFilename="csvFilename" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as adconf from "./AdViewConfig.js";

const variation = rpt => ({
  title: {
    Ad2: "AD-2 Förteckning över användarkonton",
    Ad2_1: "AD-2.1 Förteckning över konton för anställda (F)",
    Ad2_2: "AD-2.2 Förteckning över konton för anställda (V)",
    Ad2_3: "AD-2.3 Förteckning över elevkonton (E)",
    Ad2_4: "AD-2.4 Förteckning över konsultkonton (K)",
    Ad2_5: "AD-2.5 Förteckning över funktionskonton (O)",
    Ad2_6: "AD-2.6 Förteckning över användarkonton i en Gymnasie-Klass",
    Ad2_7: "AD-2.7 Förteckning över användarkonton i en Gymnasie-Kurs",
    Ad2_8: "AD-2.8 Förteckning över användarkonton i ett Gymnasie-Program",
    Ad2_9: "AD-2.9 Förteckning över användarkonton i en Gymnasie-Årskurs",
    Ad2_10: "AD-2.10 Förteckning över användarkonton i en Grundskole-Klass",
    Ad2_11: "AD-2.11 Förteckning över elevkonton i ett Grundskole-Ämne",
    Ad2_12: "AD-2.12 Förteckning över användarkonton i ett Grundskole-Projekt",
    Ad2_13: "AD-2.13 Förteckning över användarkonton i en Grundskole-Årskurs",
    Ad2_14: "AD-2.14 Förteckning över användarkonton i en AMF-Kurs",
  }[rpt],

  csvFilenameFun() {
    return {
      Ad2: "AD-2 Export Förteckning över användarkonton",
      Ad2_1: "AD-2.1 Export Förteckning över konton för anställda (F)",
      Ad2_2: "AD-2.2 Export Förteckning över konton för anställda (V)",
      Ad2_3: "AD-2.3 Export Förteckning över elevkonton (E)",
      Ad2_4: "AD-2.4 Export Förteckning över konsultkonton (K)",
      Ad2_5: "AD-2.5 Export Förteckning över funktionskonton (O)",
      Ad2_6: "AD-2.6 Export Förteckning över användarkonton",
      Ad2_7: "AD-2.7 Export Förteckning över användarkonton",
      Ad2_8: "AD-2.8 Export Förteckning över användarkonton",
      Ad2_9: "AD-2.9 Export Förteckning över användarkonton",
      Ad2_10: "AD-2.10 Export Förteckning över användarkonton",
      Ad2_11: "AD-2.11 Export Förteckning över elevkonton",
      Ad2_12: "AD-2.12 Export Förteckning över användarkonton",
      Ad2_13: "AD-2.13 Export Förteckning över användarkonton",
      Ad2_14: "AD-2.14 Export Förteckning över användarkonton",
    }[rpt];
  },

  autoload: false,

  serverFilters: {
    employeeTypes:
      {
        Ad2_1: ["f"],
        Ad2_2: ["v"],
        Ad2_3: ["e"],
        Ad2_4: ["k"],
        Ad2_5: ["o"],
      }[rpt] || [], // Do not restrict employeeTypes for AD-2,AD-2.6,...,AD-2.14
  },

  showFilter_group_sam: rpt === "Ad2",
  showFilter_group_dn: rpt === "Ad2",
  showFilter_veda_ids: ["Ad2", "Ad2_1", "Ad2_2", "Ad2_3", "Ad2_4", "Ad2_5"].includes(rpt),
  showFilter_employeetype: ["Ad2"].includes(rpt),
  showFilter_class: ["Ad2", "Ad2_6", "Ad2_10"].includes(rpt),
  showFilter_kurs: ["Ad2", "Ad2_7", "Ad2_14"].includes(rpt),
  showFilter_program: ["Ad2", "Ad2_8"].includes(rpt),
  showFilter_ak: ["Ad2", "Ad2_9", "Ad2_13"].includes(rpt),
  showFilter_amne: ["Ad2", "Ad2_11"].includes(rpt),
  showFilter_proj: ["Ad2", "Ad2_12"].includes(rpt),

  veda_prefix_class: { Ad2: "", Ad2_6: "117GYM", Ad2_10: "117GRU" }[rpt],
  veda_prefix_kurs: { Ad2: "", Ad2_7: "117GYM", Ad2_14: "191" }[rpt],
  veda_prefix_program: { Ad2: "", Ad2_8: "117GYM" }[rpt],
  veda_prefix_ak: { Ad2: "", Ad2_9: "117GYM", Ad2_13: "117GRU" }[rpt],
  veda_prefix_amne: { Ad2: "", Ad2_11: "117GRU" }[rpt],
  veda_prefix_proj: { Ad2: "", Ad2_12: "117GRU" }[rpt],

  userColumns: adconf.showColumns(adconf.userViewConfig, rpt, {
    sn: "yyyyyyyyyyyyyyy",
    givenName: "yyyyyyyyyyyyyyy",
    displayName: "hnnnnnnnnnnnnnn",
    description: "hnnnnynnnnnnnnn",
    employeeType: "ynnnnnnnnnnnnnn",
    homeDirectory: "hnnnnnnnnnnnnnn",
    homeDrive: "hnnnnnnnnnnnnnn",
    mobile: "hhhhhhhhhhhhhhh",
    lastLogonTimestamp: "hnnnnnnnnnnnnnn",
    logonCount: "hnnnnnnnnnnnnnn",
    whenChanged: "hnnnnnnnnnnnnnn",
    whenCreated: "hnnnnnnnnnnnnnn",
    sthlmIDMExtended7: "hnnnnnnnnnnnnnn",
    ManagerCN: "hhhnhnnnnnnnnnn",
    sAMAccountName: "hnnnnnnnnnnnnnn",
    cn: "hnnnynnnnnnnnnn",
    uid: "yyyynyhhhhhhhhh",
    uidNumber: "hnnnnnnnnnnnnnn",
    userPrincipalName: "yyyyyynnnnnnnnn",
    distinguishedName: "hnnnnnnnnnnnnnn",
    SI: "yhhhhhhhhhhhhhh",
    sthlmPersonnummer: "yhhhhhhhhhhhhhh",
    sthlmRPNR: "yhhhhhhhhhhhhhh",
    sthlmBefattningsKod: "hhhhhhnnnnnnnnn",
    sthlmKontoTyp: "ynnnnnnnnnnnnnn",
    company: "yhhhhhnnnnnnnnn",
    sthlmForvaltningsNr: "hhhhhhnnnnnnnnn",
    department: "yhhhhhhhhhhhhhh",
    sthlmVerksamhetsId: "hhhhhhhhhhhhhhh",
    sthlmFakturaRef: "yhhnhhnnnnnnnnn",
    sthlmSkolID: "ynnnnnnnnnnnnnn",
    telephoneNumber: "hhhhhhhhhhhhhhh",
    sthlmKlass: "ynnynnyyyyyyyyy",
    _sisSchoolGradeComputed: "ynnynnyyyyyyyyy",
  }),
});

export default {
  name: "Ad2",
  data() {
    return _.defaultsDeep(variation(this.$route.name), {
      isLoading: false,
      serverFilters: {
        group_sam: "",
        group_dn: "",
        veda_ids: [],
        class: [],
        kurs: [],
        program: [],
        ak: [],
        amne: [],
        proj: [],
      },
      filters: {},
      tableData: [],
      tableDataIsFor: null,
    });
  },
  watch: {
    async "serverFilters.group_sam"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.group_dn"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.veda_ids"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.employeeTypes"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.class"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.kurs"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.program"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.ak"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.amne"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.proj"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      if (this.autoload && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = { group_sam_andor: [] };
      if (currentFilters.group_sam) {
        reqParams.group_sam_andor.push([currentFilters.group_sam]);
      }
      if (currentFilters.group_dn) {
        reqParams.group_dns = [currentFilters.group_dn];
      }
      if (currentFilters.veda_ids.length) {
        reqParams.veda_ids = currentFilters.veda_ids;
      }
      if (currentFilters.employeeTypes.length) {
        reqParams.employeeTypes = currentFilters.employeeTypes;
      }
      if (currentFilters.class.length) {
        reqParams.group_sam_andor.push(currentFilters.class);
      }
      if (currentFilters.kurs.length) {
        reqParams.group_sam_andor.push(currentFilters.kurs);
      }
      if (currentFilters.program.length) {
        reqParams.group_sam_andor.push(currentFilters.program);
      }
      if (currentFilters.ak.length) {
        reqParams.group_sam_andor.push(currentFilters.ak);
      }
      if (currentFilters.amne.length) {
        reqParams.group_sam_andor.push(currentFilters.amne);
      }
      if (currentFilters.proj.length) {
        reqParams.group_sam_andor.push(currentFilters.proj);
      }
      if (reqParams.group_sam_andor.length === 0) {
        delete reqParams.group_sam_andor;
      }
      this.isLoading = true;
      const response = await this.$http.post("/api/reporting/ad/users", reqParams);
      this.tableData = response.data;
      this.tableDataIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor);
    },
    showLoadButton() {
      return !_.isEqual(this.serverFilters, this.tableDataIsFor) && !this.autoload;
    },
    csvFilename() {
      return this.csvFilenameFun();
    },
  },
  async mounted() {
    await this.maybeUpdateData();
  },
};
</script>

<style></style>
