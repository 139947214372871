<template>
  <div>
    <div class="container space-top">
      <div class="page-header">
        <h1>MDM-4 Förteckning över ASM tokens</h1>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="chartData.tableData" :columns="columns" :disableFiltering="false" csvFilename="MDM-4 Export" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

function routeToMdm3(arg) {
  arg.root.$router.push({
    path: "/mdm3",
    query: { tokenid: arg.clickedValue },
  });
}

export default {
  name: "Mdm4",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      chartData: null,
      columns: [
        {
          property: "TokenId",
          displayName: "Id",
          show: true,
          onclick: routeToMdm3,
        },
        {
          property: "Name",
          displayName: "Namn",
          show: true,
        },
        {
          property: "Location",
          displayName: "Lokation",
          show: true,
        },
        {
          property: "LastSynchronizationDatetime",
          displayName: "Senast synkroniserad",
          show: true,
        },
        {
          property: "Expiration",
          displayName: "Giltig till",
          show: true,
        },
      ],
      //   filters: {
      //     model: {
      //       selected: [],
      //     },
      //     veda: {
      //       selected: [],
      //     },
      //   },
    };
  },
  //   watch: {
  //     "filters.veda.selected"() {
  //       this.crossfilter.filterDimensionArrayContains(
  //         "VedaIdDimension",
  //         this.filters.veda.selected,
  //       );
  //       this.calculateReportComponentsData();
  //     },
  //     "filters.model.selected"() {
  //       this.crossfilter.filterDimensionArrayContains(
  //         "ModelDimension",
  //         this.filters.model.selected,
  //       );
  //       this.calculateReportComponentsData();
  //     },
  //   },
  methods: {
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.reportData);

      //   this.crossfilter.createDimensionByProperty("VedaIdDimension", "VedaId");

      //   this.crossfilter.createDimensionByProperty(
      //     "OperatingSystemFullMajorVersionDimension",
      //     "OperatingSystemFullMajorVersion",
      //   );

      //   this.crossfilter.createDimensionByProperty(
      //     "OperatingSystemFullDimension",
      //     "OperatingSystemFull",
      //   );

      //   this.crossfilter.createDimensionByProperty("ModelDimension", "Model");
    },

    calculateReportComponentsData() {
      const tableData = this.crossfilter.allFiltered();
      //   var operatingSystemBarChartData = this.crossfilter.barCharReduceCount({
      //     dimensionName: "OperatingSystemFullDimension",
      //     sort: true,
      //     removeZeroValues: true,
      //   });

      //   var latestIosCount = this.crossfilter.countOnValue(
      //     "OperatingSystemFullMajorVersionDimension",
      //     "iOS 13.x",
      //   );

      const chartData = {
        tableData,
        // deviceCount: tableData.length.toString(),
        // operatingSystemBarChartData: operatingSystemBarChartData,
        // latestIosCount: latestIosCount.toString(),
      };

      this.chartData = chartData;
    },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/mdm/asmTokens")]).then(
      this.$http.spread(intuneDevicesResponse => {
        this.reportData = intuneDevicesResponse.data;
        this.initCrossfilter();
        this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
