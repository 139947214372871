<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>Skrivare-3 Skrivar-/kopiatornyttjande på användarnivå</h1>
            </div>
            <div class="row-2-col space-top-sm">
              <div class="col">
                <h3>Välj verksamhet</h3>
                <RFilter configName="veda" v-model="filters.veda" />
              </div>
              <div class="col">
                <h3>Gruppera</h3>
                <CheckBox style="position: relative; top: 2px; margin-right: 6px" @click="toggleTotalJobMonth()" :value="this.filters.totals.JobMonth" display="Månad" />
                <CheckBox style="position: relative; top: 2px; margin-right: 6px" @click="toggleTotalJobType()" :value="this.filters.totals.JobType" display="Jobbtyp" />
                <CheckBox style="position: relative; top: 2px; margin-right: 6px" @click="toggleTotalDevice()" :value="this.filters.totals.Device" display="Enhet" />
                <CheckBox style="position: relative; top: 2px; margin-right: 6px" @click="toggleTotalUser()" :value="this.filters.totals.User" display="Användare" />
                <CheckBox style="position: relative; top: 2px; margin-right: 6px" @click="toggleTotalDeviceLocation()" :value="this.filters.totals.DeviceLocation" display="VEDA" />
                <span class="description"
                  >Välj vilka attribut som ska grupperas ihop i rapporten. Väljer du t ex att gruppera ihop <i>Jobbtyp</i> kommer samtliga utskriftsjobb (pullprint, pushprint & copy) att grupperas
                  ihop och presenteras för varje enskild skrivare och användare.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row-flex">
          <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
        </div>
        <LoadingSpinner :isLoading="isLoading" />
        <div v-if="showData" class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="reportData" :columns="columns" :disableFiltering="false" csvFilename="Skrivare-3 Export" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.row-item h3,
.row-item span {
  width: 100%;
  display: block;
}
.description {
  margin-top: 15px;
}
div.checkbox {
  margin-top: 10px;
  display: inline !important;
  z-index: 0 !important;
}
</style>

<script>
import * as _ from "lodash";

export default {
  name: "Dmnx3",
  props: { endpoint: String },
  data() {
    return {
      isLoading: false,
      reportData: [],
      reportDataIsFor: null,
      columns: [
        {
          property: "JobMonth",
          displayName: "Månad",
          show: true,
        },
        {
          property: "JobType",
          displayName: "Jobbtyp",
          show: true,
        },
        {
          property: "DeviceSerialNumber",
          displayName: "Serienummer",
          show: true,
        },
        {
          property: "DeviceNames",
          displayName: "Enhetsnamn",
          show: false,
        },
        {
          property: "DeviceLocation",
          displayName: "VEDA",
          show: true,
        },
        {
          property: "UserUid",
          displayName: "Användarnamn",
          show: true,
        },
        {
          property: "UserDisplayName",
          displayName: "Användare",
          show: true,
        },
        {
          property: "ColoredJobCount",
          displayName: "Antal jobb i färg",
          show: false,
        },
        {
          property: "MonochromeJobCount",
          displayName: "Antal monokroma jobb",
          show: false,
        },
        {
          property: "TotalJobCount",
          displayName: "Antal jobb totalt",
          show: true,
        },
        {
          property: "ColoredPageCount",
          displayName: "Antal sidor i färg",
          show: false,
        },
        {
          property: "MonochromePageCount",
          displayName: "Antal monokroma sidor",
          show: false,
        },
        {
          property: "TotalPageCount",
          displayName: "Antal sidor totalt",
          show: true,
        },
      ],
      filters: {
        veda: [],
        totals: {
          JobMonth: false,
          JobType: true,
          Device: false,
          User: false,
          DeviceLocation: false,
        },
      },
    };
  },
  watch: {},
  methods: {
    toggleTotalJobMonth() {
      this.filters.totals.JobMonth = !this.filters.totals.JobMonth;
    },
    toggleTotalJobType() {
      this.filters.totals.JobType = !this.filters.totals.JobType;
    },
    toggleTotalDevice() {
      this.filters.totals.Device = !this.filters.totals.Device;
    },
    toggleTotalUser() {
      this.filters.totals.User = !this.filters.totals.User;
    },
    toggleTotalDeviceLocation() {
      this.filters.totals.DeviceLocation = !this.filters.totals.DeviceLocation;
    },
    async updateData() {
      this.isLoading = true;
      const currentFilters = _.cloneDeep(this.filters);

      const params = {};
      if (currentFilters.veda.length) {
        params.veda = currentFilters.veda;
      }
      params.totals = currentFilters.totals;

      const statisticsResponse = await this.$http.post("/api/reporting/dmnx/printStatistics", params);

      this.reportData = statisticsResponse.data;
      this.reportDataIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    showData() {
      return _.isEqual(this.filters, this.reportDataIsFor) && _.isArray(this.reportData);
    },
    showLoadButton() {
      return !_.isEqual(this.filters, this.reportDataIsFor);
    },
  },
};
</script>
