import Cr1 from "../views/reports/gsuite/Cr1.vue";
import Cr21 from "../views/reports/gsuite/Cr2-1.vue";
import Cr22 from "../views/reports/gsuite/Cr2-2.vue";
import Cr3 from "../views/reports/gsuite/Cr3.vue";
import Cr4 from "../views/reports/gsuite/Cr4.vue";
import Cr5 from "../views/reports/gsuite/Cr5.vue";
import Cr6 from "../views/reports/gsuite/Cr6.vue";
// import Devices1 from "../views/reports/devices/devices1.vue";
import Dmnx1 from "../views/reports/dmnx/Dmnx1.vue";
import Dmnx2 from "../views/reports/dmnx/Dmnx2.vue";
import Dmnx3 from "../views/reports/dmnx/Dmnx3.vue";
import Lev2 from "../views/reports/delivery/Lev2.vue";
import Lev3 from "../views/reports/delivery/Lev3.vue";
import Lev4 from "../views/reports/delivery/Lev4.vue";
import Lev6 from "../views/reports/delivery/Lev6.vue";
import Reporting from "../views/Reporting.vue";
import Sccm1 from "../views/reports/sccm/Sccm1.vue";
import Sccm2 from "../views/reports/sccm/Sccm2.vue";
import Sccm3 from "../views/reports/sccm/Sccm3.vue";
import Sccm4 from "../views/reports/sccm/Sccm4.vue";
import Mdm1 from "../views/reports/mdm/Mdm1.vue";
import Mdm2 from "../views/reports/mdm/Mdm2.vue";
import Mdm3 from "../views/reports/mdm/Mdm3.vue";
import Mdm4 from "../views/reports/mdm/Mdm4.vue";
import Mdm5 from "../views/reports/mdm/Mdm5.vue";
import Mdm6 from "../views/reports/mdm/Mdm6.vue";
import Mdm7 from "../views/reports/mdm/Mdm7.vue";

export default function () {
  return [
    {
      path: "/",
      redirect: "/reporting",
      meta: { allowWithoutLogin: true },
    },
    {
      name: "Auth",
      path: "/auth",
      meta: { allowWithoutLogin: true },
    },
    {
      name: "Auth_return",
      path: "/auth_return",
      meta: { allowWithoutLogin: true },
    },
    {
      path: "/reporting",
      name: "Reporting",
      component: Reporting,
    },
    {
      path: "/cr1",
      name: "Cr1",
      component: Cr1,
    },
    {
      path: "/cr2.1",
      name: "Cr2-1",
      component: Cr21,
    },
    {
      path: "/cr2.2",
      name: "Cr2-2",
      component: Cr22,
    },
    {
      path: "/cr3",
      name: "Cr3",
      component: Cr3,
    },
    {
      path: "/cr4",
      name: "Cr4",
      component: Cr4,
    },
    {
      path: "/cr5",
      name: "Cr5",
      component: Cr5,
    },
    {
      path: "/cr6",
      name: "Cr6",
      component: Cr6,
    },
    {
      path: "/lev2",
      name: "Lev2",
      component: Lev2,
    },
    {
      path: "/lev3",
      name: "Lev3",
      component: Lev3,
    },
    {
      path: "/lev4",
      name: "Lev4",
      component: Lev4,
    },
    {
      path: "/lev6",
      name: "Lev6",
      component: Lev6,
    },
    {
      path: "/sccm1",
      name: "Sccm1",
      component: Sccm1,
    },
    {
      path: "/sccm2",
      name: "Sccm2",
      component: Sccm2,
    },
    {
      path: "/sccm3",
      name: "Sccm3",
      component: Sccm3,
    },
    {
      path: "/sccm4",
      name: "Sccm4",
      component: Sccm4,
    },
    {
      path: "/dmnx1",
      name: "Dmnx1",
      component: Dmnx1,
    },
    {
      path: "/dmnx2",
      name: "Dmnx2",
      component: Dmnx2,
    },
    {
      path: "/dmnx3",
      name: "Dmnx3",
      component: Dmnx3,
    },
    {
      path: "/mdm1",
      name: "Mdm1",
      component: Mdm1,
    },
    {
      path: "/mdm2",
      name: "Mdm2",
      component: Mdm2,
    },
    {
      path: "/mdm3",
      name: "Mdm3",
      component: Mdm3,
    },
    {
      path: "/mdm4",
      name: "Mdm4",
      component: Mdm4,
    },
    {
      path: "/mdm5",
      name: "Mdm5",
      component: Mdm5,
    },
    {
      path: "/mdm6",
      name: "Mdm6",
      component: Mdm6,
    },
    {
      path: "/mdm7",
      name: "Mdm7",
      component: Mdm7,
    },
  ];
}
