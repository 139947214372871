<template>
  <div>
    <div class="container space-top">
      <div class="row-1-col">
        <div class="row-item">
          <div class="page-header">
            <h1>SCCM-4 Inloggningstider</h1>
          </div>
          <div class="row-flex space-top-sm">
            <RFilter configName="veda" v-model="serverFilters.veda_ids" />
            <RFilter configName="sccm_device_types" v-model="serverFilters.device_types" />
            <DatePeriodSelector style="margin-left: auto" v-model="filters.loginPeriod" name="Senaste inloggning" qpsParamName="lastlogindate" />
          </div>
        </div>
      </div>
      <div class="row-flex">
        <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data</a>
      </div>
      <div v-if="showData && !notFound" class="row-4-col space-top">
        <ValueBox class="row-item" :value="data.averageSessionTime" text="Medel logon tid i minuter" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
        <ValueBox class="row-item" :value="data.totalLogins" text="Antal logon" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
        <ValueBox class="row-item" :value="data.totalDevices" text="Antal datorer" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
      </div>
      <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
      <span v-if="notFound && showData">Hittade ingen data</span>
      <div v-if="showData && !notFound">
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="tableData" :columns="columns" :disableFiltering="false" csvFilename="SCCM-4 Export" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

function routeCosToAd7(arg) {
  arg.root.$router.push({
    path: "/ad7",
    query: { cos: arg.object.CosNumber },
  });
}

function routeDeviceNameToAd7(arg) {
  arg.root.$router.push({
    path: "/ad7",
    query: { cn: arg.object.DeviceName },
  });
}

export default {
  name: "Sccm4",
  props: { endpoint: String },
  data() {
    return {
      isLoading: false,
      tableData: [{ LastLoginTime: "" }],
      crossfilter: null,
      autoload: false,
      tableDataIsFor: null,
      data: {
        averageSessionTime: "0",
        totalDevices: "0",
        totalLogins: "0",
      },
      columns: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn (för användare)",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "Verksamhetsid (för användare)",
          show: false,
        },
        {
          property: "UserId",
          displayName: "AnvändarId",
          show: true,
        },
        {
          property: "DeviceName",
          displayName: "Enhetsnamn",
          show: true,
          onclick: routeDeviceNameToAd7,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
          onclick: routeCosToAd7,
        },
        {
          property: "DeviceType",
          displayName: "Enhetstyp",
          show: true,
        },
        {
          property: "NumberOfLogins",
          displayName: "Antal inloggningar",
          show: true,
        },
        {
          property: "LastLoginTime",
          displayName: "Senaste inloggning",
          show: true,
        },
        {
          property: "TotalMinutesUsed",
          displayName: "Totala minuter inloggad",
          show: true,
        },
        {
          property: "AverageMinutesPerLogin",
          displayName: "Medel minuter inloggad",
          show: true,
        },
        {
          property: "AverageHoursPerLogin",
          displayName: "Medel timmar inloggad",
          show: true,
        },
      ],
      filters: { loginPeriod: [] },
      serverFilters: {
        veda_ids: [],
        device_types: [],
      },
    };
  },
  watch: {
    async "serverFilters.veda_ids"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.device_types"() {
      await this.maybeUpdateData();
    },
    async "filters.loginPeriod"() {
      await this.applyLoginPeriodFilter();
    },
  },
  methods: {
    applyLoginPeriodFilter() {
      if (this.crossfilter) {
        this.crossfilter.filterDimensionArrayContains("LastLoginTimeDimension", this.filters.loginPeriod);
        this.calculateReportComponentsData();
      }
    },
    async maybeUpdateData() {
      if (this.autoload && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = {};
      if (currentFilters.veda_ids.length) {
        reqParams.veda_ids = currentFilters.veda_ids;
      }
      if (currentFilters.device_types.length) {
        reqParams.device_types = currentFilters.device_types;
      }

      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/sccm/logintelemetry", { params: reqParams });

      this.tableData = response.data;
      this.tableDataIsFor = currentFilters;
      this.isLoading = false;
      this.initCrossfilter();
      this.applyLoginPeriodFilter();
    },

    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.tableData);
      this.crossfilter.createDimensionByMomentOnProperty("LastLoginTimeDimension", "LastLoginTime", "YYYY-MM-DD", "YYYY-MM-DD");
    },

    calculateReportComponentsData() {
      this.tableData = this.crossfilter.allFiltered();

      const totalUsage = this.tableData.reduce((a, b) => a + b.NumberOfLogins, 0);

      const averageUsageDuration = this.tableData.reduce((a, b) => a + b.TotalMinutesUsed, 0) / totalUsage;

      const distinctDevices = [...new Set(this.tableData.map(x => x.DeviceName))];

      const data = {
        averageSessionTime: averageUsageDuration.toFixed(0),
        totalLogins: totalUsage.toString(),
        totalDevices: distinctDevices.length.toString(),
      };

      this.data = data;
    },

    // loginDatePeriodFilterChanged(activeDatePeriodFilter) {
    //   this.filters.loginPeriod.start = activeDatePeriodFilter.start;
    //   this.filters.loginPeriod.end = activeDatePeriodFilter.end;

    //   var days = momentHandler.daysBetweenDates({
    //     startDateString: this.filters.loginPeriod.start,
    //     endDateString: this.filters.loginPeriod.end,
    //     inputFormat: "YYYY-MM-DD",
    //     labelFormat: "YYYY-MM-DD",
    //     valueFormat: "YYYY-MM-DD",
    //   });

    //   this.crossfilter.startsWithfilterDimensionArray(
    //     "LastLoginTimeDimension",
    //     days.map(item => item.value),
    //   );
    //   this.calculateReportComponentsData();
    // },
  },
  computed: {
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor);
    },
    showLoadButton() {
      return !_.isEqual(this.serverFilters, this.tableDataIsFor) && !this.autoload;
    },
    // days(){
    //   return momentHandler.daysBetweenDates({
    //     startDateString: this.filters.loginPeriod.start,
    //     endDateString: this.filters.loginPeriod.end,
    //     inputFormat: "YYYY-MM-DD",
    //     labelFormat: "YYYY-MM-DD",
    //     valueFormat: "YYYY-MM-DD",
    //   }).map(item => item.value) ||[];
    // },
    notFound() {
      return !this.tableData.length;
    },
  },
  async mounted() {
    await this.maybeUpdateData();
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
