"use strict";
const moment = require("moment");
const futile = require("@fujitsusweden/futile");

const momentHandler = module.exports;

const undef = {}.undef;
const goodInputFormats = new Set(["YYYY-MM-DD"]);
const goodReturnFormats = new Set([
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD HH:mm",
  "YYYY-MM-DD",
  "YYYY-MM",
  "HH:mm",
]);
function assertInputFormat(inputFormat) {
  if (!goodInputFormats.has(inputFormat)) {
    throw futile.err("Bad format!", { inputFormat });
  }
}
function assertReturnFormat(returnFormat) {
  if (!goodReturnFormats.has(returnFormat)) {
    if ((/hh/u).exec(returnFormat)) {
      throw futile.err("Bad format! `hh` is 12-based hours, use `HH` instead!", { returnFormat });
    }
    if ((/HH:MM/u).exec(returnFormat)) {
      throw futile.err("Bad format! `MM` is month, `mm` is minutes!", { returnFormat });
    }
    throw futile.err("Bad format!", { returnFormat });
  }
}

/**
 * @description Create an array of date-objects between two dates
 * month-object: { label: "", value: "" }
 *
 * @param {string} startDateString Start date
 * @param {string} endDateString End date
 * @param {string} inputFormat Input format for startDateString and endDateString
 * @param {string} labelFormat Output format for label attribute
 * @param {string} valueFormat Output format for value attribute
 * @param {string} mode day or month
 *
 * @returns {list}
 */
function datesBetweenDates({ startDateString, endDateString, inputFormat, labelFormat, valueFormat, mode }) {
  assertInputFormat(inputFormat);
  assertReturnFormat(labelFormat);
  assertReturnFormat(valueFormat);
  const dateStart = moment(startDateString, inputFormat);
  const dateEnd = moment(endDateString, inputFormat);
  const interim = dateStart.clone();
  const timeValues = [];
  /* eslint-disable-next-line no-unmodified-loop-condition */ // ESLint doesn't recognize that interim is modified.
  while (dateEnd > interim || interim.format("d") === dateEnd.format("d")) {
    timeValues.push({
      label: interim.locale("sv").format(labelFormat),
      value: interim.locale("sv").format(valueFormat),
    });
    interim.add(1, mode);
  }
  return timeValues;
}

/**
 * @description Create an array of month-objects between two dates
 * month-object: { label: "", value: "" }
 *
 * @param {string} startDateString Start date
 * @param {string} endDateString End date
 * @param {string} inputFormat Input format for startDateString and endDateString
 * @param {string} labelFormat Output format for label attribute
 * @param {string} valueFormat Output format for value attribute
 *
 * @returns {list}
 */
momentHandler.monthsBetweenDates = args => datesBetweenDates({ ...args, mode: "month" });

/**
 * @description Create an array of day-objects between two dates
 * month-object: { label: "", value: "" }
 *
 * @param {string} startDateString Start date
 * @param {string} endDateString End date
 * @param {string} inputFormat Input format for startDateString and endDateString
 * @param {string} labelFormat Output format for label attribute
 * @param {string} valueFormat Output format for value attribute
 *
 * @returns {list}
 */
momentHandler.daysBetweenDates = args => datesBetweenDates({ ...args, mode: "day" });

/**
 * @description Format a date string
 *
 * @param {string} dateString Date string
 * @param {string} inputFormat Input format for dateString
 * @param {string} returnFormat Output format for dateString
 *
 * @returns {string}
 */
momentHandler.formatDatestring = function (dateString, inputFormat, returnFormat) {
  assertReturnFormat(returnFormat);
  if (inputFormat === null) {
    return moment(dateString).format(returnFormat);
  }
  assertInputFormat(inputFormat);
  return moment(dateString, inputFormat).format(returnFormat);
};

/**
 * @description Create a date period for one month
 *
 * @param {string} format Date format
 *
 * @returns {object}
 */
momentHandler.datePeriodOneMonth = function (format) {
  assertReturnFormat(format);
  return {
    start: moment().startOf("month").format(format),
    end: moment().endOf("month").format(format),
  };
};

/**
 * @description Create a date period for three months
 *
 * @param {string} format Date format
 *
 * @returns {object}
 */
momentHandler.datePeriodThreeMonths = function (format) {
  assertReturnFormat(format);
  return {
    start: moment().subtract(2, "months").startOf("month").format(format),
    end: moment().endOf("month").format(format),
  };
};

/**
 * @description Create a date period for one year
 *
 * @param {string} format Date format
 *
 * @returns {object}
 */
momentHandler.datePeriodOneYear = function (format) {
  assertReturnFormat(format);
  return {
    start: moment().startOf("month").subtract(1, "years").format(format),
    end: moment().endOf("month").format(format),
  };
};

/**
 * @description Create a date period for between two dates
 *
 * @param {string} format Date format
 * *
 * @param {string} startDate Start date
 *
 * @param {string} endDate End date (optional)
 *
 * @returns {object}
 */
momentHandler.datePeriodAll = function (format, startDate, endDate = undef) {
  assertInputFormat(format);
  assertReturnFormat(format);
  return {
    start: moment(startDate, format).format(format),

    end: endDate ? moment(endDate, format).format(format) : moment().endOf("month").format(format),
  };
};
