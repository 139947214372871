<template>
  <div class="loader" v-if="isLoading">
    <clip-loader :loading="true" :color="spinnerColor"></clip-loader>
  </div>
</template>

<script>


export default {
  name: "LoadingSpinner",
  props: { isLoading: Boolean },
  data() {
    return { spinnerColor: "#111111" };
  },
};
</script>

<style scoped>
.loader {
  margin-top: 70px;
}
</style>
