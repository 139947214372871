

import Ad1 from "../views/reports/ad/Ad1.vue";
const Ad1_1 = { ...Ad1, name: "Ad1_1" };
const Ad1_2 = { ...Ad1, name: "Ad1_2" };
const Ad1_3 = { ...Ad1, name: "Ad1_3" };
const Ad1_4 = { ...Ad1, name: "Ad1_4" };

import Ad2 from "../views/reports/ad/Ad2.vue";
const Ad2_1 = { ...Ad2, name: "Ad2_1" };
const Ad2_2 = { ...Ad2, name: "Ad2_2" };
const Ad2_3 = { ...Ad2, name: "Ad2_3" };
const Ad2_4 = { ...Ad2, name: "Ad2_4" };
const Ad2_5 = { ...Ad2, name: "Ad2_5" };
const Ad2_6 = { ...Ad2, name: "Ad2_6" };
const Ad2_7 = { ...Ad2, name: "Ad2_7" };
const Ad2_8 = { ...Ad2, name: "Ad2_8" };
const Ad2_9 = { ...Ad2, name: "Ad2_9" };
const Ad2_10 = { ...Ad2, name: "Ad2_10" };
const Ad2_11 = { ...Ad2, name: "Ad2_11" };
const Ad2_12 = { ...Ad2, name: "Ad2_12" };
const Ad2_13 = { ...Ad2, name: "Ad2_13" };
const Ad2_14 = { ...Ad2, name: "Ad2_14" };

import Ad3 from "../views/reports/ad/Ad3.vue";

import Ad4 from "../views/reports/ad/Ad4.vue";
const Ad4_1 = { ...Ad4, name: "Ad4_1" };
const Ad4_2 = { ...Ad4, name: "Ad4_2" };
const Ad4_3 = { ...Ad4, name: "Ad4_3" };
const Ad4_4 = { ...Ad4, name: "Ad4_4" };
const Ad4_5 = { ...Ad4, name: "Ad4_5" };
const Ad4_6 = { ...Ad4, name: "Ad4_6" };

import Ad5 from "../views/reports/ad/Ad5.vue";

import Ad6 from "../views/reports/ad/Ad6.vue";
const Ad6_1 = { ...Ad6, name: "Ad6_1" };
const Ad6_2 = { ...Ad6, name: "Ad6_2" };

import Ad7 from "../views/reports/ad/Ad7.vue";

import Ad8 from "../views/reports/ad/Ad8.vue";
const Ad8_1 = { ...Ad8, name: "Ad8_1" };

import Ad9 from "../views/reports/ad/Ad9.vue";

export default function () {
  return [
    {
      path: "/ad1",
      name: "Ad1",
      component: Ad1,
    },
    {
      path: "/ad1.1",
      name: "Ad1_1",
      component: Ad1_1,
    },
    {
      path: "/ad1.2",
      name: "Ad1_2",
      component: Ad1_2,
    },
    {
      path: "/ad1.3",
      name: "Ad1_3",
      component: Ad1_3,
    },
    {
      path: "/ad1.4",
      name: "Ad1_4",
      component: Ad1_4,
    },
    {
      path: "/ad2",
      name: "Ad2",
      component: Ad2,
    },
    {
      path: "/ad2.1",
      name: "Ad2_1",
      component: Ad2_1,
    },
    {
      path: "/ad2.2",
      name: "Ad2_2",
      component: Ad2_2,
    },
    {
      path: "/ad2.3",
      name: "Ad2_3",
      component: Ad2_3,
    },
    {
      path: "/ad2.4",
      name: "Ad2_4",
      component: Ad2_4,
    },
    {
      path: "/ad2.5",
      name: "Ad2_5",
      component: Ad2_5,
    },
    {
      path: "/ad2.6",
      name: "Ad2_6",
      component: Ad2_6,
    },
    {
      path: "/ad2.7",
      name: "Ad2_7",
      component: Ad2_7,
    },
    {
      path: "/ad2.8",
      name: "Ad2_8",
      component: Ad2_8,
    },
    {
      path: "/ad2.9",
      name: "Ad2_9",
      component: Ad2_9,
    },
    {
      path: "/ad2.10",
      name: "Ad2_10",
      component: Ad2_10,
    },
    {
      path: "/ad2.11",
      name: "Ad2_11",
      component: Ad2_11,
    },
    {
      path: "/ad2.12",
      name: "Ad2_12",
      component: Ad2_12,
    },
    {
      path: "/ad2.13",
      name: "Ad2_13",
      component: Ad2_13,
    },
    {
      path: "/ad2.14",
      name: "Ad2_14",
      component: Ad2_14,
    },
    {
      path: "/ad3",
      name: "Ad3",
      component: Ad3,
    },
    {
      path: "/ad4",
      name: "Ad4",
      component: Ad4,
    },
    {
      path: "/ad4.1",
      name: "Ad4_1",
      component: Ad4_1,
    },
    {
      path: "/ad4.2",
      name: "Ad4_2",
      component: Ad4_2,
    },
    {
      path: "/ad4.3",
      name: "Ad4_3",
      component: Ad4_3,
    },
    {
      path: "/ad4.4",
      name: "Ad4_4",
      component: Ad4_4,
    },
    {
      path: "/ad4.5",
      name: "Ad4_5",
      component: Ad4_5,
    },
    {
      path: "/ad4.6",
      name: "Ad4_6",
      component: Ad4_6,
    },
    {
      path: "/ad5",
      name: "Ad5",
      component: Ad5,
    },
    {
      path: "/ad6",
      name: "Ad6",
      component: Ad6,
    },
    {
      path: "/ad6.1",
      name: "Ad6_1",
      component: Ad6_1,
    },
    {
      path: "/ad6.2",
      name: "Ad6_2",
      component: Ad6_2,
    },
    {
      path: "/ad7",
      name: "Ad7",
      component: Ad7,
    },
    {
      path: "/ad8",
      name: "Ad8",
      component: Ad8,
    },
    {
      path: "/ad8.1",
      name: "Ad8_1",
      component: Ad8_1,
    },
    {
      path: "/ad9",
      name: "Ad9",
      component: Ad9,
    },
  ];
}
