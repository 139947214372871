<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="user_uid" name="Primär användare (UID)" width="280" v-model="serverFilters.primary_user_uid" v-if="showFilter_primary_user_uid" />
          <RFilter configName="group_sam" qpsParamName="group_sam" v-model="serverFilters.group_sam" v-if="showFilter_group_sam" />
          <RFilter configName="group_dn" qpsParamName="group_dn" v-model="serverFilters.group_dn" v-if="showFilter_group_dn" />
          <RFilter configName="veda" v-model="serverFilters.veda_ids" v-if="showFilter_veda_ids" />
        </div>
      </div>
    </div>
    <div class="row-flex">
      <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <div v-if="showData" class="row-1-col space-top">
      <h2>Windows-datorer</h2>
      <TablePaginated :tableData="tableData" :columns="deviceColumns" :disableFiltering="false" :csvFilename="csvFilename" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as adconf from "./AdViewConfig.js";

const variation = rpt => ({
  title: {
    Ad8: "AD-8 Förteckning över Windows-datorer",
    Ad8_1: "AD-8.1 Förteckning över Windows-datorer per verksamhet",
  }[rpt],

  csvFilenameFun() {
    return {
      Ad8: "AD-8 Export Förteckning över Windows-datorer",
      Ad8_1: "AD-8.1 Export Förteckning över Windows-datorer per verksamhet",
    }[rpt];
  },

  autoload: false,

  serverFilters: {},

  showFilter_primary_user_uid: rpt === "Ad8",
  showFilter_group_sam: rpt === "Ad8",
  showFilter_group_dn: rpt === "Ad8",
  showFilter_veda_ids: true,

  deviceColumns: adconf.showColumns(adconf.deviceViewConfig, rpt, {
    CosNumber: "yy",
    cn: "yy",
    distinguishedName: "hn",
    whenChanged: "hn",
    whenCreated: "hn",
    dNSHostName: "hh",
    lastLogonTimestamp: "yh",
    logonCount: "yh",
    operatingSystem: "hh",
    operatingSystemVersion: "hh",
    InvoiceReference: "hh",
    InstallationStatus: "yy",
    PrimaryUserDistinguishedName: "hh",
    PrimaryUserUid: "hh",
    PrimaryUserSn: "yy",
    PrimaryUserGivenName: "yy",
    PrimaryUserNumberOf: "hh",
  }),
});

export default {
  name: "Ad8",
  data() {
    return _.defaultsDeep(variation(this.$route.name), {
      isLoading: false,
      serverFilters: {
        primary_user_uid: "",
        group_sam: "",
        group_dn: "",
        veda_ids: [],
      },
      filters: {},
      tableData: [],
      tableDataIsFor: null,
    });
  },
  watch: {
    async "serverFilters.primary_user_uid"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.group_sam"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.group_dn"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.veda_ids"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      if (this.autoload && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = {};
      if (currentFilters.primary_user_uid) {
        reqParams.primary_user_uid = currentFilters.primary_user_uid;
      }
      if (currentFilters.group_sam) {
        reqParams.group_sams = [currentFilters.group_sam];
      }
      if (currentFilters.group_dn) {
        reqParams.group_dns = [currentFilters.group_dn];
      }
      if (currentFilters.veda_ids.length) {
        reqParams.veda_ids = currentFilters.veda_ids;
      }
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/adcached/devices", { params: reqParams });
      this.tableData = response.data;
      this.tableDataIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor);
    },
    showLoadButton() {
      return !_.isEqual(this.serverFilters, this.tableDataIsFor) && !this.autoload;
    },
    csvFilename() {
      return this.csvFilenameFun();
    },
  },
  async mounted() {
    await this.maybeUpdateData();
  },
};
</script>

<style></style>
