<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="group_sam" v-model="filters.sam" />
          <RFilter configName="group_dn" v-model="filters.dn" />
        </div>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <span v-if="notSingle">{{ notSingleMessage }}</span>
    <div v-if="showData" class="row-1-col space-top">
      <h2>Data</h2>
      <TableSingleObject :tableData="dataobj" :fields="groupFields" :csvFilename="csvFilename_single" showMissing />
    </div>
    <div v-if="showData" class="row-1-col space-top">
      <h3>Gruppmedlemmar</h3>
      <TablePaginated :tableData="dataobj.Member_enriched" :columns="userColumns" :disableFiltering="false" :csvFilename="csvFilename_table" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as adconf from "./AdViewConfig.js";
import * as adutils from "./AdUtils.js";

const variation = rpt => ({
  title: { Ad3: "AD-3 Enskild grupp" }[rpt],
  csvprefix_single: { Ad3: "AD-3 Export Grupp " }[rpt],
  csvprefix_table: { Ad3: "AD-3 Export Medlemmar i " }[rpt],
  filters: {},
  noneMessage: { Ad3: "Ingen sådan grupp kunde hittas." }[rpt],
  tooManyMessage: { Ad3: "Mer än en sådan grupp hittades. Gör sökningen mer specifik." }[rpt],

  groupFields: adconf.showFields(adconf.groupViewConfig, rpt, {
    displayName: "y",
    description: "y",
    sn: "y",
    sAMAccountName: "y",
    cn: "y",
    distinguishedName: "y",
    whenChanged: "y",
    whenCreated: "y",
    managedBy: "y",
  }),

  userColumns: adconf.showColumns(adconf.userViewConfig, rpt, {
    sn: "y",
    givenName: "y",
    displayName: "h",
    description: "h",
    employeeType: "y",
    homeDirectory: "h",
    homeDrive: "h",
    mobile: "h",
    lastLogonTimestamp: "h",
    logonCount: "h",
    whenChanged: "h",
    whenCreated: "h",
    sthlmIDMExtended7: "h",
    ManagerCN: "h",
    sAMAccountName: "h",
    cn: "h",
    uid: "y",
    uidNumber: "h",
    userPrincipalName: "y",
    distinguishedName: "h",
    SI: "y",
    sthlmPersonnummer: "y",
    sthlmRPNR: "y",
    sthlmBefattningsKod: "h",
    sthlmKontoTyp: "y",
    company: "y",
    sthlmForvaltningsNr: "h",
    department: "y",
    sthlmVerksamhetsId: "h",
    sthlmFakturaRef: "y",
    sthlmSkolID: "y",
    telephoneNumber: "h",
    sthlmKlass: "h",
    _sisSchoolGradeComputed: "h",
  }),
});

export default {
  name: "Ad3",
  data() {
    return _.defaultsDeep(variation(this.$route.name), {
      isLoading: false,
      filters: {
        sam: "",
        dn: "",
      },
      dataobj: null,
      dataobjIsFor: null,
    });
  },
  watch: {
    async "filters.sam"() {
      await this.maybeUpdateData();
    },
    async "filters.dn"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      const currentFilters = _.cloneDeep(this.filters);
      const reqParams = {};
      let dosearch = false;
      if (currentFilters.sam) {
        reqParams.sams = [currentFilters.sam];
        dosearch = true;
      }
      if (currentFilters.dn) {
        reqParams.dns = [currentFilters.dn];
        dosearch = true;
      }
      // If filters are cleared then show nothing
      if (!dosearch) {
        this.dataobj = null;
        this.dataobjIsFor = null;
        return;
      }
      // Otherwise, fetch group object
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/ad/groups", { params: { ...reqParams, single: true } });
      this.dataobj = adutils.single(response.data);
      if (_.isObject(this.dataobj)) {
        const response2 = await this.$http.get("/api/reporting/ad/users", { params: { group_dns: [this.dataobj.distinguishedName] } });
        this.dataobj.Member_enriched = response2.data;
      }
      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    notSingle() {
      return _.isEqual(this.filters, this.dataobjIsFor) && (this.dataobj === "none" || this.dataobj === "too many");
    },
    showData() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    notSingleMessage() {
      if (this.dataobj === "none") {
        return this.noneMessage;
      }
      if (this.dataobj === "too many") {
        return this.tooManyMessage;
      }
      return "";
    },
    csvFilename_single() {
      return this.csvprefix_single + this.dataobj.sAMAccountName;
    },
    csvFilename_table() {
      return this.csvprefix_table + this.dataobj.sAMAccountName;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
