/* global process */
import * as _ from "lodash";
import Axios from "axios";
import { EventBus } from "./utils/eventBus/EventBus.js";
import Vue from "vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

const HTTP_400_BAD_REQUEST = 400;
const HTTP_401_UNAUTHORIZED = 401;
const HTTP_403_FORBIDDEN = 403;
const HTTP_404_NOT_FOUND = 404;
const HTTP_500_INTERNAL_SERVER_ERROR = 500;
const MAX_ERRORDATA_LENGTH = 1000;

// On any HTTP 401 or HTTP 403 response, log out and return to the login page.
// On any HTTP 400, 404 or 500 response, send an event to the ErrorHandler
// component.
Axios.interceptors.response.use(
  response => response,
  error => {
    const errorCode = error && error.response && error.response.status;
    if (HTTP_401_UNAUTHORIZED === errorCode || HTTP_403_FORBIDDEN === errorCode) {
      store.dispatch("logoutUser");
      router.push({ path: "/auth" });
      return null; // Since we navigate away, it doesn't matter.
    } else if (HTTP_400_BAD_REQUEST === errorCode || HTTP_404_NOT_FOUND === errorCode || HTTP_500_INTERNAL_SERVER_ERROR === errorCode) {
      EventBus.$emit("serverError", {
        ...error.response.data,
        endpoint: error.config.url,
        type: `HTTP ${errorCode}`,
      });
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  },
);

// Workaround for https://github.com/axios/axios/issues/61
// Apparently, Axios silently swallows errors arising from parsing invalid JSON.
// This is bad in principle, but especially since streamingHandler communicates
// an error by an invalid JSON payload.
Axios.interceptors.response.use(
  // ESLint doesn't recognize Promise.reject as control flow
  /* eslint-disable-next-line consistent-return */
  response => {
    if (_.isObject(response) && _.isString(response.data) && _.startsWith(response.headers["content-type"], "application/json") && response.data === response.request.responseText) {
      // When streamingHandler encounters an error it'll try to send an invalid
      // JSON value followed by a null byte and a valid error data JSON object
      // less than 1000 bytes.
      let errorData = {};
      try {
        errorData = JSON.parse(response.data.slice(0 - MAX_ERRORDATA_LENGTH, response.data.length).match(/\0(.*)$/u)[1]);
      } catch (err) {
        // That was our best effort; do nothing more.
      }
      EventBus.$emit("serverError", {
        ...errorData,
        endpoint: response.config.url,
        type: "Stream error",
      });
      Promise.reject(Error("Stream error"));
    } else {
      return response;
    }
  },
  error => Promise.reject(error),
);

// On any request:
// - Add the authorization token if it is available
// - Add the default baseURL if no baseURL is already set
Axios.interceptors.request.use(
  config => {
    const authToken = store.getters.authenticationToken;
    const authHeader = authToken ? { Authorization: authToken } : {};
    return {
      baseURL: process.env.VUE_APP_BACKEND,
      ...config,
      headers: {
        ...config.headers,
        ...authHeader,
      },
    };
  },
  error => Promise.reject(error),
);

Vue.prototype.$http = Axios;

/* eslint-disable-next-line sort-imports */
import App from "@/App.vue";
import BarChart from "@/components/charts/BarChart/BarChart.vue";
import CheckBox from "@/components/CheckBox.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import DatePeriodSelector from "@/components/filters/DatePeriodSelector/DatePeriodSelector.vue";
import ErrorHandler from "@/components/ErrorHandler/ErrorHandler.vue";
import JsonCSVExcel from "@/components/vue-json-csv-excel/JsonCSVExcel.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Menubar from "@/components/menubar/Menubar.vue";
import QueryParamSync from "@/components/QueryParamSync/QueryParamSync.vue";
import RFilter from "@/components/filters/RFilter/RFilter.vue";
import SelectFilter from "@/components/filters/SelectFilter/SelectFilter.vue";
import TablePaginated from "@/components/tables/TablePaginated.vue";
import TableSingleObject from "@/components/tables/TableSingleObject.vue";
import TextFilter from "@/components/filters/TextFilter/TextFilter.vue";
import ValueBox from "@/components/charts/ValueBox/ValueBox.vue";
import ValueFilter from "@/components/filters/ValueFilter/ValueFilter.vue";
import draggable from "vuedraggable";

Vue.component("App", App);
Vue.component("BarChart", BarChart);
Vue.component("CheckBox", CheckBox);
Vue.component("ClipLoader", ClipLoader);
Vue.component("DatePeriodSelector", DatePeriodSelector);
Vue.component("ErrorHandler", ErrorHandler);
Vue.component("JsonCSVExcel", JsonCSVExcel);
Vue.component("LoadingSpinner", LoadingSpinner);
Vue.component("Menubar", Menubar);
Vue.component("QueryParamSync", QueryParamSync);
Vue.component("RFilter", RFilter);
Vue.component("SelectFilter", SelectFilter);
Vue.component("TablePaginated", TablePaginated);
Vue.component("TableSingleObject", TableSingleObject);
Vue.component("TextFilter", TextFilter);
Vue.component("ValueBox", ValueBox);
Vue.component("ValueFilter", ValueFilter);
Vue.component("draggable", draggable);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
