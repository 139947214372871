<!--
     Usage:
     * encodingMethod: Can be
       * "CommaSeparatedStrings" for arrays of strings
       * "JSON" for any regular value.
       * "Straight" for any string value.
       * "Integer" for any integer value.
       * "CommaSeparatedStringsAndBooleans" for arrays of booleans and strings, decodes true/false to boolean values.
            This will also decode string variants of true/false to boolean values.
       * An object {encode, decode, default} where
         * encode is a function for encoding a value
         * decode is a function for decoding a value
         * default is a value used if the default prop is not used
     * paramName: The name of the URI query parameter to synchronize with.
     * default: The value represented by the absense of the query parameter. If
         not given it will be, depending on the encoding method:
       * CommaSeparatedStrings: []
       * JSON: null
       * Straight: ""
       * Integer: 0
       * CommaSeparatedBooleans: []
     * v-model: The name of the value to synchronize with
-->

<template>
  <div v-if="false" />
</template>

<script>

import * as _ from "lodash";
import * as encodingmethods from "./encodingmethods.js";

const undef = {}.undef;

export default {
  name: "QueryParamSync",
  props: {
    encodingMethod: { type: null, default: "JSON" },
    paramName: String,
    value: null,
    default: { type: null, default: undef },
  },
  data() {
    return {};
  },
  mounted() {
    this.fromRouteToValue();
  },
  watch: {
    $route(to, from) {
      // Do nothing if this route change does not change the parameter we
      // synchronize with.
      if (from.query[this.paramName] === to.query[this.paramName]) {
        return;
      }
      this.fromRouteToValue();
    },
    value() {
      this.fromValueToRoute();
    },
  },
  computed: {
    effectiveEncodingMethod() {
      let em = this.encodingMethod;
      if (_.isString(em)) {
        // Use standard encoding method defined above
        em = encodingmethods[em];
      }
      em = _.clone(em);
      if (!_.isUndefined(this.default)) {
        // Use default value set in prop
        em.default = this.default;
      }
      em.default_encoded = em.encode(em.default);
      return em;
    },
  },
  methods: {
    situation() {
      // Assess situation
      const em = this.effectiveEncodingMethod;
      const route_value_encoded = this.$route.query[this.paramName];
      let route_value_is_default = _.isUndefined(route_value_encoded) || route_value_encoded === em.default_encoded;
      let route_value = null;
      let route_value_ok = false;
      // Set route_value to the default
      if (route_value_is_default) {
        route_value = _.cloneDeep(em.default);
      } else {
        try {
          route_value = em.decode(route_value_encoded);
          route_value_ok = true;
        } catch (e) {
          // Handled later using route_value_ok
        }
      }
      if (!route_value_ok) {
        // Bad query parameter is interpreted as the default.
        route_value_is_default = true;
        route_value = _.cloneDeep(em.default);
      }
      const this_value = _.cloneDeep(this.value);
      let this_value_encoded = null;
      let this_value_encoded_ok = false;
      try {
        this_value_encoded = em.encode(this_value);
        this_value_encoded_ok = true;
      } catch (e) {
        // Handled later using this_value_encoded_ok
      }
      const this_value_is_default = _.isEqual(this_value, em.default) || this_value_encoded === em.default_encoded;
      const values_are_equivalent = _.isEqual(this_value, route_value) || this_value_encoded === route_value_encoded;
      return {
        values_are_equivalent,
        route_value,
        this_value_encoded,
        this_value_encoded_ok,
        this_value_is_default,
      };
    },
    fromRouteToValue() {
      const s = this.situation();
      // Do nothing if values are equivalent
      if (s.values_are_equivalent) {
        return;
      }
      // Emit changed value from route
      this.$emit("input", s.route_value);
    },
    fromValueToRoute() {
      const s = this.situation();
      if (!s.this_value_encoded_ok) {
        throw Error("Encoder error");
      }
      // Do nothing if values are equivalent
      if (s.values_are_equivalent) {
        return;
      }
      // Push route with changed value
      const query = {
        ...this.$route.query,
        [this.paramName]: s.this_value_encoded,
      };
      if (s.this_value_is_default) {
        delete query[this.paramName];
      }
      this.$router.push({ query });
    },
  },
};
</script>
