
import * as _ from "lodash";

export function compareStrings(strA, strB) {
  return strA.toLowerCase() === strB.toLowerCase();
}

export function stringIncludes(strA, strB) {
  return strA.toLowerCase().includes(strB.toLowerCase());
}

// Determines whether a value contains a string, which depending on the value
// type means:
// - For strings, the value includes the string, case insensitively.
// - For objects, at least one value includes the string, recursively.
// - For arrays, at least one value includes the string, recursively.
// - For booleans, the swedish rendering "sant" or "falskt" includes the string,
//   case insensitively.
// - For null and undefined values, the value does not include the string.
export function includes(value, str) {
  if (_.isArray(value)) {
    // Iterate through values.
    for (const item of value) {
      if (includes(item, str)) {
        return true;
      }
    }
  } else if (_.isObject(value)) {
    // Iterate through object keys, test the values.
    for (const key in value) {
      if (includes(value[key], str)) {
        return true;
      }
    }
  } else if (_.isString(value)) {
    return value.toLowerCase().includes((str || "").toLowerCase());
  } else if (_.isInteger(value)) {
    return value.toString().includes((str || "").toLowerCase());
  } else if (_.isBoolean(value)) {
    return (value ? "sant" : "falskt").includes((str || "").toLowerCase());
  } else if ((str || "") === "") {
    return true;
  }
  return false;
}

// Given a Vue instance, wait 3 ticks (to allow for its element's siblings to
// become available). Then, whether or not any siblings exist, set the zIndex for
// the element and all siblings in a decreasing progression.
const WAIT_TICKS = 3;
export function zIndexFix(vueInstance, countdown = WAIT_TICKS) {
  if (countdown) {
    vueInstance.$nextTick(() => {
      zIndexFix(vueInstance, countdown - 1);
    });
    return;
  }
  const element = vueInstance.$el;
  const parent = element.parentElement;
  const numberOfChildren = parent.children.length;
  for (let idx = 0; idx < numberOfChildren; idx++) {
    const child = parent.children[idx];
    child.style.zIndex = numberOfChildren - idx;
  }
}

// Takes an array of strings and returns a string that render that list as a
// grammatically correct enumeration in swedish.
export function renderSwedishList(arr) {
  const items = _.filter(arr);
  const c = items.length;
  if (c === 0) {
    return null;
  }
  if (c === 1) {
    return items[0];
  }
  return `${_.join(_.slice(items, 0, c - 1), ", ")} och ${items[c - 1]}`;
}

export function getUpperCaseOrEmptyString(x) {
  return _.isNull(x) ? "" : x.toString().toUpperCase();
}
