
import * as _ from "lodash";

export const Straight = {
  encode: value => `${value}`,
  decode: _.identity,
  default: "",
};

const JSON_method = {
  encode: JSON.stringify,
  decode: JSON.parse,
  default: null,
};
export { JSON_method as JSON };

export const CommaSeparatedStrings = {
  encode: value => value.map(x => x.replace(/_/gu, "__").replace(/,/gu, "_.").replace(/^/u, ",")).join(""),
  decode: value =>
    value
      .split(",")
      .slice(1)
      .map(x =>
        x
          .split("__")
          .map(y => y.replace(/_\./gu, ","))
          .join("_")),
  default: [],
};

export const Integer = {
  encode: value => `${value}`,
  decode: parseInt,
  default: 0,
};

export const CommaSeparatedStringsAndBooleans = {
  encode: value =>
    _.join(
      value.map(x => encodeURIComponent(x)),
      ",",
    ),
  decode: value =>
    _.split(value, ",")
      .map(x => decodeURIComponent(x))
      .map(x => {
        if (x.toString().toUpperCase() === "TRUE") {
          return true;
        }
        if (x.toString().toUpperCase() === "FALSE") {
          return false;
        }
        return x;
      }),
  default: [],
};

export const Boolean = {
  encode: value => (value === true ? "Y" : "N"),
  decode: value => !!value.match(/^(y|t|yes|true)$/ui),
  default: false,
};
