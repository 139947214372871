<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="user_uid" v-model="filters.uid" />
          <RFilter v-if="showFilterDn" configName="user_dn" v-model="filters.dn" />
        </div>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <span v-if="notSingle">{{ notSingleMessage }}</span>
    <div v-if="showData" class="row-1-col space-top">
      <h2>Data</h2>
      <TableSingleObject :tableData="dataobj" :fields="userFields" :csvFilename="csvFilename_single" showMissing />
    </div>
    <div v-if="showData" class="row-1-col space-top">
      <h3>Länkar</h3>
      <ul>
        <li>
          <a class="link" @click="showWindowsComputers">Visa Windows-datorer</a>
        </li>
      </ul>
    </div>
    <div v-if="showData" class="row-1-col space-top">
      <h3>Grupper</h3>
      <TablePaginated :tableData="dataobj.MemberOf_enriched" :columns="groupColumns" :disableFiltering="false" :csvFilename="csvFilename_table" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as adconf from "./AdViewConfig.js";
import * as adutils from "./AdUtils.js";

const variation = rpt => ({
  title: {
    Ad1: "AD-1 Enskilt användarkonto",
    Ad1_1: "AD-1.1 Enskilt konto för anställd",
    Ad1_2: "AD-1.2 Enskilt elevkonto",
    Ad1_3: "AD-1.3 Enskilt konsultkonto",
    Ad1_4: "AD-1.4 Enskilt funktionskonto",
  }[rpt],
  csvprefix_single: {
    Ad1: "AD-1 Export Användarkonto ",
    Ad1_1: "AD-1.1 Export Anställds konto ",
    Ad1_2: "AD-1.2 Export Elevkonto ",
    Ad1_3: "AD-1.3 Export Konsultkonto ",
    Ad1_4: "AD-1.4 Export Funktionskonto ",
  }[rpt],
  csvprefix_table: {
    Ad1: "AD-1 Export Grupper för ",
    Ad1_1: "AD-1.1 Export Grupper för anställds konto ",
    Ad1_2: "AD-1.2 Export Grupper för elevkonto ",
    Ad1_3: "AD-1.3 Export Grupper för konsultkonto ",
    Ad1_4: "AD-1.4 Export Grupper för funktionskonto ",
  }[rpt],
  filters: {
    employeeTypes: {
      Ad1: [],
      Ad1_1: ["f", "v"],
      Ad1_2: ["e"],
      Ad1_3: ["k"],
      Ad1_4: ["o"],
    }[rpt],
  },
  showFilterDn: rpt === "Ad1",
  noneMessage: {
    Ad1: "Inget sådant användarkonto kunde hittas.",
    Ad1_1: "Inget sådant konto för anställd kunde hittas.",
    Ad1_2: "Inget sådant elevkonto kunde hittas.",
    Ad1_3: "Inget sådant konsultkonto kunde hittas.",
    Ad1_4: "Inget sådant funktionskonto kunde hittas.",
  }[rpt],
  tooManyMessage: {
    Ad1: "Mer än ett sådant användarkonto hittades. Gör sökningen mer specifik.",
    Ad1_1: "Mer än ett sådant konto för anställd hittades. Gör sökningen mer specifik.",
    Ad1_2: "Mer än ett sådant elevkonto hittades. Gör sökningen mer specifik.",
    Ad1_3: "Mer än ett sådant konsultkonto hittades. Gör sökningen mer specifik.",
    Ad1_4: "Mer än ett sådant funktionskonto hittades. Gör sökningen mer specifik.",
  }[rpt],

  userFields: adconf.showFields(adconf.userViewConfig, rpt, {
    sn: "yyyyy",
    givenName: "yyyyy",
    displayName: "yyyyy",
    description: "yyyyy",
    employeeType: "ynnnn",
    homeDirectory: "yyyyy",
    homeDrive: "yyyyy",
    mobile: "yyyyn",
    lastLogonTimestamp: "yyyyy",
    logonCount: "yyyyy",
    whenChanged: "yyyyy",
    whenCreated: "yyyyy",
    sthlmIDMExtended7: "yyyyy",
    ManagerCN: "yyyyn",
    sAMAccountName: "ynnnn",
    cn: "ynnnn",
    uid: "yyyyy",
    uidNumber: "ynnnn",
    userPrincipalName: "yyyyy",
    distinguishedName: "ynnnn",
    SI: "yyyyy",
    sthlmPersonnummer: "yyyyn",
    sthlmRPNR: "yyyyn",
    sthlmBefattningsKod: "yyyyy",
    sthlmKontoTyp: "ynnnn",
    company: "yyyyy",
    sthlmForvaltningsNr: "yyyyy",
    department: "yyyyy",
    sthlmVerksamhetsId: "yyyyy",
    sthlmFakturaRef: "yyyyy",
    sthlmSkolID: "ynnnn",
    sthlmKlass: "ynnnn",
    _sisSchoolGradeComputed: "ynnnn",
    telephoneNumber: "yyyyn",
  }),

  groupColumns: adconf.showColumns(adconf.groupViewConfig, rpt, {
    displayName: "yyyyy",
    description: "yyyyy",
    sn: "hnnnn",
    sAMAccountName: "yyyyy",
    cn: "hnnnn",
    distinguishedName: "hnnnn",
    whenChanged: "hhhhh",
    whenCreated: "hhhhh",
    managedBy: "hhhhh",
  }),
});

export default {
  name: "Ad1",
  data() {
    return _.defaultsDeep(variation(this.$route.name), {
      isLoading: false,
      filters: {
        uid: "",
        dn: "",
      },
      dataobj: null,
      dataobjIsFor: null,
    });
  },
  watch: {
    async "filters.uid"() {
      await this.maybeUpdateData();
    },
    async "filters.dn"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      const currentFilters = _.cloneDeep(this.filters);
      const reqParams = {};
      let dosearch = false;
      if (currentFilters.uid) {
        reqParams.uids = [currentFilters.uid];
        dosearch = true;
      }
      if (currentFilters.dn) {
        reqParams.dns = [currentFilters.dn];
        dosearch = true;
      }
      if (currentFilters.employeeTypes.length) {
        reqParams.employeeTypes = currentFilters.employeeTypes;
        // Do not set dosearch, since this filter is not shown to the user and
        // shouldn't be enough to trigger a data fetch.
      }
      // If filters are cleared then show nothing
      if (!dosearch) {
        this.dataobj = null;
        this.dataobjIsFor = null;
        return;
      }
      // Otherwise, fetch user object
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/ad/users", { params: { ...reqParams, single: true } });
      this.dataobj = adutils.single(response.data);
      if (_.isObject(this.dataobj)) {
        const response2 = await this.$http.get("/api/reporting/ad/groups", { params: { user_dns: [this.dataobj.distinguishedName] } });
        this.dataobj.MemberOf_enriched = response2.data;
      }
      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
    showWindowsComputers() {
      this.$root.$router.push({
        path: "/ad8",
        query: { uid: this.dataobj.uid },
      });
    },
  },
  computed: {
    notSingle() {
      return _.isEqual(this.filters, this.dataobjIsFor) && (this.dataobj === "none" || this.dataobj === "too many");
    },
    showData() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    notSingleMessage() {
      if (this.dataobj === "none") {
        return this.noneMessage;
      }
      if (this.dataobj === "too many") {
        return this.tooManyMessage;
      }
      return "";
    },
    csvFilename_single() {
      return this.csvprefix_single + this.dataobj.sAMAccountName;
    },
    csvFilename_table() {
      return this.csvprefix_table + this.dataobj.sAMAccountName;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
