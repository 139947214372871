<!--
     Use v-model for two-way binding an array of values of checked leaf nodes.

     Use the prop `nodes` to supply an array of nodes.
     A node is an object with:
     * value: The value used to identify the node. Required and unique.
     * display: If supplied, used instead of value for labelling.
     * children: If supplied with a length>0, this node is not a leaf node.

     Implementation note: v-model is shorthand for setting a prop named "value"
     and reacting to an emitted event called "input". The implementation below
     will talk about props.value and $emit("input", newvalue).
-->

<template>
  <div ref="me">
    <div class="dropdown-header pointer noselect" v-bind:class="{ open: showDropDown }" v-on:click="toggleDropdown()" :style="widthStyle">
      {{ name }}
      <i class="down right" />
      <span class="dot right" v-show="hasAppliedFilters" />
    </div>
    <div v-show="showDropDown" class="dropdown-body">
      <div class="dropdown-body-item-middle">
        <input type="text" class="input-sm" v-model="freetextFilter" :style="widthStyle" />
      </div>
      <div class="dropdown-body-item-lower" :style="widthStyle">
        <ValueNode v-for="node in effectiveNodes" :node="node" :key="node.pkey" :selectedValues="selectedValues" :shownNodes="shownNodes" @add="add" @remove="remove" />
      </div>
    </div>
  </div>
</template>

<script>

import * as _ from "lodash";
import * as utils from "@/utils/utils.js";
import ValueNode from "./ValueNode.vue";

function walkNodes(nodes, fun, path = []) {
  _.map(nodes, node => {
    fun(node, path);
    if (node.children) {
      walkNodes(node.children, fun, [...path, node.pkey]);
    }
  });
}

function nodesAddPkey(nodes) {
  nodes = _.cloneDeep(nodes);
  let pkey = 0;
  walkNodes(nodes, node => {
    node.pkey = pkey;
    pkey += 1;
  });
  return nodes;
}

export default {
  name: "ValueFilter",
  props: {
    name: String,
    nodes: Array,
    width: {
      type: String,
      default: "200",
    },
    value: Array,
    inheritz: {
      type: Boolean,
      default: false,
    },
  },
  components: { ValueNode },
  data() {
    return {
      showDropDown: false,
      freetextFilter: "",
    };
  },
  methods: {
    add(values) {
      this.$emit("input", _.sortBy(_.union(this.value, values)));
    },

    remove(values) {
      this.$emit("input", _.sortBy(_.difference(this.value, values)));
    },

    toggleDropdown() {
      this.showDropDown = !this.showDropDown;
    },

    documentMouseup(e) {
      if (this.showDropDown && !this.$refs.me.contains(e.target)) {
        this.showDropDown = false;
      }
    },
  },
  computed: {
    widthStyle() {
      return `width: ${this.width}px;`;
    },

    hasAppliedFilters() {
      return this.value.length > 0;
    },

    selectedValues() {
      return new Set(this.value);
    },

    effectiveNodes() {
      return nodesAddPkey(this.nodes);
    },

    shownNodes() {
      const filter = this.freetextFilter;
      const ret = new Set();
      walkNodes(this.effectiveNodes, (node, path) => {
        if (filter === "" || utils.stringIncludes(node.display || node.value, filter)) {
          _.map([...path, node.pkey], pkey => {
            ret.add(pkey);
          });
        }
      });
      return ret;
    },
  },
  mounted() {
    if (!this.inheritz) {
      utils.zIndexFix(this);
    }
    document.addEventListener("mouseup", this.documentMouseup);
  },
  beforeDestroy() {
    document.removeEventListener("mouseup", this.documentMouseup);
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.dropdown-header {
  border: 1px solid #dcdcdc;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown-body {
  position: absolute;
  background-color: #ffffff;
}

.dropdown-body-item-middle {
  border: 1px solid #dcdcdc;
  border-top: 0px;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.dropdown-body-item-lower {
  border: 1px solid #dcdcdc;
  border-top: 0px;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  overflow-y: scroll;
  max-height: 300px;
}

.right {
  float: right;
}

i {
  border: solid #444444;
  border-width: 0 1px 1px 0;
  padding: 3px;
  position: relative;
  top: 7px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #742c9c;
  border-radius: 50%;
  position: relative;
  top: 7px;
  left: -10px;
}
</style>
