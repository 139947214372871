<!--
     Usage:
     * value: Can be
       * true to display a checked box
       * false to display a empty box
       * null to display a box with a dash
     * @click: Will be emitted on click.
     * v-model: Can be used for two-way sync with a boolean value. You probably
       don't want to use this together with value or @click.
     * display: The text to display next to the checkbox
-->

<template>
  <div
    class="checkbox"
    :class="{
      'icon-checkbox-selected-full': value == true,
      'icon-checkbox': value == false,
      'icon-checkbox-selected': value == null,
    }"
    @click="$emit('click')"
  >
    {{ display }}
  </div>
</template>

<script>

import * as utils from "@/utils/utils.js";

export default {
  name: "CheckBox",
  props: {
    display: String,
    value: { type: null, default: null },
    inheritz: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.inheritz) {
      utils.zIndexFix(this);
    }
  },
};
</script>

<style>
.checkbox {
  padding-left: 2rem;
  min-height: 24px;
  cursor: pointer;
}

/* These styles are taken from
 * https://static.stockholm.se/styleguide-etjanst/latest/icons/icons.data.svg.css
 * as linked from
 * https://etjanster.test.stockholm.se/styleguide/bootstrap-components.html
 * except as noted
 */

.icon-checkbox-full {
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22enable-background%3Anew%200%200%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%2218.2%2C4.3%2018.2%2C18.2%205.8%2C18.2%205.8%2C5.8%2018.2%2C5.8%2018.2%2C4.3%204.3%2C4.3%204.3%2C19.7%2019.7%2C19.7%20%20%20%2019.7%2C4.3%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}

.icon-checkbox-selected-full {
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22enable-background%3Anew%200%200%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%20id%3D%22ic_x5F_chevron_x5F_left%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_chevron_x5F_right%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_close%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_expand_x5F_less%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_expand_x5F_more%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_add%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%3E%20%3Cg%3E%20%20%3Cpolygon%20points%3D%2218.2%2C12.4%2018.2%2C18.2%205.8%2C18.2%205.8%2C5.8%2013.8%2C5.8%2013.8%2C4.3%204.3%2C4.3%204.3%2C19.7%2019.7%2C19.7%20%20%20%2019.7%2C12.4%20%20%20%22%2F%3E%20%3C%2Fg%3E%20%3Cg%3E%20%20%3Cpath%20d%3D%22M18.5%2C4.6L11.7%2C13L9%2C10.5l-1.2%2C1.1l3.9%2C3.9l8.2-9.8L18.5%2C4.6z%22%2F%3E%20%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}

/* Altered */
.icon-checkbox-selected {
  background-image: url("data:image/svg+xml,%3Csvg%20version%3D%271.1%27%20id%3D%27Layer_1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20x%3D%270px%27%20y%3D%270px%27%20width%3D%2724px%27%20height%3D%2724px%27%20viewBox%3D%270%200%2024%2024%27%20style%3D%27enable-background%3Anew%200%200%2024%2024%3B%27%20xml%3Aspace%3D%27preserve%27%3E%3Cpolygon%20fill-opacity%3D%270.5%27%20points%3D%2718.2%2C4.3%2018.2%2C18.2%205.8%2C18.2%205.8%2C5.8%2018.2%2C5.8%2018.2%2C4.3%204.3%2C4.3%204.3%2C19.7%2019.7%2C19.7%2019.7%2C4.3%20%27%2F%3E%3Cpath%20id%3D%27%27%20d%3D%27M%209%2012%20l%206%200%27%20stroke%3D%27black%27%20stroke-width%3D%272%27%20fill%3D%27none%27%20%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}

.icon-checkbox {
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22enable-background%3Anew%200%200%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%20id%3D%22ic_x5F_chevron_x5F_left%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_chevron_x5F_right%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_close%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_expand_x5F_less%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_expand_x5F_more%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%20id%3D%22ic_x5F_add%22%20style%3D%22opacity%3A0.54%3B%22%3E%3C%2Fg%3E%3Cg%3E%20%3Cg%3E%20%20%3Cpolygon%20fill-opacity%3D%220.5%22%20points%3D%2218.2%2C4.3%2018.2%2C18.2%205.8%2C18.2%205.8%2C5.8%2018.2%2C5.8%2018.2%2C4.3%204.3%2C4.3%204.3%2C19.7%2019.7%2C19.7%20%20%20%2019.7%2C4.3%20%20%20%22%2F%3E%20%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}
</style>
