<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>CR-1 Enskild ChromeOS enhet</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="device_cosnumber" v-model="filters.cosnumber" />
          <RFilter configName="gsuite_chromeos_serialnumber" v-model="filters.serialnumber" />
        </div>
      </div>
    </div>
    <div class="row-flex">
      <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
    </div>
    <LoadingSpinner :isLoading="isLoading" />

    <div v-if="showData" class="row-1-col space-top">
      <span v-if="notFound">{{ notFoundMessage }}</span>

      <h2>Data</h2>
      <TableSingleObject :tableData="dataobj" :fields="deviceFields" :csvFilename="csvFilename" showMissing />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";

function routeToCr5(arg) {
  arg.root.$router.push({
    path: "/cr5",
    query: { oupath: arg.clickedValue },
  });
}

function routeToCr3byEmail(arg) {
  arg.root.$router.push({
    path: "/cr3",
    query: { email: arg.clickedValue },
  });
}
export default {
  name: "Cr1",
  props: {},
  data() {
    return {
      isLoading: false,
      filters: {
        serialnumber: "",
        cosnumber: "",
      },
      dataobj: null,
      dataobjIsFor: null,
      deviceFields: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "VerksamhetsId",
          show: true,
        },
        {
          property: "InvoiceReference",
          displayName: "Fakturareferens",
          show: true,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
        },
        {
          property: "Status",
          displayName: "Status",
          show: true,
        },
        {
          property: "LastEnroll",
          displayName: "Senaste registrering",
          show: true,
        },
        {
          property: "LastSynchronization",
          displayName: "Senaste synkronisering",
          show: true,
        },
        {
          property: "UserPrincipalName",
          displayName: "Senaste användare",
          show: true,
          onclick: routeToCr3byEmail,
        },
        {
          property: "UserId",
          displayName: "Senaste användare UID",
          show: true,
        },
        {
          property: "Model",
          displayName: "Model",
          show: true,
        },
        {
          property: "OperatingSystemVersion",
          displayName: "OS version",
          show: true,
        },
        {
          property: "FirmwareVersion",
          displayName: "Firmwareversion",
          show: false,
        },
        {
          property: "PlatformVersion",
          displayName: "Plattformsversion",
          show: true,
        },
        {
          property: "MACAdress",
          displayName: "MAC",
          show: true,
        },
        {
          property: "BootMode",
          displayName: "BootMode",
          show: true,
        },
        {
          property: "GsuiteOUPath",
          displayName: "Sökväg OU-grupp",
          show: true,
          onclick: routeToCr5,
        },
        {
          property: "AutoUpdateExpirationDate",
          displayName: "Slutdatum för automatisk uppdatering",
          show: true,
        },
        {
          property: "RAMReportTime",
          displayName: "RAM rapporttid",
          show: true,
        },
        {
          property: "RAMTotal",
          displayName: "RAM totalt",
          show: true,
        },
        {
          property: "RAMQuota",
          displayName: "RAM använt",
          show: true,
        },
        // {
        //   property: "RAMUsed",
        //   displayName: "RAM använt (GB)",
        //   show: false,
        // },
        {
          property: "DiskTotal",
          displayName: "Diskutrymme totalt ",
          show: true,
        },

        {
          property: "DiskQuota",
          displayName: "Diskytrymme använt",
          show: true,
        },
        // {
        //   property: "DiskUsed",
        //   displayName: "Använt diskutrymme (GB)",
        //   show: false,
        // },
      ],
    };
  },
  methods: {
    async updateData() {
      if (!_.size(_.pickBy(this.filters)) > 0) {
        return;
      }
      this.isLoading = true;
      const params = {};
      const currentFilters = _.cloneDeep(this.filters);

      if (currentFilters.serialnumber) {
        params.serialnumber = currentFilters.serialnumber;
      }

      if (currentFilters.cosnumber) {
        params.cosnumber = currentFilters.cosnumber;
      }

      const response = await this.$http.get("/api/reporting/gsuite/device", { params });
      this.dataobj = response.data;
      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    notFound() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isString(this.dataobj);
    },
    showData() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    notFoundMessage() {
      return "Ingen enhet med angivet serienummer hittades";
    },
    showLoadButton() {
      return !_.isEqual(this.filters, this.dataobjIsFor);
    },
    csvFilename() {
      return `CR-1 Export - Enskild ChromeOS enhet ${this.dataobj.Serialnumber}`;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
