<template>

  <div>

    <div class="dateperiod-container" style="color: #742c9c !important;">

      <div v-if="name" style="cursor: default; color: #333333 !important;"> {{ name }} </div>

      <div
        class="noselect"
        v-bind:class="{
          dateperiodSelected: isDatePeriodTypeActive(DATEPERIOD_TYPES.ALL),
        }"
        v-on:click="setActiveDatePeriodType(DATEPERIOD_TYPES.ALL)"
      >
         Samtliga
      </div>

      <div
        class="noselect"
        v-bind:class="{
          dateperiodSelected: isDatePeriodTypeActive(DATEPERIOD_TYPES.ONE_MONTH),
        }"
        v-on:click="setActiveDatePeriodType(DATEPERIOD_TYPES.ONE_MONTH)"
      >
         1 mån
      </div>

      <div
        class="noselect"
        v-bind:class="{
          dateperiodSelected: isDatePeriodTypeActive(DATEPERIOD_TYPES.THREE_MONTHS),
        }"
        v-on:click="setActiveDatePeriodType(DATEPERIOD_TYPES.THREE_MONTHS)"
      >
         3 mån
      </div>

      <div
        class="noselect"
        v-bind:class="{
          dateperiodSelected: isDatePeriodTypeActive(DATEPERIOD_TYPES.ONE_YEAR),
        }"
        v-on:click="setActiveDatePeriodType(DATEPERIOD_TYPES.ONE_YEAR)"
      >
         1 år
      </div>

      <div
        class="noselect datelabel"
        v-bind:class="{
          dateperiodSelected: isDatePeriodTypeActive(DATEPERIOD_TYPES.CUSTOM_DATEPERIOD),
        }"
        v-on:click="showStartCalendar()"
      >
         {{ formattedCalendarDatePeriod().start }}
      </div>

      <div
        class="noselect datelabel"
        v-bind:class="{
          dateperiodSelected: isDatePeriodTypeActive(DATEPERIOD_TYPES.CUSTOM_DATEPERIOD),
        }"
        v-on:click="showEndCalendar()"
      >
         {{ formattedCalendarDatePeriod().end }}
      </div>

    </div>

    <datepicker
      ref="startDatepicker"
      input-class="hidden"
      class="color=red"
      v-model="calendarDatePeriod.start"
      @selected="startCalendarDateSelected"
      :language="datepickerLocale"
      :minimumView="datepickerType"
      :maximumView="datepickerType"
    ></datepicker>

    <datepicker
      ref="endDatepicker"
      input-class="hidden"
      v-model="calendarDatePeriod.end"
      @selected="endCalendarDateSelected"
      :language="datepickerLocale"
      :minimumView="datepickerType"
      :maximumView="datepickerType"
    ></datepicker>

    <QueryParamSync
      v-if="qpsParamName"
      encodingMethod="CommaSeparatedStrings"
      :paramName="qpsParamName"
      :value="
        activeDatePeriodType === DATEPERIOD_TYPES.CUSTOM_DATEPERIOD
          ? [activeDatePeriodType, formattedCalendarDatePeriod().start, formattedCalendarDatePeriod().end]
          : activeDatePeriodType === DATEPERIOD_TYPES.ALL
          ? []
          : [activeDatePeriodType]
      "
      :default="defaultValue"
      @input="input"
    />

  </div>

</template>

<script>
import * as utils from "@/utils/utils.js";
import Datepicker from "vuejs-datepicker";
import { momentHandler } from "@/../../shared";
import { sv } from "vuejs-datepicker/dist/locale";

const undef = {}.undef;

export default {
  name: "DatePeriodSelector",
  components: { Datepicker },
  props: {
    value: Array,
    name: String,
    dateEventFormat: {
      type: String,
      default: "YYYY-MM-DD",
    },
    datepickerType: {
      type: String, // 'day' or 'month'
      default: "day",
    },
    qpsParamName: String,
    defaultValue: { type: null, default: undef },
  },
  data() {
    return {
      datepickerLocale: sv,
      DATEPERIOD_TYPES: {
        ONE_MONTH: "ONE_MONTH",
        THREE_MONTHS: "THREE_MONTHS",
        ONE_YEAR: "ONE_YEAR",
        CUSTOM_DATEPERIOD: "CUSTOM_DATEPERIOD",
        ALL: "ALL",
      },
      activeDatePeriodType: "ALL",
      calendarDatePeriod: {
        start: "",
        end: "",
      },
    };
  },
  methods: {
    /**
     * @description Verify if both start and end are set
     */
    calendarStartAndEndAreSet() {
      return this.calendarDatePeriod.start != "" && this.calendarDatePeriod.end != "";
    },

    /**
     * @description Format calendarDatePeriod with dateEventFormat
     */
    formattedCalendarDatePeriod() {
      return {
        start: this.calendarDatePeriod.start == "" ? this.dateEventFormat : momentHandler.formatDatestring(this.calendarDatePeriod.start, null, this.dateEventFormat),
        end: this.calendarDatePeriod.end == "" ? this.dateEventFormat : momentHandler.formatDatestring(this.calendarDatePeriod.end, null, this.dateEventFormat),
      };
    },

    /**
     * @description Set the active date period type
     *
     * @param {event} event currently not used
     */
    startCalendarDateSelected(ignored__event) {
      this.$nextTick().then(() => {
        if (this.calendarStartAndEndAreSet()) {
          this.setActiveDatePeriodType(this.DATEPERIOD_TYPES.CUSTOM_DATEPERIOD);
        }
      });
    },

    /**
     * @description Set the active date period type
     *
     * @param {event} event currently not used
     */
    endCalendarDateSelected(ignored__event) {
      this.$nextTick().then(() => {
        if (this.calendarStartAndEndAreSet()) {
          this.setActiveDatePeriodType(this.DATEPERIOD_TYPES.CUSTOM_DATEPERIOD);
        }
      });
    },

    /**
     * @description Set the calendar date period objekt
     * @description When selecting a pre-defined date period the custom date period type and its date pickers should reflect the selected pre-defined date period,
     * therefor we update the v-model object
     *
     * @param {object} datePeriod date period object with start and end dates
     */
    setCalendarDatePeriod(datePeriod) {
      this.calendarDatePeriod = datePeriod;
    },

    /**
     * @description Show datepicker for start-time
     * @description Close datepicker for end-time
     */
    showStartCalendar() {
      this.$refs.startDatepicker.showCalendar();
      this.$refs.endDatepicker.close(true);
    },

    /**
     * Show datepicker for end-time
     * Close datepicker for start-time
     */
    showEndCalendar() {
      this.$refs.endDatepicker.showCalendar();
      this.$refs.startDatepicker.close(true);
    },

    /**
     * Close datepicker for end-time
     * Close datepicker for start-time
     */
    hideCalendar() {
      this.$refs.startDatepicker.close(true);
      this.$refs.endDatepicker.close(true);
    },

    /**
     * @description Compare a date period type with the active date period type
     *
     * @param {string} datePeriodType
     *
     * @returns {bool}
     */
    isDatePeriodTypeActive(datePeriodType) {
      return this.activeDatePeriodType === datePeriodType;
    },

    /**
     * @description Set the active date period type
     * @description hide calendars
     * @description Send dateperiod-changed event
     *
     * @param {string} datePeriodType
     */
    setActiveDatePeriodType(datePeriodType) {
      this.activeDatePeriodType = datePeriodType;

      switch (this.activeDatePeriodType) {
        case this.DATEPERIOD_TYPES.ALL:
          this.calendarDatePeriod.start = "";
          this.calendarDatePeriod.end = "";
          break;
        case this.DATEPERIOD_TYPES.ONE_MONTH:
          this.setCalendarDatePeriod(momentHandler.datePeriodOneMonth(this.dateEventFormat));
          this.hideCalendar();
          break;
        case this.DATEPERIOD_TYPES.THREE_MONTHS:
          this.setCalendarDatePeriod(momentHandler.datePeriodThreeMonths(this.dateEventFormat));
          this.hideCalendar();
          break;
        case this.DATEPERIOD_TYPES.ONE_YEAR:
          this.setCalendarDatePeriod(momentHandler.datePeriodOneYear(this.dateEventFormat));
          this.hideCalendar();
          break;
        default:
          break;
      }
      this.$emit("input", this.activeDatePeriodType === this.DATEPERIOD_TYPES.ALL ? [] : this.getArrayFromPeriod(this.formattedCalendarDatePeriod()));
    },
    getArrayFromPeriod(period) {
      if (this.datepickerType === "month") {
        return momentHandler
          .monthsBetweenDates({ startDateString: period.start, endDateString: period.end, inputFormat: "YYYY-MM-DD", labelFormat: "YYYY-MM-DD", valueFormat: "YYYY-MM" })
          .map(item => item.value);
      }
      if (this.datepickerType === "day") {
        return momentHandler
          .daysBetweenDates({ startDateString: period.start, endDateString: period.end, inputFormat: "YYYY-MM-DD", labelFormat: "YYYY-MM-DD", valueFormat: "YYYY-MM-DD" })
          .map(item => item.value);
      }
      throw Error("Invalid datepickerType");
    },
    input(value) {
      if (!value || !value.length || value.length < 1) {
        return;
      }
      /* eslint-disable-next-line no-magic-numbers */
      if (value.length === 3) {
        const datePeriod = momentHandler.datePeriodAll(this.dateEventFormat, value[1], value[2]);
        this.setCalendarDatePeriod(datePeriod);
      }
      this.setActiveDatePeriodType(value[0]);
    },
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.activeDatePeriodType && this.activeDatePeriodType !== this.DATEPERIOD_TYPES.ALL) {
      this.setActiveDatePeriodType(this.activeDatePeriodType);
    }
    utils.zIndexFix(this);
  },
};
</script>

<style>
.cell:hover {
  border-color: #f1e6fc !important;
}

.cell.selected {
  background-color: #f1e6fc !important;
  color: #444444 !important;
}

.hidden {
  display: none;
}

.dateperiod-container {
  display: flex;
  flex-direction: row;
}

.dateperiod-container > div {
  border: solid 1px #dcdcdc;
  border-left: none;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.dateperiod-container > div:first-child {
  border-left: solid 1px #dcdcdc;
}

.dateperiodSelected {
  background-color: #f1e6fc;
  color: #742c9c !important;
}

.datelabel {
  width: 87px;
  text-align: center;
}

.vdp-datepicker__calendar {
  position: absolute;
  right: 0px;
}
</style>

