<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>Skrivare-2 Skrivar-/kopiatornyttjande per månadsperiod</h1>
            </div>
            <div class="row-flex space-top-sm">
              <RFilter configName="veda" v-model="filters.veda.selected" />
              <RFilter configName="dmnx_model" v-model="filters.model.selected" />
              <DatePeriodSelector style="margin-left: auto" v-model="filters.timePeriod" name="Datumperiod" qpsParamName="dateperiod" datepickerType="month" dateEventFormat="YYYY-MM" />
              <RFilter configName="truefalse" name="Överskrider volymutnyttjande" qpsParamName="capacityoverusage" width="250" v-model="filters.capacityoverusage.selected" />
            </div>
          </div>
        </div>
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="chartData.tableData" :columns="columns" :disableFiltering="false" csvFilename="Skrivare-2 Export" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

export default {
  name: "Dmnx2",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      chartData: null,
      columns: [
        {
          property: "Model",
          displayName: "Modell",
          show: false,
        },
        {
          property: "Manufacturer",
          displayName: "Tillverkare",
          show: false,
        },
        {
          property: "SubCategory",
          displayName: "Artikel",
          show: false,
        },
        {
          property: "SubCategoryName",
          displayName: "Beskrivning",
          show: true,
        },
        {
          property: "SubCategoryCapacity",
          displayName: "Volym/månad",
          show: true,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
        },
        {
          property: "IpAddress",
          displayName: "IP-adress",
          show: false,
        },
        {
          property: "VedaName",
          displayName: "Verksamhet",
          show: true,
        },
        {
          property: "LocationVeda",
          displayName: "VerksamhetsId",
          show: false,
        },
        {
          property: "Period",
          displayName: "Månadsperiod",
          show: true,
        },
        {
          property: "CopyBlackPeriodCount",
          displayName: "Kopiering svartvitt",
          show: true,
        },
        {
          property: "CopyColorFullPeriodCount",
          displayName: "Kopiering färg",
          show: true,
        },
        {
          property: "CopyColorMonoPeriodCount",
          displayName: "Kopiering färg(mono)",
          show: false,
        },
        {
          property: "CopyColorTwinPeriodCount",
          displayName: "Kopiering färg(twin)",
          show: false,
        },
        {
          property: "PrintBlackPeriodCount",
          displayName: "Utskrifter svartvitt",
          show: true,
        },
        {
          property: "PrintColorFullPeriodCount",
          displayName: "Utskrifter färg",
          show: true,
        },
        {
          property: "PrintColorMonoPeriodCount",
          displayName: "Utskrifter färg(mono)",
          show: false,
        },
        {
          property: "PrintColorTwinPeriodCount",
          displayName: "Utskrifter färg(twin)",
          show: false,
        },
        {
          property: "TotalPeriodCount",
          displayName: "Kopiering/Utskrifter total",
          show: true,
        },
        {
          property: "CapacityUsage",
          displayName: "Volymutnyttjande",
          show: true,
        },
        {
          property: "CapacityOverused",
          displayName: "Överskrider volymutnyttjande",
          show: false,
        },
      ],
      filters: {
        veda: { selected: [] },
        model: { selected: [] },
        capacityoverusage: { selected: [] },
        timePeriod: ["2019-01", "2020-01"],
      },
    };
  },
  watch: {
    "filters.veda.selected"() {
      this.crossfilter.filterDimensionArrayContains("VedaIdDimension", this.filters.veda.selected);
      this.calculateReportComponentsData();
    },
    "filters.model.selected"() {
      this.crossfilter.filterDimensionArrayContains("ModelDimension", this.filters.model.selected);
      this.calculateReportComponentsData();
    },
    "filters.timePeriod"() {
      this.crossfilter.filterDimensionArrayContains("PeriodDimension", this.filters.timePeriod);
      this.calculateReportComponentsData();
    },
    "filters.capacityoverusage.selected"() {
      this.crossfilter.filterDimensionArrayContains("CapacityOverusedDimension", this.filters.capacityoverusage.selected);
      this.calculateReportComponentsData();
    },
  },
  methods: {
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.reportData);
      this.crossfilter.createDimensionByProperty("VedaIdDimension", "LocationVeda");
      this.crossfilter.createDimensionByProperty("ModelDimension", "Model");
      this.crossfilter.createDimensionByProperty("PeriodDimension", "Period");
      this.crossfilter.createDimensionByProperty("CapacityOverusedDimension", "CapacityOverused");
    },
    calculateReportComponentsData() {
      const tableData = this.crossfilter.allFiltered();

      const chartData = { tableData };
      this.chartData = chartData;
    },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/dmnx/printerPeriods")]).then(
      this.$http.spread(eq1Response => {
        this.reportData = eq1Response.data;
        this.initCrossfilter();
        this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
