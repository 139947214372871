<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>AD-9 Exit</h1>
            </div>
            <div class="row-flex space-top-sm">
              <RFilter configName="veda" v-bind:addEmpty="true" v-model="filters.veda" />
              <RFilter configName="exit_state" v-model="filters.state" />
              <DatePeriodSelector style="margin-left: auto" v-model="filters.started" name="Registrerad i Exit" qpsParamName="started" />
              <RFilter
                name="Kontokategori"
                component="ValueFilter"
                nodeEndpoint="/api/reporting/ad/filter/employeetype"
                width="300"
                qpsEncodingMethod="CommaSeparatedStrings"
                qpsParamName="et"
                v-model="filters.employeeType"
              />
              <RFilter configName="truefalse" name="Aktiv i AD" qpsParamName="ad" v-model="filters.enabled" />
              <DatePeriodSelector style="margin-left: auto" v-model="filters.nextScheduling" name="Nästa händelse" qpsParamName="nextScheduling" />
            </div>
          </div>
        </div>

        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="reportData" :columns="columns" :disableFiltering="false" csvFilename="Ad-9 Export - Exit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

export default {
  name: "Ad9",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      columns: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "VerksamhetsId",
          show: false,
        },
        {
          property: "FirstName",
          displayName: "Förnamn",
          show: true,
        },
        {
          property: "LastName",
          displayName: "Efternamn",
          show: true,
        },
        {
          property: "Uid",
          displayName: "Användarid (UID)",
          show: true,
        },
        {
          property: "Started",
          displayName: "Registrerad i Exit",
          show: false,
        },
        {
          property: "CurrentState",
          displayName: "Status",
          show: true,
        },
        {
          property: "NextScheduling",
          displayName: "Nästa händelse",
          show: true,
        },
        {
          property: "AccountType",
          displayName: "Kontokategori",
          show: false,
        },
        {
          property: "Enabled",
          displayName: "Aktiv i AD",
          show: true,
        },
        {
          property: "LastActionInfo",
          displayName: "Felmeddelande",
          show: false,
        },
      ],
      filters: {
        veda: [],
        started: [],
        nextScheduling: [],
        enabled: [],
        employeeType: [],
        state: [],
      },
    };
  },
  watch: {
    async "filters.veda"() {
      await this.crossfilter.filterDimensionArrayContains("VedaDimension", this.filters.veda);
      await this.calculateReportComponentsData();
    },
    async "filters.state"() {
      await this.crossfilter.filterDimensionArrayContains("StateDimension", this.filters.state);
      await this.calculateReportComponentsData();
    },
    async "filters.enabled"() {
      await this.crossfilter.filterDimensionArrayContains("EnabledDimension", this.filters.enabled);
      await this.calculateReportComponentsData();
    },
    async "filters.employeeType"() {
      await this.crossfilter.filterDimensionArrayContains("EmployeeTypeDimension", this.filters.employeeType);
      await this.calculateReportComponentsData();
    },
    async "filters.started"() {
      await this.crossfilter.filterDimensionArrayContains("StartedDimension", this.filters.started);
      await this.calculateReportComponentsData();
    },
    async "filters.nextScheduling"() {
      await this.crossfilter.filterDimensionArrayContains("NextSchedulingDimension", this.filters.nextScheduling);
      await this.calculateReportComponentsData();
    },
  },
  methods: {
    async initCrossfilter() {
      this.crossfilter = await new CrossfilterHandler(this.reportData);
      await this.crossfilter.createDimensionByProperty("VedaDimension", "VedaId");
      await this.crossfilter.createDimensionByProperty("StateDimension", "CurrentState");
      await this.crossfilter.createDimensionByProperty("EnabledDimension", "Enabled");
      await this.crossfilter.createDimensionByProperty("EmployeeTypeDimension", "AccountType");
      await this.crossfilter.createDimensionByMomentOnProperty("NextSchedulingDimension", "NextScheduling", "YYYY-MM-DD", "YYYY-MM-DD");
      await this.crossfilter.createDimensionByMomentOnProperty("StartedDimension", "Started", "YYYY-MM-DD", "YYYY-MM-DD");
    },

    async calculateReportComponentsData() {
      this.reportData = await this.crossfilter.allFiltered();
    },
  },
  computed: {},
  async mounted() {
    const response = await this.$http.get("/api/reporting/ad/exitprocessinfo");
    this.reportData = _.isString(response.data) ? [] : response.data;

    await this.initCrossfilter();
    await this.calculateReportComponentsData();
    this.isLoading = false;
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
