<template>
  <div>
    <div class="dropdown-header pointer noselect" v-bind:class="{ open: showDropDown }" v-on:click="toggleDropdown()" :style="widthStyle">
      <div class="header-item">
        <template v-if="selectedValue != null">{{ selectedValue.display }}</template>
        <i class="down right" />
      </div>
    </div>
    <div v-show="showDropDown" class="dropdown-body">
      <div class="dropdown-body-item-lower" :style="widthStyle" v-if="selectedValue != null">
        <div
          v-for="(value, index) in values"
          v-bind:key="index"
          class="pointer dropdown-item"
          :class="[value.value === selectedValue.value ? 'dropdown-item-selected' : '']"
          v-on:click="
            selectedValue = value;
            toggleDropdown();
          "
        >
          {{ value.display }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as utils from "@/utils/utils.js";

export default {
  name: "SelectFilter",
  props: {
    width: String,
    values: Array,
    value: String,
    inheritz: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      selectedValue: null,
      showDropDown: false,
      freetextFilter: "",
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropDown = !this.showDropDown;
    },
  },
  computed: {
    widthStyle() {
      return `width: ${this.width}px;`;
    },
  },
  watch: {
    selectedValue() {
      this.$emit("input", this.selectedValue.value);
    },
    value() {
      this.selectedValue = this.values.filter(item => item.value === this.value)[0];
    },
  },
  mounted() {
    if (!this.inheritz) {
      utils.zIndexFix(this);
    }
    this.$nextTick().then(() => {
      if (this.value === "") {
        this.selectedValue = this.values[0];
      }
    });
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.dropdown-header {
  border: 1px solid #dcdcdc;
}

.open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown-body {
  position: absolute;
  background-color: #ffffff;
}

.dropdown-body-item-lower {
  border: 1px solid #dcdcdc;
  border-top: 0px;
  overflow-y: scroll;
  max-height: 300px;
}

.header-item {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.dropdown-item {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.dropdown-item-selected {
  background-color: #f1e6fc;
  color: #742c9c !important;
}

.dropdown-item:hover {
  background-color: #f1e6fc;
  color: #742c9c !important;
}

.dropdown-selected {
  margin: 8px;
  margin-left: 16px;
  margin-right: 16px;
}

.right {
  float: right;
}

i {
  border: solid #444444;
  border-width: 0 1px 1px 0;
  padding: 3px;
  position: relative;
  top: 7px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #742c9c;
  border-radius: 50%;
  position: relative;
  top: 7px;
  left: -10px;
}
</style>
