<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>CR-5 Enskild OU-grupp</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="gsuite_ou_path" v-model="filters.path" />
        </div>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading" />

    <div v-if="showData">
      <span v-if="notFound">{{ notFoundMessage }}</span>

      <div v-if="showObject" class="row-1-col space-top">
        <h2>OU-Grupp</h2>
        <TableSingleObject :tableData="dataobj" :fields="ouFields" :disableFiltering="false" csvFilename="CR-5 Export - OU-grupp" showMissing />
      </div>
      <div v-if="showObject" class="row-1-col space-top">
        <h2>Direkt underliggande OU-grupper</h2>
        <TablePaginated :tableData="children" :columns="childColumns" :disableFiltering="false" csvFilename="CR-5 Export - Direkt underliggande OU-grupper" />
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";

function routeToCr5(arg) {
  arg.root.$router.push({
    path: "/cr5",
    query: { oupath: arg.object.parentOUPath },
  });
}

function routeToCr5FromChild(arg) {
  arg.root.$router.push({
    path: "/cr5",
    query: { oupath: arg.object.OUPath },
  });
}

function routeToCr4(arg) {
  arg.root.$router.push({
    path: "/cr4",
    query: { oupath: arg.object.OUPath },
  });
}

export default {
  name: "Cr5",
  props: {},
  data() {
    return {
      isLoading: false,
      filters: { path: "" },
      dataobj: null,
      dataobjIsFor: null,
      children: [],

      ouFields: [
        {
          property: "Name",
          displayName: "Namn",
          show: true,
        },
        {
          property: "Description",
          displayName: "Beskrivning",
          show: true,
        },
        {
          property: "OUPath",
          displayName: "Sökväg",
          show: true,
        },
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "VerksamhetsId",
          show: true,
        },
        {
          property: "NumberOfUsers",
          displayName: "Användare i gruppen",
          show: true,
          onclick: routeToCr4,
        },
        {
          property: "parentName",
          displayName: "Direkt överliggande OU-grupp",
          show: true,
          onclick: routeToCr5,
        },
      ],
      childColumns: [
        {
          property: "OUPath",
          displayName: "Sökväg",
          show: true,
        },
        {
          property: "Name",
          displayName: "Namn",
          show: true,
          onclick: routeToCr5FromChild,
        },
        {
          property: "Description",
          displayName: "Beskrivning",
          show: true,
        },
      ],
    };
  },
  watch: {
    async "filters.path"() {
      await this.updateData();
    },
  },
  methods: {
    async updateData() {
      const currentFilters = _.cloneDeep(this.filters);
      if (!currentFilters.path) {
        this.dataobj = [];
        this.children = [];
        return;
      }
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/gsuite/ou", { params: { path: currentFilters.path } });
      this.dataobj = response.data;
      this.children = response.data.children;

      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    notFound() {
      return this.dataobj === "none";
    },
    showObject() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    showData() {
      return !this.isLoading && _.size(_.pickBy(this.filters)) > 0;
    },
    notFoundMessage() {
      return "Ingen ou-grupp med angiven sökväg hittades";
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
