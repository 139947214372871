<template>
  <div>
    <div class="container space-top">
      <div class="row-1-col">
        <div class="row-item">
          <div class="page-header">
            <h1>CR-4 Förteckning över Google-konton</h1>
          </div>
          <div class="row-flex space-top-sm">
            <RFilter configName="veda" v-model="serverFilters.veda_ids" />
            <RFilter configName="gsuite_ou_tree" v-model="serverFilters.ou_paths" />
            <RFilter configName="gsuite_ou_path" v-model="serverFilters.ou_path" />
          </div>
        </div>
      </div>
      <div class="row-flex">
        <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data</a>
      </div>
      <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
      <span v-if="notFound && showData">Hittade inga användare</span>
      <div v-if="showData && !notFound">
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="tableData" :columns="columns" :disableFiltering="false" csvFilename="CR-4 Export - CR-4 Förteckning över Google-konton" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";

function routeToCr3byEmail(arg) {
  arg.root.$router.push({
    path: "/cr3",
    query: { email: arg.clickedValue },
  });
}
function routeToCr5(arg) {
  arg.root.$router.push({
    path: "/cr5",
    query: { oupath: arg.clickedValue },
  });
}

export default {
  name: "Cr4",
  props: { endpoint: String },
  data() {
    return {
      isLoading: false,
      tableData: [],
      crossfilter: null,
      autoload: false,
      tableDataIsFor: null,

      columns: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "Verksamhetsid",
          show: false,
        },
        {
          property: "GivenName",
          displayName: "Förnamn",
          show: false,
        },
        {
          property: "FamilyName",
          displayName: "Efternamn",
          show: false,
        },
        {
          property: "FullName",
          displayName: "Visningsnamn",
          show: true,
        },
        {
          property: "PrimaryEmailAddress",
          displayName: "Primär epostadress",
          show: true,
          onclick: routeToCr3byEmail,
        },
        {
          property: "UId",
          displayName: "UID",
          show: true,
        },

        // {
        //   property: "AccountType",
        //   displayName: "Kontotyp",
        //   show: true,
        // },
        {
          property: "LastLoginTime",
          displayName: "Senaste inloggning",
          show: true,
        },
        {
          property: "CreationTime",
          displayName: "Konto skapat",
          show: true,
        },

        // {
        //   property: "EmailAddresses",
        //   displayName: "Epostadresser",
        //   show: true,
        // },
        {
          property: "IsAdmin",
          displayName: "Admin",
          show: false,
        },
        {
          property: "IsDelegatedAdmin",
          displayName: "Delegerad admin",
          show: false,
        },
        {
          property: "AgreedToTerms",
          displayName: "Accepterat användarvillkoren",
          show: false,
        },
        {
          property: "Suspended",
          displayName: "Konto avstängt",
          show: false,
        },
        {
          property: "Archived",
          displayName: "Konto arkiverat",
          show: false,
        },
        {
          property: "ChangePasswordAtNextLogin",
          displayName: "Lösenordsbyte krävs vid nästa inloggning",
          show: false,
        },
        {
          property: "ManagerPrimaryEmailAddress",
          displayName: "Chef",
          show: false,
          onclick: routeToCr3byEmail,
        },
        {
          property: "IsProtectedIdentity",
          displayName: "Skyddad identitet",
          show: false,
        },
        {
          property: "IsEnrolledIn2Sv",
          displayName: "Tvåfaktorsautentisering aktiverad",
          show: false,
        },
        {
          property: "IsEnforcedIn2Sv",
          displayName: "Tvåfaktorsautentisering krävs",
          show: false,
        },
        {
          property: "OUName",
          displayName: "OU-grupp namn",
          show: false,
          onclick: routeToCr5,
        },
        {
          property: "OUDescription",
          displayName: "OU-grupp beskrivning",
          show: false,
        },
        {
          property: "OUPath",
          displayName: "Sökväg OU-grupp",
          show: true,
          onclick: routeToCr5,
        },
        {
          property: "Title",
          displayName: "Titel",
          show: true,
        },
      ],
      filters: {},
      serverFilters: {
        veda_ids: [],
        ou_paths: [],
        ou_path: "",
      },
    };
  },
  watch: {
    async "serverFilters.veda_ids"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.ou_paths"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.ou_path"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      if (this.autoload && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = {};
      if (currentFilters.veda_ids.length) {
        reqParams.veda_ids = currentFilters.veda_ids;
      }

      if (currentFilters.ou_paths.length) {
        reqParams.ou_paths = currentFilters.ou_paths;
      }
      if (currentFilters.ou_path) {
        reqParams.ou_path = currentFilters.ou_path;
      }

      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/gsuite/users", { params: reqParams });
      // Make it possible to filter on boolean values
      // utils.renderTrueFalseNullDestructively(response.data);
      this.tableData = response.data;
      this.tableDataIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor);
    },
    showLoadButton() {
      return !_.isEqual(this.serverFilters, this.tableDataIsFor) && !this.autoload;
    },
    notFound() {
      return !this.tableData.length;
    },
  },
  async mounted() {
    await this.maybeUpdateData();
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
