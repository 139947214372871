<!--
     Use v-model for two-way binding a string.

     Implementation note: v-model is shorthand for setting a prop named "value"
     and reacting to an emitted event called "input". The implementation below
     will talk about props.value and $emit("input", newvalue).

     TODO:
   - Add a prop "formattip" and display it when pattern does not match,
     perhaps as a balloon.
   - If necessary, add a prop "options" containing an array of possible string
     values. Maybe display a dropdown with these options, filtered on
     inputboxvalue.
   - The input textbox is slightly higher than a line of text (in order to
     contain a line of text). This makes the component slightly higher than it
     should. Find a way to center the input textbox vertically without
     affecting the component height.
   - Link the for attribute of the label element to the input textbox, perhaps
     using the vue-uniq-ids package.
-->

<template>
  <div>
    <div :style="widthStyle" style="display: flex">
      <div class="text-header">{{ name }}</div>
      <input class="inputbox input" type="text" :class="{ badvalue: !valueOk }" v-model="inputboxvalue" />
    </div>
  </div>
</template>

<script>

import * as utils from "@/utils/utils.js";

export default {
  name: "TextFilter",
  props: {
    name: String,
    width: {
      type: String,
      default: "200",
    },
    value: String,
    pattern: {
      type: RegExp,
      default: () => /^/u,
    },
    inheritz: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputboxvalue: "",
      valueOk: true,
    };
  },
  methods: {},
  computed: {
    widthStyle() {
      return `width: ${this.width}px;`;
    },
  },
  watch: {
    value() {
      this.inputboxvalue = this.value;
    },
    inputboxvalue() {
      const value = this.inputboxvalue;
      this.valueOk = value === "" || value.match(this.pattern);
      if (this.valueOk) {
        this.$emit("input", value);
      }
    },
  },
  mounted() {
    if (!this.inheritz) {
      utils.zIndexFix(this);
    }
  },
};
</script>

<style scoped>
.text-header {
  border: 1px solid #dcdcdc;
  border-right: 0px;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  white-space: nowrap;
}

.namelabel {
  margin-right: 1em;
  position: relative;
  top: 6px;
}

.inputbox {
  /* //width: 10px; */
  flex-grow: 1;
}

.badvalue {
  background: #ffcccc;
}
</style>
