<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>CR-2.1 Sök ChromeOS enhet</h1>
            </div>
            <div class="row-flex space-top-sm">
              <RFilter configName="veda" v-bind:addEmpty="true" v-model="filters.veda.selected" />
            </div>
          </div>
        </div>

        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="reportData" :columns="columns" :disableFiltering="false" csvFilename="CR-2-1 Export - Sök ChromeOS enhet" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

function routeToCr1(arg) {
  arg.root.$router.push({
    path: "/cr1",
    query: { sn: arg.object.Serialnumber, cos: arg.object.CosNumber },
  });
}

export default {
  name: "Cr2-1",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      chartData: null,
      latestOsVersion: null,
      columns: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "VerksamhetsId",
          show: false,
        },
        {
          property: "UserPrincipalName",
          displayName: "Senaste användare",
          show: true,
        },
        {
          property: "UserId",
          displayName: "Senaste användare UID",
          show: true,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
          onclick: routeToCr1,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
          onclick: routeToCr1,
        },
        {
          property: "Model",
          displayName: "Modell",
          show: false,
        },
        {
          property: "GsuiteOUPath",
          displayName: "Sökväg OU-grupp",
          show: false,
        },
      ],
      filters: { veda: { selected: [] } },
    };
  },
  watch: {
    "filters.veda.selected"() {
      this.crossfilter.filterDimensionArrayContains("VedaDimension", this.filters.veda.selected);
      this.calculateReportComponentsData();
    },
  },
  methods: {
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.reportData);
      this.crossfilter.createDimensionByProperty("VedaDimension", "VedaId");
    },

    calculateReportComponentsData() {
      this.reportData = this.crossfilter.allFiltered();
    },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/gsuite/ChromeDevices")]).then(
      this.$http.spread(gsuiteDevicesResponse => {
        this.reportData = gsuiteDevicesResponse.data;

        this.initCrossfilter();
        this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
