<!--
     Props:
   - configName: Optional. The name of the configuration in ./namedFilters.js to
     use for any prop that is not given.
   - All other props (name, component, nodes, nodeEndpoint, width,
     qpsEncodingMethod, qpsParamName, defaultValue, pattern, addEmpty, values):
     See ./namedFilters.js for documentation. Props documented as mandatory need
     to be given either by way of configName, or directly.
-->

<template>
  <div v-if="config != null">
    <ValueFilter v-if="config.component === 'ValueFilter'" :name="config.name" :nodes="config.nodes" :value="value" @input="input" :width="config.width" :inheritz="true" />
    <TextFilter v-if="config.component === 'TextFilter'" :name="config.name" :value="value" @input="input" :pattern="config.pattern" :width="config.width" :inheritz="true" />
    <SelectFilter v-if="config.component === 'SelectFilter'" :values="config.values" :value="value" @input="input" :width="config.width" :inheritz="true" />
    <CheckBox v-if="config.component === 'CheckBox'" :display="config.name" :value="value" @click="checkBoxClick" :inheritz="true" />
    <QueryParamSync v-if="config.qpsParamName" :encodingMethod="config.qpsEncodingMethod" :paramName="config.qpsParamName" :value="value" :default="config.defaultValue" @input="input" />
  </div>
</template>

<script>

import * as _ from "lodash";
import * as utils from "@/utils/utils.js";
import { namedFilters } from "../../../../../shared";

const undef = {}.undef;

export default {
  name: "RFilter",
  props: {
    configName: String,
    name: String,
    component: String,
    nodes: Array,
    nodeEndpoint: String,
    EndpointParameter: Object,
    width: String,
    qpsEncodingMethod: String,
    qpsParamName: String,
    defaultValue: { type: null, default: undef },
    pattern: RegExp,
    addEmpty: Boolean,
    values: Array,
    value: null,
  },
  data() {
    return { config: null };
  },
  methods: {
    createConfig() {
      let configObject = {};
      if (this.configName) {
        configObject = _.cloneDeep(namedFilters[this.configName]);
      }
      for (const el of ["name", "component", "nodes", "nodeEndpoint", "EndpointParameter", "width", "qpsEncodingMethod", "qpsParamName", "defaultValue", "pattern", "addEmpty", "values"]) {
        if (this[el]) {
          configObject[el] = this[el];
        }
      }
      this.config = configObject;
    },
    getNodesAsync() {
      if (this.config.component === "ValueFilter" && !this.config.nodes) {
        this.$http
          .get(this.config.nodeEndpoint, { params: this.config.EndpointParameter })
          .then(response => {
            if (this.config.addEmpty) {
              response.data.push({
                display: "(Tom)",
                value: "",
              });
            }
            this.config = { ...this.config, nodes: response.data };
          });
      }
    },
    input(value) {
      this.$emit("input", value);
    },
    checkBoxClick() {
      this.$emit("input", !this.value);
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.createConfig();
    this.getNodesAsync();
    utils.zIndexFix(this);
  },
};
</script>
