<template>
  <div>
    <div class="container space-top">
      <div class="row-1-col">
        <div class="row-item">
          <div class="page-header">
            <h1>SCCM-2 Applikationsanvändning</h1>
          </div>
          <div class="row-flex space-top-sm">
            <RFilter configName="veda" v-model="serverFilters.veda_ids" />
            <RFilter configName="sccm_app_manufacturers" v-model="serverFilters.app_manufacturer" />
            <RFilter configName="sccm_app_versions" v-model="serverFilters.app_version" />
            <RFilter configName="sccm_os_versions" v-model="serverFilters.os_version" />
            <RFilter configName="device_cosnumber" v-model="serverFilters.cosnumber" />
          </div>
        </div>
      </div>
      <div class="row-flex">
        <button class="button-standard noselect" :disabled="enableLoadButton" @click="updateData">Hämta data</button>
      </div>
      <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
      <span v-if="limited && showData"> Sökningen gav över {{ rowLimit }} resultat. Försök att begränsa sökningen. Visar de {{ rowLimit }} första resultaten </span>
      <div v-if="showData">
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="tableData" :columns="columns" :disableFiltering="false" csvFilename="SCCM-2 Export Applikationsanvändning" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "Sccm2",
  props: { endpoint: String },
  data() {
    return {
      isLoading: false,
      rowLimit: 10000,
      limited: false,
      tableData: [],
      crossfilter: null,
      autoload: false,
      tableDataIsFor: null,
      columns: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "Verksamhetsid",
          show: false,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
        },
        {
          property: "BIOSSerialNumber",
          displayName: "Enhetsid",
          show: false,
        },
        {
          property: "Model",
          displayName: "Enhetsmodell",
          show: true,
        },
        {
          property: "DeviceType",
          displayName: "Enhetstyp",
          show: true,
        },
        {
          property: "Os",
          displayName: "OS",
          show: false,
        },
        {
          property: "OsVersion",
          displayName: "OS-version",
          show: false,
        },
        {
          property: "FullName",
          displayName: "Enhetsägare",
          show: true,
        },
        {
          property: "UserId",
          displayName: "Enhetsägarid",
          show: true,
        },
        {
          property: "ProgramManufacturer",
          displayName: "Applikationstillverkare",
          show: true,
        },
        {
          property: "ProgramName",
          displayName: "Applikationsnamn",
          show: true,
        },
        {
          property: "ProgramVersion",
          displayName: "Applikationsversion",
          show: true,
        },
        {
          property: "LastUsed",
          displayName: "Senast använt",
          show: true,
        },
        {
          property: "NumOfTimesUsed",
          displayName: "Antal gånger applikationen använts",
          show: true,
        },
      ],
      filters: {},
      serverFilters: {
        veda_ids: [],
        app_manufacturer: [],
        app_version: "",
        os_version: [],
        cosnumber: "",
      },
    };
  },
  watch: {
    async "serverFilters.veda_ids"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.app_manufacturer"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.app_version"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.os_version"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.cosnumber"() {
      await this.updateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      if (this.autoload && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = {};
      // Request one record more than limit so we can detect whether the result
      // set was truncated
      reqParams.limit = this.rowLimit + 1;
      if (currentFilters.veda_ids.length) {
        reqParams.veda_ids = currentFilters.veda_ids;
      }
      if (currentFilters.app_manufacturer.length) {
        reqParams.app_manufacturer = currentFilters.app_manufacturer;
      }
      if (currentFilters.app_version) {
        reqParams.app_version = currentFilters.app_version;
      }
      if (currentFilters.os_version.length) {
        reqParams.os_version = currentFilters.os_version;
      }
      if (currentFilters.cosnumber) {
        reqParams.cosnumber = currentFilters.cosnumber;
      }
      if (_.isEmpty(reqParams)) {
        return;
      }

      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/sccm/applicationusage", { params: reqParams });
      // Make it possible to filter on boolean values
      // utils.renderTrueFalseNullDestructively(response.data);
      const data = response.data;
      if (data.length > this.rowLimit) {
        this.tableData = data.slice(0, this.rowLimit);
        this.limited = true;
      } else {
        this.tableData = data;
        this.limited = false;
      }
      this.tableDataIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor);
    },
    enableLoadButton() {
      return !Object.values(this.serverFilters).some(x => x && !(Array.isArray(x) && x.length === 0));
    },
  },
  async mounted() {
    await this.maybeUpdateData();
  },
};
</script>
