<template>
  <div v-show="show">
    <CheckBox
      :value="{ yes: true, no: false, partly: null }[state]"
      :class="{ leaf: isLeaf, branch: !isLeaf }"
      :display="node.display || node.value"
      @click="click"
      style="padding-top: 2px; padding-bottom: 2px"
    />
    <div class="nodepadding">
      <ValueNode v-for="childNode in node.children" :node="childNode" :key="childNode.pkey" :selectedValues="selectedValues" :shownNodes="shownNodes" @add="add" @remove="remove" />
    </div>
  </div>
</template>

<script>

import * as _ from "lodash";

function isLeaf(node) {
  return !(node.children && node.children.length > 0);
}

function walkTreeLeafs(tree, fun) {
  if (isLeaf(tree)) {
    fun(tree);
  }
  if (tree.children) {
    _.map(tree.children, node => walkTreeLeafs(node, fun));
  }
}

export default {
  name: "ValueNode",
  props: {
    node: Object,
    selectedValues: Set,
    shownNodes: Set,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    click() {
      // Clicking a node that is unselected or partly selected will select all
      // nodes in that subtree. Clicking a node that is selected will unselect
      // all nodes in that subtree.
      const valuestosend = [];
      walkTreeLeafs(this.node, x => valuestosend.push(x.value));
      this.$emit(this.state === "yes" ? "remove" : "add", valuestosend);
    },
    // Propagate add/remove messages
    add(values) {
      this.$emit("add", values);
    },
    remove(values) {
      this.$emit("remove", values);
    },
  },
  computed: {
    state() {
      // For leaf nodes, selectedValues determines state.
      if (isLeaf(this.node)) {
        return this.selectedValues.has(this.node.value) ? "yes" : "no";
      }
      // Otherwise, find out whether there exists some children that are
      // selected resp. unselected
      let someisselected = false;
      let someisunselected = false;
      walkTreeLeafs(this.node, x => {
        if (this.selectedValues.has(x.value)) {
          someisselected = true;
        } else {
          someisunselected = true;
        }
      });
      // Parent nodes are selected if all children are selected.
      if (someisselected && !someisunselected) {
        return "yes";
      }
      // Parent nodes are unselected if all children are unselected.
      if (!someisselected && someisunselected) {
        return "no";
      }
      // Parent nodes with a mix of selected and unselected children have state
      // 'partly', but are not included in selectedValues.
      return "partly";
    },
    show() {
      return this.shownNodes.has(this.node.pkey);
    },
    isLeaf() {
      return isLeaf(this.node);
    },
  },
};
</script>

<style scoped>
.nodepadding {
  padding-left: 10px;
}

.leaf {
  color: black;
}

.branch {
  color: #a0a0a0;
  font-weight: bold;
}
</style>
