<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>LEV-2 Förteckning över Verksamheter</h1>
            </div>
            <div class="row-flex space-top-sm">
              <RFilter configName="veda_all" v-model="filters.veda.selected" />
              <RFilter configName="veda_active" v-model="filters.active.selected" />
            </div>
          </div>
        </div>
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="reportData" :columns="columns" :disableFiltering="false" csvFilename="LEV-2  Export - Förteckning över verksamheter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

export default {
  name: "Lev2",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      columns: [
        {
          property: "Name",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "VerksamhetsId",
          show: true,
        },
        {
          property: "Active",
          displayName: "Aktiv",
          show: true,
        },
        {
          property: "CostCenterCode",
          displayName: "Kostnadställe",
          show: true,
        },
        {
          property: "CostCenterName",
          displayName: "Beskrivning kostnadsställe",
          show: true,
        },
        {
          property: "ParentName",
          displayName: "Överordnad verksamhetsnamn",
          show: true,
        },
        {
          property: "ParentVedaId",
          displayName: "Överordnad verksamhetsId",
          show: true,
        },
      ],
      filters: {
        veda: { selected: [] },
        active: { selected: [] },
      },
    };
  },
  watch: {
    "filters.veda.selected"() {
      this.crossfilter.filterDimensionArrayContains("VedaDimension", this.filters.veda.selected);
      this.calculateReportComponentsData();
    },
    "filters.active.selected"() {
      this.crossfilter.filterDimensionArrayContains("ActiveDimension", this.filters.active.selected);
      this.calculateReportComponentsData();
    },
  },
  methods: {
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.reportData);
      this.crossfilter.createDimensionByProperty("VedaDimension", "VedaId");
      this.crossfilter.createDimensionByProperty("ActiveDimension", "Active");
    },
    calculateReportComponentsData() {
      this.reportData = this.crossfilter.allFiltered();
    },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/delivery/businesses")]).then(
      this.$http.spread(gsuiteOUsResponse => {
        this.reportData = gsuiteOUsResponse.data;
        this.initCrossfilter();
        this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
