<template>
  <div v-if="displayError" class="container">
    <div class="alert-danger space-top-sm" v-for="(error, index) in errors" :key="index">
      <strong>Fel!</strong> Någonting gick inte som det skulle.
      <br />
      <strong>Mål:</strong>
      {{ error.endpoint }}
      <br />
      <strong>Fel-ID:</strong>
      {{ describe(error) }}
      <a class="alert-close pointer" @click="dismissError(index)"></a>
    </div>
  </div>
</template>

<script>


import { EventBus } from "../../utils/eventBus/EventBus.js";

export default {
  name: "ErrorHandler",
  data() {
    return { errors: [] };
  },
  methods: {
    errorEvent(error) {
      this.errors.push(error);
    },
    dismissError(index) {
      this.errors.splice(index, 1);
    },
    removeEvents() {
      this.errors = [];
    },
    describe(error) {
      if (error.errorLogId && error.type) {
        return `${error.errorLogId} (${error.type})`;
      }
      return error.errorLogId || error.type || "Unknown error";
    },
  },
  computed: {
    displayError() {
      return this.errors.length > 0;
    },
  },
  beforeMount() {
    EventBus.$on("serverError", this.errorEvent);
    EventBus.$on("routerchange", this.removeEvents);
  },
  beforeDestroy() {
    EventBus.$off("serverError", this.errorEvent);
    EventBus.$off("routerchange", this.removeEvents);
  },
};
</script>
