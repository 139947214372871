<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="user_uid" v-model="serverFilters.user_uid" v-if="showFilter_user_uid" />
          <RFilter configName="user_dn" v-model="serverFilters.user_dn" v-if="showFilter_user_dn" />
          <RFilter configName="veda" v-model="serverFilters.veda_ids" v-if="showFilter_veda_ids" />
          <RFilter configName="group_type" v-model="serverFilters.group_type" v-if="showFilter_group_type" />
        </div>
      </div>
    </div>
    <div class="row-flex">
      <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <div v-if="showData" class="row-1-col space-top">
      <h2>Grupper</h2>
      <TablePaginated :tableData="tableData" :columns="groupColumns" :disableFiltering="false" :csvFilename="csvFilename" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as adconf from "./AdViewConfig.js";

const variation = rpt => ({
  title: {
    Ad4: "AD-4 Förteckning över grupper",
    Ad4_1: "AD-4.1 Förteckning över klasser per verksamhet",
    Ad4_2: "AD-4.2 Förteckning över kurser per verksamhet",
    Ad4_3: "AD-4.3 Förteckning över program per gymnasieverksamhet",
    Ad4_4: "AD-4.4 Förteckning över projekt per grundskoleverksamhet",
    Ad4_5: "AD-4.5 Förteckning över ämnen per grundskoleverksamhet",
    Ad4_6: "AD-4.6 Förteckning över årskurser per verksamhet",
  }[rpt],

  csvFilenameFun() {
    return {
      Ad4: "AD-4 Export Förteckning över grupper",
      Ad4_1: "AD-4.1 Export Förteckning över klasser per verksamhet",
      Ad4_2: "AD-4.2 Export Förteckning över kurser per verksamhet",
      Ad4_3: "AD-4.3 Export Förteckning över program per gymnasieverksamhet",
      Ad4_4: "AD-4.4 Export Förteckning över projekt per grundskoleverksamhet",
      Ad4_5: "AD-4.5 Export Förteckning över ämnen per grundskoleverksamhet",
      Ad4_6: "AD-4.6 Export Förteckning över årskurser per verksamhet",
    }[rpt];
  },

  autoload: false,

  serverFilters: {
    group_type: {
      Ad4: "",
      Ad4_1: "CLASS",
      Ad4_2: "KURS",
      Ad4_3: "PROGRAM",
      Ad4_4: "PROJ",
      Ad4_5: "AMNE",
      Ad4_6: "AK",
    }[rpt],
  },

  showFilter_user_uid: rpt === "Ad4",
  showFilter_user_dn: rpt === "Ad4",
  showFilter_veda_ids: true,
  showFilter_group_type: rpt === "Ad4",

  groupColumns: adconf.showColumns(adconf.groupViewConfig, rpt, {
    displayName: "yyyyyyy",
    description: "yyyyyyy",
    sn: "hnnnnnn",
    sAMAccountName: "yhhhhhh",
    cn: "hnnnnnn",
    distinguishedName: "hnnnnnn",
    whenChanged: "hhhhhhh",
    whenCreated: "hhhhhhh",
    managedBy: "hyyyyyy",
  }),
});

export default {
  name: "Ad4",
  data() {
    return _.defaultsDeep(variation(this.$route.name), {
      isLoading: false,
      serverFilters: {
        user_uid: "",
        user_dn: "",
        veda_ids: [],
      },
      filters: {},
      tableData: [],
      tableDataIsFor: null,
    });
  },
  watch: {
    async "serverFilters.user_uid"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.user_dn"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.veda_ids"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.group_type"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      if (this.autoload && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = {};
      if (currentFilters.user_uid) {
        reqParams.user_uids = [currentFilters.user_uid];
      }
      if (currentFilters.user_dn) {
        reqParams.user_dns = [currentFilters.user_dn];
      }
      if (currentFilters.veda_ids.length) {
        reqParams.veda_ids = currentFilters.veda_ids;
      }
      if (currentFilters.group_type) {
        reqParams.group_type = currentFilters.group_type;
      }
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/ad/groups", { params: reqParams });
      this.tableData = response.data;
      this.tableDataIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor);
    },
    showLoadButton() {
      return !_.isEqual(this.serverFilters, this.tableDataIsFor) && !this.autoload;
    },
    csvFilename() {
      return this.csvFilenameFun();
    },
  },
  async mounted() {
    await this.maybeUpdateData();
  },
};
</script>

<style></style>
