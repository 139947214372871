<template>
  <div>
    <div class="container space-top">
      <div class="page-header">
        <h1>MDM-1 Enskild iOS enhet</h1>
      </div>
    </div>
    <div class="container space-top">
      <div class="row-1-col">
        <div class="row-item">
          <div class="row-flex space-top-sm">
            <RFilter configName="intune_ios_serialnumber" v-model="filters.serialnumber" />
            <RFilter configName="device_cosnumber" v-model="filters.cosnumber" />
          </div>
        </div>
      </div>
      <div class="row-flex">
        <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
      </div>
      <LoadingSpinner :isLoading="isLoading" />
      <span v-if="notSingle">{{ notSingleMessage }}</span>
      <div v-if="showData" class="row-1-col space-top">
        <h2>Data</h2>
        <TableSingleObject :tableData="dataobj" :fields="deviceFields" :csvFilename="csvFilename_single" showMissing />
      </div>
      <div v-if="showData" class="row-1-col space-top">
        <h2>Tilldelade applikationer</h2>
        <TablePaginated :tableData="applicationsDataObj" :columns="applicationsFields" :disableFiltering="false" :csvFilename="csvFilename_table" />
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "Mdm1",
  props: {},
  data() {
    return {
      isLoading: false,
      filters: {
        serialnumber: "",
        cosnumber: "",
      },
      dataobj: null,
      dataobjIsFor: null,
      applicationsDataObj: null,
      deviceFields: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "Verksamhetsid",
          show: true,
        },
        {
          property: "InvoiceReference",
          displayName: "Fakturareferens",
          show: true,
        },
        {
          property: "Model",
          displayName: "Modell",
          show: true,
        },
        {
          property: "OperatingSystemFull",
          displayName: "Operativsystem",
          show: true,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
        },
        {
          property: "userPrincipalName",
          displayName: "Användare",
          show: true,
        },
        {
          property: "Supervised",
          displayName: "DEP",
          show: true,
        },
        {
          property: "Enrolled",
          displayName: "Registrerad",
          show: true,
        },
        {
          property: "LastSynchronization",
          displayName: "Senast synkroniserad",
          show: true,
        },
      ],
      applicationsFields: [
        {
          property: "Name",
          displayName: "Namn",
          show: true,
        },
        {
          property: "Version",
          displayName: "Version",
          show: true,
        },
      ],
    };
  },
  methods: {
    async updateData() {
      this.isLoading = true;
      const currentFilters = _.cloneDeep(this.filters);

      const params = {};
      if (currentFilters.serialnumber) {
        params.serialnumber = currentFilters.serialnumber;
      }
      if (currentFilters.cosnumber) {
        params.cosnumber = currentFilters.cosnumber;
      }

      const deviceResponse = await this.$http.get("/api/reporting/mdm/iosDevice", { params });
      const applicationsResponse = await this.$http.get("/api/reporting/mdm/iosAssignedApplications", { params });
      // Make it possible to filter on boolean values
      this.dataobj = deviceResponse.data;
      this.applicationsDataObj = applicationsResponse.data;

      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    notSingle() {
      // console.log({
      //  nsa: _.isEqual(this.filters, this.dataobjIsFor),
      //  nsb: _.isString(this.dataobj),
      // });
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isString(this.dataobj);
    },
    showData() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    notSingleMessage() {
      if (this.dataobj === "none") {
        return "Ingen sådan enhet kunde hittas";
      }
      if (this.dataobj === "too many") {
        return "Fler än en enhet matchar sökningen";
      }
      return "";
    },
    showLoadButton() {
      return !_.isEqual(this.filters, this.dataobjIsFor);
    },
    csvFilename_single() {
      return `MDM-1 Export - iOS enhet ${this.dataobj.Serialnumber}`;
    },
    csvFilename_table() {
      return `MDM-1 Export - Tilldelade applikationer för iOS enhet ${this.dataobj.Serialnumber}`;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
