
import * as _ from "lodash";
import * as assert from "assert";
import * as oidcHandler from "./utils/idportal/oidcHandler.js";
import { EventBus } from "./utils/eventBus/EventBus.js";
import Router from "vue-router";
import Vue from "vue";
import { authorizeRoles } from "../../shared";
import coreRoutes from "./routes/coreRoutes.js";
import reportingAdRoutes from "./routes/reportingAdRoutes.js";
import reportingRoutes from "./routes/reportingRoutes.js";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [...coreRoutes(), ...reportingRoutes(), ...reportingAdRoutes()],
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== from.name) {
    EventBus.$emit("routerchange", null);
  }

  if (to.name === "Auth") {
    oidcHandler.redirectToAuthorize();
  }

  if (to.name === "Auth_return") {
    const token = await oidcHandler.handleAuthenticateCallback(to.fullPath);

    store.dispatch("authenticateUser", token);
    router.push({ path: "/reporting" });
  }

  if (to.meta.allowWithoutLogin) {
    next();
  } else if (store.getters.authenticated) {
    const authorizations = store.getters.authenticatedUserAuthorizations;
    const rolesobject = {};
    for (const { role } of authorizations) {
      rolesobject[role] = true;
    }
    const roleTestFunction = authorizeRoles[to.name];
    assert(_.isFunction(roleTestFunction));
    if (roleTestFunction(rolesobject)) {
      next();
    } else {
      next({ path: "/unauthorized" });
    }
  } else {
    next({ path: "/auth" });
  }
});

export default router;
