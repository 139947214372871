<!--
     Shows a table with the properties/values of a single object.
     The columns for attribute and value have free-text filters.

     Props:
   - tableData, Object.
     The object to show.
   - disableFiltering, Boolean, default false.
     Disable free-text filters.
   - showMissing, Boolean, default false.
     Show all rows in fields even if not present in tableData.
   - paramPrefix, String. The prefix for the query parameters that store the
     state of the filters. It is only necessary to set this if there is more
     than one TableSingleObject on the page.
   - fields, array of fielddefs, optional.
     If not given, rows are sorted on property and all properties are present in
     table. If present, only properties found in fields are shown, showMissing
     determines whether to only show properties found in tableData, and they are
     sorted according to fields. A fielddef is an object with the following
     properties:
     - property, String, mandatory.
       The name of the property.
     - displayName, String, optional.
       The name to show for the property. If not given, property is used instead.
     - formatter, Function, optional.
       A function used to format the value. If not given, the value is used
       as-is. After formatting, the value should be either a String, an Array of
       String, or an Object with String values.
     - onclick, Function, optional.
       If given, make this item clickable and on click, call the function with
       one argument, an object containing the following keys:
       - root: The root Vue instance.
       - object: The object shown in the table.
       - property: The property for the row.
       - value: The value for the row.
       - clickedValue: value[key] if value is an Array or Object, otherwise
           same as value.
       - key: The index or key to the item within the value that was clicked.
         Only present if the value is an Array or Object.
       - propconf: The fielddef for the row.
-->

<template>
  <div class="space-bottom">
    <QueryParamSync encodingMethod="Straight" :paramName="paramPrefix + 'attr'" v-model="filters.column_property" />
    <QueryParamSync encodingMethod="Straight" :paramName="paramPrefix + 'val'" v-model="filters.column_value" />
    <table class="block-table">
      <thead>
        <tr>
          <th colspan="100%" style="font-weight: normal; !important; background-color: #FFFFFF !important;">
            <div class="row-flex">
              <JsonCSVExcel :data="[tableData]" :name="csvFilename + '.csv'" :columns="fields">
                <a class="button-standard noselect">Exportera till Excel</a>
              </JsonCSVExcel>
            </div>
          </th>
        </tr>
        <tr>
          <th>Attribut</th>
          <th>Värde</th>
        </tr>
        <tr>
          <th>
            <input type="text" class="input-sm" v-model="filters.column_property" />
          </th>
          <th>
            <input type="text" class="input-sm" v-model="filters.column_value" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in filteredRows" v-bind:key="row.property">
          <td>{{ row.property }}</td>
          <td>
            <template v-if="isObjectButNotArray(row.value)">
              <ul class="table-ul">
                <li class="table-li" v-for="(objKey, index) in Object.keys(row.value)" v-bind:key="index">
                  <b>{{ objKey }}</b
                  >:
                  <template v-if="row.onclick">
                    <a
                      class="link"
                      @click="
                        row.onclick({
                          root: $root,
                          object: tableData,
                          property: row.property,
                          value: row.value,
                          clickedValue: row.value[objKey],
                          key: objKey,
                          propconf: row.fielddef,
                        });
                        $event.preventDefault();
                      "
                      >{{ vrender(row.value[objKey]) }}</a
                    >
                  </template>
                  <template v-else>
                    {{ vrender(row.value[objKey]) }}
                  </template>
                </li>
              </ul>
            </template>
            <template v-else-if="isArray(row.value)">
              <ul class="table-ul">
                <li v-for="(listObj, index) in row.value" v-bind:key="index" class="table-li">
                  <template v-if="row.onclick">
                    <a
                      class="link"
                      @click="
                        row.onclick({
                          root: $root,
                          object: tableData,
                          property: row.property,
                          value: row.value,
                          clickedValue: row.value[index],
                          key: index,
                          propconf: row.fielddef,
                        });
                        $event.preventDefault();
                      "
                      >{{ vrender(listObj) }}</a
                    >
                  </template>
                  <template v-else>
                    {{ vrender(listObj) }}
                  </template>
                </li>
              </ul>
            </template>
            <template v-else>
              <template v-if="row.onclick">
                <a
                  class="link"
                  @click="
                    row.onclick({
                      root: $root,
                      object: tableData,
                      property: row.property,
                      value: row.value,
                      clickedValue: row.value,
                      propconf: row.fielddef,
                    });
                    $event.preventDefault();
                  "
                  >{{ vrender(row.value) }}</a
                >
              </template>
              <template v-else>
                {{ vrender(row.value) }}
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import * as _ from "lodash";

// Determine whether the string part is included in whole, or any element,
// property or value of whole (if it is an array or object). Use empty string in
// place of undefined whole.
function includes(whole, part) {
  if (part === "") {
    return true;
  }
  if (_.isUndefined(whole)) {
    whole = "";
  }
  part = part.toLowerCase();
  if (_.isString(whole)) {
    return whole.toLowerCase().includes(part);
  } else if (_.isArray(whole)) {
    for (const element of whole) {
      if (includes(element, part)) {
        return true;
      }
    }
  } else if (_.isObject(whole)) {
    for (const property in whole) {
      if (includes(property, part)) {
        return true;
      }
      if (includes(whole[property], part)) {
        return true;
      }
    }
  }
  return false;
}

export default {
  name: "TableSingleObject",
  props: {
    tableData: Object,
    fields: {
      type: Array,
      default: null,
    },
    csvFilename: {
      type: String,
      default: `Detail ${new Date().toISOString()}`,
    },
    disableFiltering: {
      type: Boolean,
      default: false,
    },
    showMissing: {
      type: Boolean,
      default: false,
    },
    paramPrefix: {
      type: String,
      default: "tso",
    },
  },
  data() {
    return {
      filters: {
        column_property: "",
        column_value: "",
      },
    };
  },
  methods: {
    vrender(value) {
      if (_.isString(value) || _.isInteger(value)) {
        return value;
      }
      if (value === true) {
        return "Sant";
      }
      if (value === false) {
        return "Falskt";
      }
      return "";
    },

    isObjectButNotArray: value => _.isObject(value) && !_.isArray(value),
    isArray: value => _.isArray(value),
  },
  computed: {
    rows() {
      if (this.tableData === null) {
        return [];
      }
      let tableDataObjectList = [];
      if (this.fields) {
        for (const fielddef of this.fields) {
          if (this.showMissing || fielddef.property in this.tableData) {
            tableDataObjectList.push({
              property: fielddef.displayName || fielddef.property,
              value: (fielddef.formatter || _.identity)(this.tableData[fielddef.property]),
              fielddef,
              onclick: fielddef.onclick,
            });
          }
        }
      } else {
        for (const property in this.tableData) {
          tableDataObjectList.push({
            property,
            value: this.tableData[property],
          });
        }
        tableDataObjectList = _.sortBy(tableDataObjectList, [x => x.property.toLowerCase()]);
      }
      return tableDataObjectList;
    },
    filteredRows() {
      return this.rows.filter(row => includes(row.property, this.filters.column_property) && includes(row.value, this.filters.column_value));
    },
  },
};
</script>

<style scoped>
.filter-container {
  border: 1px solid #dcdcdc;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  display: flex;
}

.filter-namelabel {
  margin-right: 1em;
}

.filter-inputbox {
  width: 10px;
  flex-grow: 1;
}
</style>
