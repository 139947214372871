
import * as futile from "@fujitsusweden/futile";
import * as utils from "@/utils/utils.js";
import _ from "lodash";
import crossfilter from "crossfilter2";
import { momentHandler } from "@/../../shared";

export default class CrossfilterHandler {
  constructor(data) {
    let i = 0;
    data.forEach(d => {
      d.cfId = i;
      i += 1;
    });

    this.instance = crossfilter(data);
    this.dimensions = [];
    this.sortDimensions = [];
    const dimension = this.instance.dimension(item => item.cfId);

    this.dimensions.push({
      name: "cfIdDimension",
      dimension,
    });
  }

  addRow(row) {
    this.instance.add([row]);
  }

  removeRow(cfId) {
    this.instance.remove((r, ignored__i) => r.cfId === cfId);
  }

  all() {
    return this.instance.all();
  }

  // Since crossfilter don't support row updates we have to remove and re-add an updated row
  // Doing that will add the updated row at the last position and the order will be changed if no dimension order is active
  // Therefor we sort by cfIdDimension instead of using allFiltered()
  allFiltered() {
    return this.getDimension("cfIdDimension").top(Infinity);
  }

  dimensionExist(name) {
    return this.dimensions.some(dimension => dimension.name === name);
  }

  sortDimensionExist(name) {
    return this.sortDimensions.some(dimension => dimension.name === name);
  }

  generateSortDimension(column) {
    if (this.sortDimensionExist(column)) {
      return;
    }
    const collator = new Intl.Collator("sv", {
      sensitivity: "base",
      numeric: true,
    });
    const orderdColumnData = this.instance
      .all()
      .map(({ [column]: value, cfId }) => ({ value, cfId }))
      // Then sort the array based on value
      .sort((a, b) => {
        const aProp = a.value || "";
        const bProp = b.value || "";
        // The collator sorts empty strings first, we want them last.
        if (aProp === bProp) {
          return 0;
        }
        if (aProp === "") {
          return 1;
        }
        if (bProp === "") {
          return -1;
        }
        return collator.compare(aProp, bProp);
      });
    // Create a map with crossfilterindex and index from the orderd array
    const orderdColumnMap = new Map(orderdColumnData.map(({ cfId }, index) => [cfId, index]));
    this.createSortDimensionByMap(column, orderdColumnMap);
  }

  createSortDimensionByMap(name, map) {
    const dimension = this.instance.dimension(item => map.get(item.cfId));
    this.sortDimensions.push({
      name,
      dimension,
    });
  }

  createDimensionByProperty(name, property) {
    if (this.dimensionExist(name)) {
      throw futile.err("Dimension with this name already exists", { name });
    }
    const dimension = this.instance.dimension(item => item[property]);
    this.dimensions.push({ name, dimension });
  }

  createDimensionByMomentOnProperty(name, property, dateInputFormat, dateDimensionFormat) {
    if (this.dimensionExist(name)) {
      throw futile.err("Dimension with this name already exists", { name });
    }
    const dimension = this.instance.dimension(item => momentHandler.formatDatestring(item[property], dateInputFormat, dateDimensionFormat));
    this.dimensions.push({ name, dimension });
  }

  getDimension(dimensionName) {
    const dimensions = this.dimensions.filter(dimension => dimension.name === dimensionName);
    if (dimensions.length === 1) {
      return dimensions[0].dimension;
    } else {
      throw futile.err("Could not find dimension with this name", { dimensionName });
    }
  }

  getSortDimension(dimensionName) {
    const dimensions = this.sortDimensions.filter(dimension => dimension.name === dimensionName);
    if (dimensions.length === 1) {
      return dimensions[0].dimension;
    } else {
      throw futile.err("Could not find dimension with this name", { dimensionName });
    }
  }

  removeAllFilters() {
    this.dimensions.forEach(dim => {
      dim.dimension.filter(null);
    });
  }

  filterDimension(name, filterValue) {
    const dimension = this.getDimension(name);
    dimension.filter(null);

    if (_.isUndefined(filterValue) || filterValue === "") {
      return;
    }

    dimension.filter(item => utils.includes(item, filterValue));
  }

  filterDimensionExact(name, filterValue) {
    const dimension = this.getDimension(name);
    dimension.filter(null);

    if (_.isUndefined(filterValue) || filterValue === "") {
      return;
    }

    dimension.filter(item => item === filterValue);
  }

  filterDimensionArrayContains(name, array) {
    const arrayUpper = array.map(item => utils.getUpperCaseOrEmptyString(item));
    const dimension = this.getDimension(name);
    dimension.filter(null);

    if (array.length > 0) {
      dimension.filter(item => arrayUpper.indexOf(utils.getUpperCaseOrEmptyString(item)) > -1);
    }
  }

  groupReduceCount(dimensionName) {
    const dimension = this.getDimension(dimensionName);

    return dimension.group().reduceCount().all();
  }

  groupReduceSum(dimensionName, property) {
    const dimension = this.getDimension(dimensionName);

    return dimension
      .group()
      .reduceSum(item => item[property])
      .all();
  }

  countOnValue(dimensionName, value) {
    const reducedCount = this.groupReduceCount(dimensionName);
    const result = reducedCount.filter(rc => rc.key === value)[0];
    if (_.isUndefined(result)) {
      return 0;
    }
    return result.value;
  }

  // Pass properties to only return the reducedCount matching a property
  barCharReduceCount({ dimensionName, properties = null, sort = false, removeZeroValues = false, reverse = false }) {
    let reducedCount = this.groupReduceCount(dimensionName);

    if (removeZeroValues) {
      reducedCount = reducedCount.filter(item => item.value !== 0);
    }
    if (sort) {
      reducedCount = _.orderBy(reducedCount, ["value"], ["desc"]);
    }
    if (reverse) {
      reducedCount.reverse();
    }
    const countSet = [];
    if (properties === null) {
      properties = [];
      reducedCount.forEach(rc => {
        properties.push({ label: rc.key });
        countSet.push(rc.value);
      });
    } else {
      properties.forEach(property => {
        const count = reducedCount.filter(item => item.key === property.value);
        if (count.length === 1) {
          countSet.push(count[0].value);
        } else {
          countSet.push(0);
        }
      });
    }

    const barChartData = {
      labels: properties.map(item => item.label),
      datasets: [{ name: "", data: countSet }],
    };

    return barChartData;
  }

  barChartReduceSumMultiDataset(configurations) {
    const result = {
      labels: configurations[0].properties.map(item => item.label),
      datasets: [],
    };
    configurations.forEach(item => {
      const reducedSum = this.barChartReduceSum(item.dimensionName, item.sumProperty, item.properties);
      result.datasets.push({
        label: item.label,
        ...reducedSum.datasets[0],
      });
    });

    return result;
  }

  barChartReduceSum(dimensionName, sumProperty, properties) {
    const reducedSum = this.groupReduceSum(dimensionName, sumProperty);
    const countSet = [];

    properties.forEach(property => {
      const count = reducedSum.filter(item => item.key === property.value);
      if (count.length === 1) {
        countSet.push(count[0].value);
      } else {
        countSet.push(0);
      }
    });

    const barChartData = {
      labels: properties.map(item => item.label),
      datasets: [{ name: "", data: countSet }],
    };

    return barChartData;
  }
}
