<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>Rapporter</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter name="Sök rapport" component="TextFilter" width="400" qpsEncodingMethod="Straight" qpsParamName="q" v-model="filters.search" />
          <QueryParamSync encodingMethod="Straight" paramName="tab" v-model="selectedCategory" />
        </div>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <div v-if="!isLoading" class="row-1-col space-top">
      <ul class="tab-container">
        <li
          v-for="(row, index) in [{ key: '', header: 'Alla' }, ...reportCategories]"
          v-bind:key="index"
          class="tab-item pointer"
          v-bind:class="[row.key === selectedCategory ? 'tab-item-active' : '']"
          @click="selectedCategory = row.key"
        >
          <a :key="index" class="tab-item-link">{{ row.header }}</a>
        </li>
      </ul>
      <div v-if="reportCategories.length === 0">
        Inga rapporter tillgängliga. Kontrollera behörigheter under
        <em>Mitt konto</em>.
      </div>
      <div v-else-if="filteredReportCategories.length === 0">
        Inga rapporter matchar sökningen.
        <template v-if="selectedCategory"> Prova att gå till tabben <em>Alla</em> eller ändra sökningen. </template>
        <template v-else> Prova att ändra sökningen. </template>
      </div>
      <template v-for="reportCategory in filteredReportCategories">
        <div :key="reportCategory.key">
          <h2>{{ reportCategory.header }}</h2>
          <table class="space-bottom">
            <tbody>
              <tr v-for="report in reportCategory.reports" :key="report.view">
                <template v-if="report.view !== ''">
                  <td>
                    <a class="link" v-on:click="goToView(report.view)">
                      {{ report.name }}
                    </a>
                    <br />
                    {{ report.description }}
                    <br />
                    <b>Uppdateras {{ report.syncSchedule }}</b>
                    <template v-if="lastSyncFormat(report.lastSync) !== 'Nu'">
                      <br />
                      <b>Senast uppdaterad:</b>
                      {{ lastSyncFormat(report.lastSync) }}
                    </template>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import { momentHandler } from "@/../../shared";

const HOUR = 3600000;

export default {
  name: "Reporting",
  components: {},
  data() {
    return {
      isLoading: true,
      reportCategories: [],
      selectedCategory: "",
      filters: { search: "" },
    };
  },
  methods: {
    goToView(view) {
      this.$router.push({ name: view });
    },

    // Return a description of when the underlying data for a report was last
    // synchronized. See function calcLastSync in reportingService for more
    // documentation.
    lastSyncFormat(lastSync) {
      if (!lastSync) {
        return "Error";
      }
      if (typeof lastSync === "string") {
        return lastSync;
      }
      const [begin, end] = lastSync;
      if (begin === null && end === null) {
        return "Nu";
      }
      const now = new Date();
      const beginDate = new Date(begin);
      const endDate = new Date(end);
      const beginHdiff = Math.ceil((now - beginDate) / HOUR);
      const endHdiff = Math.floor((now - endDate) / HOUR);
      const sameday = momentHandler.formatDatestring(beginDate, null, "YYYY-MM-DD") === momentHandler.formatDatestring(endDate, null, "YYYY-MM-DD");
      const beginFormatted = momentHandler.formatDatestring(beginDate, null, "YYYY-MM-DD HH:mm");
      const endFormatted = momentHandler.formatDatestring(endDate, null, sameday ? "HH:mm" : "YYYY-MM-DD HH:mm");
      if (end === null) {
        return `Upp till ${beginHdiff} timmar sedan (${beginFormatted} -- Nu)`;
      }
      return `${endHdiff}-${beginHdiff} timmar sedan (${beginFormatted} -- ${endFormatted})`;
    },
  },

  computed: {
    // The data in this.reportCategories filtered using filters.search and tab
    // selection.
    filteredReportCategories() {
      const searchTerms = _.toUpper(this.filters.search).split(" ");
      function reportmatches(report) {
        for (const searchTerm of searchTerms) {
          if (_.includes(_.toUpper(report.name), searchTerm)) {
            continue;
          }
          if (_.includes(_.toUpper(report.description), searchTerm)) {
            continue;
          }
          return false;
        }
        return true;
      }
      const ret = [];
      for (const reportCategory of this.reportCategories) {
        if (this.selectedCategory && this.selectedCategory !== reportCategory.key) {
          continue;
        }
        const retReports = [];
        for (const report of reportCategory.reports) {
          if (reportmatches(report)) {
            retReports.push(report);
          }
        }
        if (retReports.length) {
          ret.push({
            ...reportCategory,
            reports: retReports,
          });
        }
      }
      return ret;
    },
  },

  async mounted() {
    const result = await this.$http.get("/api/reporting/reports");
    this.reportCategories = result.data;
    this.isLoading = false;
  },
};
</script>

<style scoped>
.tab-container {
  border-bottom: 1px solid #ddd;
  padding-right: 0px;
  padding-left: 0px;
}
.tab-item {
  display: inline-block;
  position: relative;
  top: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.tab-item:hover {
  background-color: #f1e6fc;
}

.tab-item-active {
  border: 1px solid #ddd;
  border-bottom: 1px solid #fff;
}

.tab-item-active > .tab-item-link {
  color: #444444 !important;
}

.tab-item-link {
  color: #5d237d;
}
</style>
