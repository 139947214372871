<template>
  <div>
    <div class="container space-top">
      <div class="page-header">
        <h1>MDM-6 Enskild MacOS enhet</h1>
      </div>
    </div>
    <div class="container space-top">
      <div class="row-1-col">
        <div class="row-item">
          <div class="row-flex space-top-sm">
            <RFilter configName="intune_ios_serialnumber" v-model="filters.serialnumber" />
            <RFilter configName="device_cosnumber" v-model="filters.cosnumber" />
          </div>
        </div>
      </div>
      <div class="row-flex">
        <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data </a>
      </div>
      <LoadingSpinner :isLoading="isLoading" />
      <div v-if="showData" class="row-1-col space-top">
        <h2>Data</h2>
        <span v-if="notFound">{{ notFoundMessage }}</span>
        <TableSingleObject :tableData="dataobj" :fields="deviceFields" :csvFilename="csvFilename_single" showMissing />
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as utils from "@/utils/utils.js";

export default {
  name: "Mdm6",
  props: {},
  data() {
    return {
      isLoading: false,
      filters: {
        serialnumber: "",
        cosnumber: "",
      },
      dataobj: null,
      dataobjIsFor: null,
      deviceFields: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaID",
          displayName: "Verksamhetsid",
          show: true,
        },
        {
          property: "model",
          displayName: "Modell",
          show: true,
        },
        {
          property: "OperatingSystemFull",
          displayName: "Operativsystem",
          show: true,
        },
        {
          property: "operating_system_build",
          displayName: "Build", // kompileringsnummer?
          show: true,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
        },
        {
          property: "userPrincipalName",
          displayName: "Användare",
          show: true,
        },
        {
          property: "Enrolled",
          displayName: "Registrerad",
          show: true,
        },
        {
          property: "LastSynchronization",
          displayName: "Senast synkroniserad",
          show: true,
        },
      ],
    };
  },
  methods: {
    async updateData() {
      this.isLoading = true;
      const currentFilters = _.cloneDeep(this.filters);

      const params = {};
      if (currentFilters.serialnumber) {
        params.serialnumber = currentFilters.serialnumber;
      }
      if (currentFilters.cosnumber) {
        params.cosnumber = currentFilters.cosnumber;
      }

      const deviceResponse = await this.$http.get("/api/reporting/mdm/macosDevice", { params });
      this.dataobj = deviceResponse.data;
      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    notFound() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isString(this.dataobj) && this.dataobj === null;
    },
    showData() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    notFoundMessage() {
      return `Ingen användare med ${utils.renderSwedishList([this.filters.uid ? `UID=${this.filters.uid}` : "", this.filters.dn ? `DN=${this.filters.dn}` : ""])} kunde hittas`;
    },
    showLoadButton() {
      return !_.isEqual(this.filters, this.dataobjIsFor);
    },
    csvFilename_single() {
      return `MDM-6 Export - MacOS enhet ${this.dataobj.Serialnumber}`;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
