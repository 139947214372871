"use strict";

// This file defines role-based access to reports and other frontend views.
// These definitions are used by the backend reportingService to send a correct
// list of reports to the frontend, and also by the frontend to determine what
// views should be accessible. Neither is critical to security since access to
// the actual data is determined in the backend by other means.

// For each report or other frontend view, the exported object has
// - a key that corresponds to
//   - the value of view in ../backend/services/reportingService.js (if present)
//   - the value of name in ../frontend/src/modules/reporting/routes/routes*.js
// - a value that is a "role test function". It determines whether a user has
//   access to the view in question by
//   - taking an argument that is an object {role:true} for the roles of the
//     current user
//   - returning truthy if and only if the user should be granted access

// Reusable role test functions:
/* eslint-disable no-unused-vars */
const {
  // Role test functions to give access to
  // - Fujitsu's developers
  // - Fujitsu's testers
  dev,
  dev_roles_general,
  // Role test functions to give access to
  // - CoS testers
  // - Fujitsu's developers
  // - Fujitsu's testers
  test,
  test_roles_general,
  // Role test functions for access by all eligible users and no others
  allowall,
  roles_general,
} = require("./roleTestFunctions.js");
/* eslint-enable no-unused-vars */

module.exports = Object.freeze({
  // Listed in alphabetic order

  // Used only for frontend routes:
  AuthorizationRules: allowall,
  MyAccount: allowall,
  ReleaseNotes: allowall,
  Reporting: allowall,
  Unauthorized: allowall,

  // Used both for frontend routes and backend reportingService:
  Ad1: roles_general,
  Ad1_1: roles_general,
  Ad1_2: roles_general,
  Ad1_3: roles_general,
  Ad1_4: roles_general,
  Ad2: roles_general,
  Ad2_1: roles_general,
  Ad2_2: roles_general,
  Ad2_3: roles_general,
  Ad2_4: roles_general,
  Ad2_5: roles_general,
  Ad2_6: roles_general,
  Ad2_7: roles_general,
  Ad2_8: roles_general,
  Ad2_9: roles_general,
  Ad2_10: roles_general,
  Ad2_11: roles_general,
  Ad2_12: roles_general,
  Ad2_13: roles_general,
  Ad2_14: roles_general,
  Ad3: roles_general,
  Ad4: roles_general,
  Ad4_1: roles_general,
  Ad4_2: roles_general,
  Ad4_3: roles_general,
  Ad4_4: roles_general,
  Ad4_5: roles_general,
  Ad4_6: roles_general,
  Ad5: roles_general,
  Ad6: roles_general,
  Ad6_1: roles_general,
  Ad6_2: roles_general,
  Ad7: roles_general,
  Ad8: roles_general,
  Ad8_1: roles_general,
  Ad9: roles_general,
  Cr1: roles_general,
  "Cr2-1": roles_general,
  "Cr2-2": roles_general,
  Cr3: roles_general,
  Cr4: roles_general,
  Cr5: roles_general,
  Cr6: roles_general,
  Lev2: roles_general,
  Lev3: roles_general,
  Lev4: dev_roles_general,
  Lev6: roles_general,
  Sccm1: roles_general,
  Sccm2: roles_general,
  Sccm3: roles_general,
  Sccm4: roles_general,
  Dmnx1: roles_general,
  Dmnx2: roles_general,
  Dmnx3: roles_general,
  Mdm1: roles_general,
  Mdm2: roles_general,
  Mdm3: roles_general,
  Mdm4: roles_general,
  Mdm5: roles_general,
  Mdm6: roles_general,
  Mdm7: roles_general,
});
