<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter name="GruppdiskID (CN)" component="TextFilter" width="230" qpsEncodingMethod="Straight" qpsParamName="cn" :pattern="/^[0-9]{5}$/" v-model="filters.cn" />
          <RFilter name="Beskrivning" component="TextFilter" width="350" qpsEncodingMethod="Straight" qpsParamName="desc" :pattern="/^.{1,1000}$/" v-model="filters.description" />
        </div>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <span v-if="notSingle">{{ notSingleMessage }}</span>
    <div v-if="showData" class="row-1-col space-top">
      <h2>Data</h2>
      <TableSingleObject :tableData="dataobj" :fields="volumeFields" :csvFilename="csvFilename_single" showMissing />
    </div>
    <div v-if="showData" class="row-1-col space-top">
      <h3>Användare & Behörigheter</h3>
      <TablePaginated :tableData="dataobj.volumeusers" :columns="volumeuserColumns" :disableFiltering="false" :csvFilename="csvFilename_table" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as adconf from "./AdViewConfig.js";
import * as adutils from "./AdUtils.js";

const variation = rpt => ({
  title: { Ad5: "AD-5 Enskild gruppdisk" }[rpt],
  csvprefix_single: { Ad5: "AD-5 Export Gruppdisk " }[rpt],
  csvprefix_table: { Ad5: "AD-5 Export Användare och behörigheter för gruppdisk " }[rpt],
  filters: {},
  noneMessage: { Ad5: "Ingen sådan gruppdisk kunde hittas." }[rpt],
  tooManyMessage: { Ad5: "Mer än en sådan gruppdisk hittades. Gör sökningen mer specifik." }[rpt],

  volumeFields: adconf.showFields(adconf.volumeViewConfig, rpt, {
    cn: "y",
    distinguishedName: "y",
    description: "y",
    uNCName: "y",
    keywords: "y",
    whenChanged: "y",
    whenCreated: "y",
    managedByUid: "y",
    volumegroupSamR: "y",
    volumegroupSamRW: "y",
    volumegroupSamFULL: "y",
  }),

  volumeuserColumns: adconf.showColumns(adconf.volumeuserViewConfig, rpt, {
    permissions: "y",
    sn: "y",
    givenName: "y",
    displayName: "h",
    description: "h",
    employeeType: "y",
    homeDirectory: "h",
    homeDrive: "h",
    mobile: "h",
    lastLogonTimestamp: "h",
    logonCount: "h",
    whenChanged: "h",
    whenCreated: "h",
    sthlmIDMExtended7: "h",
    ManagerCN: "h",
    sAMAccountName: "h",
    cn: "h",
    uid: "y",
    uidNumber: "h",
    userPrincipalName: "y",
    distinguishedName: "h",
    SI: "y",
    sthlmPersonnummer: "y",
    sthlmRPNR: "y",
    sthlmBefattningsKod: "h",
    sthlmKontoTyp: "y",
    company: "y",
    sthlmForvaltningsNr: "h",
    department: "y",
    sthlmVerksamhetsId: "h",
    sthlmFakturaRef: "y",
    sthlmSkolID: "y",
    telephoneNumber: "h",
    sthlmKlass: "h",
    _sisSchoolGradeComputed: "h",
  }),
});

export default {
  name: "Ad5",
  data() {
    return _.defaultsDeep(variation(this.$route.name), {
      isLoading: false,
      filters: {
        cn: "",
        description: "",
      },
      dataobj: null,
      dataobjIsFor: null,
    });
  },
  watch: {
    async "filters.cn"() {
      await this.maybeUpdateData();
    },
    async "filters.description"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      const currentFilters = _.cloneDeep(this.filters);
      const reqParams = {};
      let dosearch = false;
      if (currentFilters.cn) {
        reqParams.cns = [currentFilters.cn];
        dosearch = true;
      }
      if (currentFilters.description) {
        reqParams.description = currentFilters.description;
        dosearch = true;
      }
      // If filters are cleared then show nothing
      if (!dosearch) {
        this.dataobj = null;
        this.dataobjIsFor = null;
        this.isLoading = false;
        return;
      }
      // Otherwise, fetch volume object
      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/ad/volumes", { params: { ...reqParams, single: true, volumeusers: true } });
      // We now have the data available. However, it's possible that the filters
      // have already changed and that a new search is underway. In order to not
      // interfere with that, only affect the changes if the filters still
      // match.
      if (_.isEqual(currentFilters, this.filters)) {
        this.dataobj = adutils.single(response.data);
        this.dataobjIsFor = currentFilters;
        this.isLoading = false;
      }
    },
  },
  computed: {
    notSingle() {
      return _.isEqual(this.filters, this.dataobjIsFor) && (this.dataobj === "none" || this.dataobj === "too many");
    },
    showData() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    notSingleMessage() {
      if (this.dataobj === "none") {
        return this.noneMessage;
      }
      if (this.dataobj === "too many") {
        return this.tooManyMessage;
      }
      return "";
    },
    csvFilename_single() {
      return this.csvprefix_single + this.dataobj.cn;
    },
    csvFilename_table() {
      return this.csvprefix_table + this.dataobj.cn;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
