/* global process */
import * as assert from "assert";
import * as jwt from "jsonwebtoken";
import Vue from "vue";
import Vuex from "vuex";
import { meta } from "../../shared";

const undef = {}.undef;

Vue.use(Vuex);

function stateToLocalStorage(state) {
  if (state.token) {
    localStorage.setItem("token", state.token);
  } else {
    localStorage.removeItem("token");
  }
  if (state.tokenforme) {
    localStorage.setItem("tokenforme", state.tokenforme);
  } else {
    localStorage.removeItem("tokenforme");
  }
}

export default new Vuex.Store({
  state: {
    // Localstorage defined in browser
    token: localStorage.getItem("token") || "",
    tokenforme: localStorage.getItem("tokenforme") || "",
    // packageVersion: process.env.PACKAGE_VERSION || "0",
    appVersion: meta.version,
  },
  mutations: {
    authenticateUser(state, payload) {
      state.token = payload;
      state.tokenforme = "";
      stateToLocalStorage(state);
    },
    logoutUser(state) {
      state.token = "";
      state.tokenforme = "";
      stateToLocalStorage(state);
      window.location.href = process.env.VUE_APP_IDPORTAL_SIGNOFF_URI;
    },
    impersonateUser(state, payload) {
      assert(state.tokenforme === "");
      assert(state.token);
      const impersonatedBy = jwt.decode(state.token).realSamAccountName;
      assert(impersonatedBy === undef);
      state.tokenforme = state.token;
      state.token = payload;
      stateToLocalStorage(state);
    },
    endImpersonation(state) {
      assert(state.tokenforme);
      state.token = state.tokenforme;
      state.tokenforme = "";
      stateToLocalStorage(state);
    },
  },
  actions: {
    authenticateUser(context, payload) {
      context.commit("authenticateUser", payload);
    },
    logoutUser(context) {
      context.commit("logoutUser");
    },
    impersonateUser(context, payload) {
      context.commit("impersonateUser", payload);
    },
    endImpersonation(context) {
      context.commit("endImpersonation");
    },
  },
  getters: {
    authenticated: state => state.token !== "",
    impersonated: state => Boolean(jwt.decode(state.token).realSamAccountName),
    authenticatedUserSamAccountName: state => jwt.decode(state.token).samAccountName,
    authenticatedUserImpersonatedBy: state => jwt.decode(state.token).realSamAccountName,
    authenticatedUserAuthorizations: state => jwt.decode(state.token).authorizations,
    authenticationToken: state => state.token,
    appVersion: state => state.appVersion,
  },
});
