import { render, staticRenderFns } from "./ValueBox.vue?vue&type=template&id=72996600&scoped=true&"
import script from "./ValueBox.vue?vue&type=script&lang=js&"
export * from "./ValueBox.vue?vue&type=script&lang=js&"
import style0 from "./ValueBox.vue?vue&type=style&index=0&id=72996600&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72996600",
  null
  
)

export default component.exports