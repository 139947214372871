<template>
  <div class="container space-top">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>CR-3 Enskild användare</h1>
        </div>
        <div class="row-flex space-top-sm">
          <RFilter configName="gsuite_user_email" v-model="filters.email" />
          <RFilter configName="user_uid" v-model="filters.uid" />
        </div>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading" />

    <div v-if="showData">
      <span v-if="notFound">{{ notFoundMessage }}</span>

      <div v-if="showObject" class="row-1-col space-top">
        <h2>Användare</h2>
        <TableSingleObject :tableData="dataobj" :fields="userFields" :disableFiltering="true" :csvFilename="csvFilename_single" showMissing />
      </div>
      <div v-if="showObject" class="row-1-col space-top">
        <h2>Tokens</h2>
        <TablePaginated :tableData="tokens" :columns="tokenColumns" :disableFiltering="false" :csvFilename="csvFilename_table_tokens" paramPrefix="tblt" />
      </div>
      <div v-if="showObject" class="row-1-col space-top">
        <h2>ChromeOS Enheter</h2>
        <TablePaginated :tableData="devices" :columns="deviceColumns" :disableFiltering="false" :csvFilename="csvFilename_table_chromeos" paramPrefix="tbld" />
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";

function routeToCr5(arg) {
  arg.root.$router.push({
    path: "/cr5",
    query: { oupath: arg.object.OUPath },
  });
}
function routeToCr3byEmail(arg) {
  arg.root.$router.push({
    path: "/cr3",
    query: { email: arg.clickedValue },
  });
}

function routeToCr1(arg) {
  arg.root.$router.push({
    path: "/cr1",
    query: { sn: arg.object.Serialnumber, cos: arg.object.CosNumber },
  });
}

export default {
  name: "Cr3",
  props: {},
  data() {
    return {
      isLoading: false,
      filters: {
        email: "",
        uid: null,
      },
      dataobj: null,
      dataobjIsFor: null,
      tokens: [],
      devices: [],

      tokenColumns: [
        {
          property: "displayText",
          displayName: "Applikationens namn",
          show: true,
        },
        {
          property: "anonymous",
          displayName: "Ej registrerad hos Google",
          show: true,
        },
        {
          property: "nativeApp",
          displayName: "Native app",
          show: true,
        },
        {
          property: "scopes",
          displayName: "Omfattning",
          show: true,
        },
      ],
      deviceColumns: [
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
          onclick: routeToCr1,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
          onclick: routeToCr1,
        },
        {
          property: "Model",
          displayName: "Modell",
          show: true,
        },
        {
          property: "Status",
          displayName: "Status",
          show: true,
        },
      ],
      userFields: [
        {
          property: "UId",
          displayName: "UID",
          show: true,
        },
        {
          property: "GivenName",
          displayName: "Förnamn",
          show: true,
        },
        {
          property: "FamilyName",
          displayName: "Efternamn",
          show: true,
        },
        {
          property: "FullName",
          displayName: "Visningsnamn",
          show: true,
        },
        // {
        //   property: "AccountType",
        //   displayName: "Kontotyp",
        //   show: true,
        // },
        {
          property: "LastLoginTime",
          displayName: "Senaste inloggning",
          show: true,
        },
        {
          property: "CreationTime",
          displayName: "Konto skapat",
          show: true,
        },
        {
          property: "PrimaryEmailAddress",
          displayName: "Primär Epostadress",
          show: true,
        },
        {
          property: "EmailAddresses",
          displayName: "Epostadresser",
          show: true,
        },
        {
          property: "IsAdmin",
          displayName: "Admin",
          show: true,
        },
        {
          property: "IsDelegatedAdmin",
          displayName: "Delegerad admin",
          show: true,
        },
        {
          property: "AgreedToTerms",
          displayName: "Accepterat användarvillkoren",
          show: true,
        },
        {
          property: "Suspended",
          displayName: "Konto avstängt",
          show: true,
        },
        {
          property: "Archived",
          displayName: "Konto arkiverat",
          show: true,
        },
        {
          property: "ChangePasswordAtNextLogin",
          displayName: "Lösenordsbyte krävs vid nästa inloggning",
          show: true,
        },
        {
          property: "ManagerPrimaryEmailAddress",
          displayName: "Chef",
          show: true,
          onclick: routeToCr3byEmail,
        },
        {
          property: "IsProtectedIdentity",
          displayName: "Skyddad identitet",
          show: true,
        },
        {
          property: "IsEnrolledIn2Sv",
          displayName: "Tvåfaktorsautentisering aktiverad",
          show: true,
        },
        {
          property: "IsEnforcedIn2Sv",
          displayName: "Tvåfaktorsautentisering krävs",
          show: true,
        },
        {
          property: "OUName",
          displayName: "OU-grupp namn",
          show: true,
          onclick: routeToCr5,
        },
        {
          property: "OUDescription",
          displayName: "OU-grupp beskrivning",
          show: true,
        },
        {
          property: "OUPath",
          displayName: "OU-grupp sökväg",
          show: true,
          onclick: routeToCr5,
        },
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "Verksamhetsid",
          show: true,
        },
        {
          property: "Title",
          displayName: "Titel",
          show: true,
        },
      ],
    };
  },
  watch: {
    async "filters.email"() {
      await this.updateData();
    },
    async "filters.uid"() {
      await this.updateData();
    },
  },
  methods: {
    async updateData() {
      this.isLoading = true;
      const params = {};
      const currentFilters = _.cloneDeep(this.filters);
      if (currentFilters.email) {
        params.email = currentFilters.email;
      }
      if (currentFilters.uid) {
        params.uid = currentFilters.uid;
      }

      const response = await this.$http.get("/api/reporting/gsuite/user", { params });

      this.dataobj = response.data;
      this.tokens = response.data.Tokens || [];
      this.devices = response.data.Devices || [];

      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    notFound() {
      return this.dataobj === "none";
    },
    showObject() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isObject(this.dataobj);
    },
    showData() {
      return !this.isLoading && _.size(_.pickBy(this.filters)) > 0;
    },
    notFoundMessage() {
      return "Ingen användare kunde hittas";
    },
    csvFilename_single() {
      return `CR-3 Export - Enskild GSuite användare ${this.dataobj.UId}`;
    },
    csvFilename_table_tokens() {
      return `CR-3 Export - Tokens för enskild GSuite användare ${this.dataobj.UId}`;
    },
    csvFilename_table_chromeos() {
      return `CR-3 Export - ChromeOS enheter för enskild GSuite användare ${this.dataobj.UId}`;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
