<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>CR-6 Förteckning över OU-grupper</h1>
            </div>
            <!-- <div class="row-flex space-top-sm">
              <RFilter configName="veda" v-model="filters.veda.selected" />
            </div>-->
          </div>
        </div>

        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="reportData" :columns="columns" :disableFiltering="false" csvFilename="CR-6 Export - Förteckning över OU-grupper" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
// todo better formatting rule, probably use prettier

function routeToCr5(arg) {
  arg.root.$router.push({
    path: "/cr5",
    query: { oupath: arg.object.OUPath },
  });
}

export default {
  name: "Cr6",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      columns: [
        {
          property: "VedaName",
          displayName: "Verksamhetsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "VerksamhetsId",
          show: false,
        },
        {
          property: "OUPath",
          displayName: "Sökväg",
          show: true,
          onclick: routeToCr5,
        },
        {
          property: "Name",
          displayName: "Namn",
          show: true,
          onclick: routeToCr5,
        },
        {
          property: "Description",
          displayName: "Beskrivning",
          show: true,
        },
      ],
      filters: {
        // veda: {
        //   selected: [],
        // },
      },
    };
  },
  watch: {
    // "filters.veda.selected"() {
    //   this.crossfilter.filterDimensionArrayContains(
    //     "VedaDimension",
    //     this.filters.veda.selected,
    //   );
    //   this.calculateReportComponentsData();
    // },
  },
  methods: {
    // initCrossfilter() {
    //   this.crossfilter = new CrossfilterHandler(this.reportData);
    //   this.crossfilter.createDimensionByProperty("VedaDimension", "VedaId");
    // },
    // calculateReportComponentsData() {
    //   this.reportData = this.crossfilter.allFiltered();
    // },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/gsuite/ous")]).then(
      this.$http.spread(gsuiteOUsResponse => {
        this.reportData = gsuiteOUsResponse.data;

        // this.initCrossfilter();
        // this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
