<template>
  <div>
    <div class="container space-top">
      <div class="page-header">
        <h1>MDM-3 Enskild ASM token</h1>
      </div>
    </div>
    <div class="container space-top">
      <div class="row-1-col">
        <div class="row-item">
          <div class="row-flex space-top-sm">
            <RFilter configName="intune_asm_tokenid" v-model="filters.tokenid" />
          </div>
        </div>
      </div>
      <LoadingSpinner :isLoading="isLoading" />
      <div v-if="showData" class="row-1-col space-top">
        <h2>Data</h2>
        <span v-if="notFound">{{ notFoundMessage }}</span>
        <TableSingleObject :tableData="dataobj" :fields="deviceFields" :csvFilename="csvFilename_single" showMissing />
      </div>
      <div v-if="showData" class="row-1-col space-top">
        <h2>Inköpta applikationer</h2>
        <TablePaginated :tableData="applicationsDataObj" :columns="applicationsFields" :disableFiltering="false" :csvFilename="csvFilename_table" />
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as utils from "@/utils/utils.js";

export default {
  name: "Mdm3",
  props: {},
  data() {
    return {
      isLoading: false,
      filters: { tokenid: "" },
      dataobj: null,
      dataobjIsFor: null,
      applicationsDataObj: null,
      deviceFields: [
        {
          property: "TokenId",
          displayName: "Id",
          show: true,
        },
        {
          property: "Name",
          displayName: "Namn",
          show: true,
        },
        {
          property: "Location",
          displayName: "Lokation",
          show: true,
        },
        {
          property: "LastSynchronizationDatetime",
          displayName: "Senast synkroniserad",
          show: true,
        },
        {
          property: "Expiration",
          displayName: "Giltig till",
          show: true,
        },
      ],
      applicationsFields: [
        {
          property: "Name",
          displayName: "Namn",
          show: true,
        },
        {
          property: "Version",
          displayName: "Version",
          show: true,
        },
        {
          property: "MinimumOsVersion",
          displayName: "Kräver",
          show: true,
        },
        {
          property: "Price",
          displayName: "Pris",
          show: true,
        },
        {
          property: "TotalLicenses",
          displayName: "Licenser",
          show: true,
        },
        {
          property: "UsedLicenses",
          displayName: "Använda licenser",
          show: true,
        },
        {
          property: "FreeLicenses",
          displayName: "Tillgängliga licenser",
          show: true,
        },
        {
          property: "RemovedFromAppStore",
          displayName: "Borttagen från App Store",
          show: true,
        },
      ],
    };
  },
  watch: {
    async "filters.tokenid"() {
      await this.updateData();
    },
  },
  methods: {
    async updateData() {
      const currentFilters = _.cloneDeep(this.filters);
      this.isLoading = true;
      const deviceResponse = await this.$http.get("/api/reporting/mdm/asmToken", { params: { tokenid: currentFilters.tokenid } });
      const applicationsResponse = await this.$http.get("/api/reporting/mdm/tokenAsmApplications", { params: { tokenid: currentFilters.tokenid } });
      // Make it possible to filter on boolean values
      this.dataobj = deviceResponse.data;
      this.applicationsDataObj = applicationsResponse.data;

      this.dataobjIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    notFound() {
      return _.isEqual(this.filters, this.dataobjIsFor) && _.isString(this.dataobj) && this.dataobj === null;
    },
    showData() {
      return !this.isLoading && _.size(_.pickBy(this.filters)) > 0;
    },
    notFoundMessage() {
      return `Ingen användare med ${utils.renderSwedishList([this.filters.uid ? `UID=${this.filters.uid}` : "", this.filters.dn ? `DN=${this.filters.dn}` : ""])} kunde hittas`;
    },
    csvFilename_single() {
      return `MDM-3 Export - ASM token ${this.dataobj.TokenId}`;
    },
    csvFilename_table() {
      return `MDM-3 Export - Inköpta applikationer för ASM token ${this.dataobj.TokenId}`;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
