<template>
  <div class="container space-top space-bottom">
    <div class="row-1-col">
      <div class="row-item">
        <div class="page-header">
          <h1>Versionsinformation</h1>
        </div>
      </div>
    </div>
    <template v-for="(release, index) in releaseNotes.releases">
      <div v-bind:key="'rn' + index">
        <h2>{{ release.version }}</h2>
        <i>{{ release.date }}</i>
        <ul>
          <li v-for="(note, index2) in release.notes" v-bind:key="'n' + index2">
            {{ note }}
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>

import releaseNotes from "../../releasenotes.js";

export default {
  name: "ReleaseNotes",
  components: {},
  data() {
    return { releaseNotes };
  },
};
</script>
