<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>SCCM-1 Applikationer</h1>
            </div>
          </div>
        </div>
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="reportData" :columns="columns" :disableFiltering="false" csvFilename="SCCM-1 Applikationer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function routeToSccm2(arg) {
  arg.root.$router.push({
    path: "/sccm2",
    query: { appnameversion: arg.clickedValue },
  });
}

function routeToSccm2Manufacturer(arg) {
  arg.root.$router.push({
    path: "/sccm2",
    query: { appmanufacturer: arg.clickedValue },
  });
}

export default {
  name: "Sccm1",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      chartData: null,
      latestOsVersion: null,
      columns: [
        {
          property: "ProgramManufacturer",
          displayName: "Tillverkare",
          show: true,
          onclick: routeToSccm2Manufacturer,
        },
        {
          property: "ProgramAndVersion",
          displayName: "Applikation och version",
          show: true,
          onclick: routeToSccm2,
        },
      ],
      filters: {},
    };
  },
  watch: {},
  methods: {},
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/sccm/applications")]).then(
      this.$http.spread(applicationsResponse => {
        this.reportData = applicationsResponse.data;

        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
