"use strict";

// The array of existing roles.
// A role is {code, type, title}, where
// - code is a short uppercase abbreviation reporesenting the role.
// - type is one of
//   - "Central":         The role applies to all data of the type the role
//                        gives access to.
//   - "Lokal":           The role applies to data belonging to a certain Veda
//                        ID.
//   - "Fakturareferens": The role applies to data related to a certain invoice
//                        reference, and in some cases to data belonging to the
//                        Veda ID that owns the specified invoice reference.
//   - "Ingen":           The role exists but will not be used in the reporting
//                        application. Present in case that changes.
// - title is the full name of the role.

const futile = require("@fujitsusweden/futile");

exports.roles_array = futile.deepFreeze([
  // Added as a mocked role for dev only.
  {
    code: "FJDEV",
    type: "Central",
    title: "Fujitsu testanvändare i utvecklingsmiljö",
  },

  // Added as a mocked role for CoS test users in production.
  {
    code: "COSTEST",
    type: "Central",
    title: "CoS testanvändare med produktionsdata",
  },

  // Added as a mocked role for dev, and by some AD group.
  {
    code: "IMPERSONATE",
    type: "Central",
    title: "Felsökare med rättighet att se rapporter från annan användares perspektiv",
  },

  // CoS roles
  { code: "BA", type: "Lokal", title: "Behörighetsadministratör" },
  { code: "BB", type: "Fakturareferens", title: "Behörig beställare" },
  { code: "BG1", type: "Fakturareferens", title: "Behörig godkännare Nivå 1" },
  { code: "BG2", type: "Fakturareferens", title: "Behörig godkännare Nivå 2" },
  { code: "BG3", type: "Fakturareferens", title: "Behörig godkännare Nivå 3" },
  { code: "BG4", type: "Fakturareferens", title: "Behörig godkännare Nivå 4" },
  { code: "BG5", type: "Fakturareferens", title: "Behörig godkännare Nivå 5" },
  { code: "BG6", type: "Fakturareferens", title: "Behörig godkännare Nivå 6" },
  { code: "BG7", type: "Fakturareferens", title: "Behörig godkännare Nivå 7" },
  { code: "CA", type: "Central", title: "Central applikationsförvaltare" },
  { code: "CAS", type: "Central", title: "Central administratör staden" },
  { code: "CIS", type: "Central", title: "Centralt IT-stöd" },
  { code: "CUS", type: "Central", title: "Central uppföljning" },
  { code: "ITKP", type: "Lokal", title: "IT-Kontaktperson" },
  { code: "ITVA", type: "Lokal", title: "IT-Verksamhetsansvarig" },
  { code: "LIS", type: "Lokal", title: "Lokalt IT-stöd" },
  { code: "LU", type: "Lokal", title: "Lokal uppföljning" },
  { code: "MDM", type: "Lokal", title: "MDM-Administratör" },
]);

exports.roles_dict = futile.deepFreeze(futile.indexBy(exports.roles_array, "code"));

exports.roles_general = futile.deepFreeze(["BA", "BB", "BG1", "BG2", "BG3", "BG4", "BG5", "BG6", "BG7", "ITKP", "ITVA", "MDM", "LIS", "LU", "CAS", "CIS", "CUS", "CA"]); // These roles are eligable to fetch resources
