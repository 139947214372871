/* global process */
import axios from "axios";

export function redirectToAuthorize() {
  /* eslint-disable-next-line no-magic-numbers */
  const nonce = Math.floor(Math.random() * 1000000000);
  const url = new URL(process.env.VUE_APP_IDPORTAL_OIDC_ENDPOINT_AUTHORIZE);
  url.searchParams.set("client_id", "rapportedu");
  url.searchParams.set("redirect_uri", process.env.VUE_APP_IDPORTAL_OIDC_RETURN_URI);
  url.searchParams.set("response_type", "id_token token");
  url.searchParams.set("scope", "openid");
  url.searchParams.set("nonce", nonce);
  // searchParams encodes space with + instead of %20, id-portal requires %20 encoding, howerver + seems to work on the 2nd try
  window.location = url.href.replace("id_token+token", "id_token%20token");
}

export async function handleAuthenticateCallback(fullPath) {
  const urlparser = new URL(fullPath, "http://dummydomainforparsing.local");
  urlparser.search = urlparser.hash.slice(1);
  const accessToken = urlparser.searchParams.get("access_token");
  const authenticateResult = await axios.get("/api/authentication/authenticate", { params: { accessToken } });
  const token = authenticateResult.data;
  return token;
}
