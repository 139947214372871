import * as _ from "lodash";

// onclick functions. See TableSingleObject.vue for documentation. They work
// both in TableSingleObject and TablePaginated.

function routeToUserWithUID(arg) {
  arg.root.$router.push({
    path: "/ad1",
    query: { uid: arg.clickedValue },
  });
}

function routeToUserWithDN(arg) {
  arg.root.$router.push({
    path: "/ad1",
    query: { dn: arg.object.distinguishedName },
  });
}

function routeToUserByDN(arg) {
  arg.root.$router.push({
    path: "/ad1",
    query: { dn: arg.object.distinguishedName },
  });
}

function routeToUserByManager(arg) {
  arg.root.$router.push({
    path: "/ad1",
    query: { dn: arg.object.manager },
  });
}

function routeToUserByPrimaryUserDN(arg) {
  arg.root.$router.push({
    path: "/ad1",
    query: { dn: arg.object.PrimaryUserDistinguishedName },
  });
}

function routeToGroupWithSAM(arg) {
  arg.root.$router.push({
    path: "/ad3",
    query: { sam: arg.clickedValue },
  });
}

function routeToGroupWithDN(arg) {
  arg.root.$router.push({
    path: "/ad3",
    query: { dn: arg.clickedValue },
  });
}

function routeToVolumeWithCN(arg) {
  arg.root.$router.push({
    path: "/ad5",
    query: { cn: arg.clickedValue },
  });
}

function routeToDevicesForGroupWithSAM(arg) {
  arg.root.$router.push({
    path: "/ad8",
    query: { group_sam: arg.clickedValue },
  });
}

function routeToDevicesForGroupWithDN(arg) {
  arg.root.$router.push({
    path: "/ad8",
    query: { group_dn: arg.clickedValue },
  });
}

function routeToDeviceWithCN(arg) {
  arg.root.$router.push({
    path: "/ad7",
    query: { cn: arg.clickedValue },
  });
}

function routeToDeviceWithCOS(arg) {
  arg.root.$router.push({
    path: "/ad7",
    query: { cos: arg.clickedValue },
  });
}

// Configuration for user fields/columns. Used for:
// - User detail in AD-1
// - User listing in AD-2
// - User listing in AD-3

export const userViewConfig = [
  {
    property: "sn",
    displayName: "Efternamn",
  },
  {
    property: "givenName",
    displayName: "Förnamn",
  },
  {
    property: "displayName",
    displayName: "Visningsnamn",
  },
  {
    property: "description",
    displayName: "Beskrivning",
  },
  {
    property: "employeeType",
    displayName: "Kontokategori",
  },
  {
    property: "homeDirectory",
    displayName: "Hemkatalog",
  },
  {
    property: "homeDrive",
    displayName: "Enhetsbokstav för hemkatalog",
  },
  {
    property: "telephoneNumber",
    displayName: "Telefonnummer",
  },
  {
    property: "mobile",
    displayName: "Mobilnummer",
  },
  {
    property: "lastLogonTimestamp",
    displayName: "Senaste inloggning",
  },
  {
    property: "logonCount",
    displayName: "Antal inloggningar",
  },
  {
    property: "whenChanged",
    displayName: "Kontot ändrat",
  },
  {
    property: "whenCreated",
    displayName: "Kontot skapat",
  },
  {
    property: "sthlmIDMExtended7",
    displayName: "Kontot upphör",
  },
  {
    property: "ManagerCN",
    displayName: "Chef/Resursägare",
    onclick: routeToUserByManager,
  },
  {
    property: "sAMAccountName",
    displayName: "AnvändarID (SAM)",
  },
  {
    property: "cn",
    displayName: "AnvändarID (CN)",
    onclick: routeToUserByDN,
  },
  {
    property: "uid",
    displayName: "AnvändarID (UID)",
    onclick: routeToUserWithUID,
  },
  {
    property: "uidNumber",
    displayName: "AnvändarID (UID#)",
  },
  {
    property: "userPrincipalName",
    displayName: "AnvändarID (UPN)",
  },
  {
    property: "distinguishedName",
    displayName: "AnvändarID (DN)",
    onclick: routeToUserWithDN,
  },
  {
    property: "SI",
    displayName: "Skyddad identitet",
  },
  {
    property: "sthlmPersonnummer",
    displayName: "Personnummer",
  },
  {
    property: "sthlmRPNR",
    displayName: "Personnummer (SPV)",
  },
  {
    property: "sthlmBefattningsKod",
    displayName: "Befattningskod",
  },
  {
    property: "sthlmKontoTyp",
    displayName: "Kontotyp",
  },
  {
    property: "company",
    displayName: "Förvaltning",
  },
  {
    property: "sthlmForvaltningsNr",
    displayName: "Förvaltningsnummer",
  },
  {
    property: "department",
    displayName: "Verksamhet",
  },
  {
    property: "sthlmVerksamhetsId",
    displayName: "Verksamhets-ID",
  },
  {
    property: "sthlmFakturaRef",
    displayName: "Fakturareferens",
  },
  {
    property: "sthlmSkolID",
    displayName: "SkolID",
  },
  {
    property: "sthlmKlass",
    displayName: "Klass",
  },
  {
    property: "_sisSchoolGradeComputed",
    displayName: "Årskurs",
  },

  // Don't show memberOf at all since that's what tables are for.
  // {
  //   property: "memberOf",
  //   displayName: "Medlem i",
  //   onclick: routeToGroupWithDN,
  // },

  // We don't have good displayNames for the following:
  // { property: "mail", displayName: "mail" },
  // { property: "mailNickname", displayName: "mailNickname" },
  // { property: "name", displayName: "name" },
  // { property: "objectGUID", displayName: "objectGUID" },
  // { property: "objectSid", displayName: "objectSid" },
  // { property: "sAMAccountType", displayName: "sAMAccountType" },
  // { property: "title", displayName: "title" },
  // { property: "uSNChanged", displayName: "uSNChanged" },
  // { property: "uSNCreated", displayName: "uSNCreated" },
];

// Configuration for group fields/columns. Used for:
// - Group listing in AD-1
// - Group detail in AD-3

export const groupViewConfig = [
  {
    property: "displayName",
    displayName: "Visningsnamn",
  },
  {
    property: "description",
    displayName: "Beskrivning",
  },
  {
    property: "sn",
    displayName: "GruppID (SN)",
  },
  {
    property: "sAMAccountName",
    displayName: "GruppID (SAM)",
    onclick: routeToGroupWithSAM,
  },
  {
    property: "cn",
    displayName: "GruppID (CN)",
  },
  {
    property: "distinguishedName",
    displayName: "GruppID (DN)",
    onclick: routeToGroupWithDN,
  },
  {
    property: "whenChanged",
    displayName: "Gruppen ändrad",
  },
  {
    property: "whenCreated",
    displayName: "Gruppen skapad",
  },
  {
    property: "managedBy",
    displayName: "Administreras av",
    onclick: routeToUserWithDN,
  },

  // Don't show member at all since that's what tables are for.
  // {
  //   property: "member",
  //   displayName: "Gruppmedlemmar",
  //   onclick: routeToUserWithDN,
  // },

  // Todo: Proper display name for the following properties:
  // { property: "name", displayName: "name" },
  // { property: "objectGUID", displayName: "objectGUID" },
  // { property: "objectSid", displayName: "objectSid" },
  // { property: "uSNChanged", displayName: "uSNChanged" },
  // { property: "uSNCreated", displayName: "uSNCreated" },
];

export const volumeViewConfig = [
  {
    property: "cn",
    displayName: "GruppdiskID (CN)",
    onclick: routeToVolumeWithCN,
  },
  {
    property: "distinguishedName",
    displayName: "GruppdiskID (DN)",
  },
  {
    property: "description",
    displayName: "Beskrivning",
  },
  {
    property: "uNCName",
    displayName: "Nätverkssökväg",
  },
  {
    property: "keywords",
    displayName: "Sökord",
  },
  {
    property: "whenChanged",
    displayName: "Gruppdisken ändrad",
  },
  {
    property: "whenCreated",
    displayName: "Gruppdisken skapad",
  },
  {
    property: "managedByUid",
    displayName: "Resursägare",
    onclick: routeToUserWithUID,
  },
  {
    property: "volumegroupSamR",
    displayName: "Grupp för läs-behörighet",
    onclick: routeToGroupWithSAM,
  },
  {
    property: "volumegroupSamRW",
    displayName: "Grupp för skriv-behörighet",
    onclick: routeToGroupWithSAM,
  },
  {
    property: "volumegroupSamFULL",
    displayName: "Grupp för full behörighet",
    onclick: routeToGroupWithSAM,
  },

  // We don't have good displayNames for the following:
  // { property: "name", displayName: "" },
  // { property: "sn", displayName: "" },
  // { property: "uSNChanged", displayName: "uSNChanged" },
  // { property: "uSNCreated", displayName: "uSNCreated" },
];

export const volumeuserViewConfig = [
  {
    property: "permissions",
    displayName: "Behörighet",
  },
  ...userViewConfig,
];

export const deviceViewConfig = [
  {
    property: "CosNumber",
    displayName: "COS-nummer",
    onclick: routeToDeviceWithCOS,
  },
  {
    property: "cn",
    displayName: "DatorID (CN)",
    onclick: routeToDeviceWithCN,
  },
  {
    property: "distinguishedName",
    displayName: "DatorID (DN)",
  },
  {
    property: "whenChanged",
    displayName: "Datorobjekt ändrat",
  },
  {
    property: "whenCreated",
    displayName: "Datorobjekt skapat",
  },
  {
    property: "dNSHostName",
    displayName: "Värdnamn (DNS)",
  },
  {
    property: "lastLogonTimestamp",
    displayName: "Senaste inloggning",
  },
  {
    property: "logonCount",
    displayName: "Antal inloggningar",
  },
  {
    property: "operatingSystem",
    displayName: "Operativsystem",
  },
  {
    property: "operatingSystemVersion",
    displayName: "Operativsystemsversion",
  },
  {
    property: "InvoiceReference",
    displayName: "Fakturareferens",
  },
  {
    property: "InstallationStatus",
    displayName: "Status",
  },
  {
    property: "PrimaryUserDistinguishedName",
    displayName: "Primäranvändare (DN)",
    onclick: routeToUserByPrimaryUserDN,
  },
  {
    property: "PrimaryUserUid",
    displayName: "Primäranvändare (UID)",
    onclick: routeToUserByPrimaryUserDN,
  },
  {
    property: "PrimaryUserSn",
    displayName: "Primäranvändare, efternamn",
    onclick: routeToUserByPrimaryUserDN,
  },
  {
    property: "PrimaryUserGivenName",
    displayName: "Primäranvändare, förnamn",
    onclick: routeToUserByPrimaryUserDN,
  },
  {
    property: "PrimaryUserNumberOf",
    displayName: "Antal primäranvändare",
  },

  // Don't show memberOf at all since that's what tables are for.
  // {
  //   property: "memberOf",
  //   displayName: "Medlem i",
  //   onclick: routeToGroupWithDN,
  // },

  // We don't have good displayNames for the following:
  // { property: "name", displayName: "" },
  // { property: "sn", displayName: "" },
  // { property: "uSNChanged", displayName: "uSNChanged" },
  // { property: "uSNCreated", displayName: "uSNCreated" },
];

export const devicegroupViewConfig = [
  {
    property: "displayName",
    displayName: "Visningsnamn",
  },
  {
    property: "description",
    displayName: "Beskrivning",
  },
  {
    property: "sn",
    displayName: "GruppID (SN)",
  },
  {
    property: "sAMAccountName",
    displayName: "GruppID (SAM)",
    onclick: routeToDevicesForGroupWithSAM,
  },
  {
    property: "cn",
    displayName: "GruppID (CN)",
  },
  {
    property: "distinguishedName",
    displayName: "GruppID (DN)",
    onclick: routeToDevicesForGroupWithDN,
  },
  {
    property: "whenChanged",
    displayName: "Gruppen ändrad",
  },
  {
    property: "whenCreated",
    displayName: "Gruppen skapad",
  },
  {
    property: "managedBy",
    displayName: "Administreras av",
    onclick: routeToUserWithDN,
  },

  // Don't show member at all since that's what tables are for.
  // {
  //   property: "member",
  //   displayName: "Gruppmedlemmar",
  //   onclick: routeToUserWithDN,
  // },

  // Todo: Proper display name for the following properties:
  // { property: "name", displayName: "name" },
  // { property: "objectGUID", displayName: "objectGUID" },
  // { property: "objectSid", displayName: "objectSid" },
  // { property: "uSNChanged", displayName: "uSNChanged" },
  // { property: "uSNCreated", displayName: "uSNCreated" },
];

export const deviceprimaryuserViewConfig = [
  {
    property: "distinguishedName",
    displayName: "Primäranvändare (DN)",
    onclick: routeToUserWithDN,
  },
  {
    property: "sn",
    displayName: "Primäranvändare, efternamn",
    onclick: routeToUserByDN,
  },
  {
    property: "givenName",
    displayName: "Primäranvändare, förnamn",
    onclick: routeToUserByDN,
  },
];

export const userFields = userViewConfig;
export const userColumns = userViewConfig;
export const groupFields = groupViewConfig;
export const groupColumns = groupViewConfig;
export const volumeFields = volumeViewConfig;
export const volumeColumns = volumeViewConfig;
export const volumeuserColumns = [{ property: "permissions", displayName: "Behörighet" }, ...userColumns];
export const deviceFields = deviceViewConfig;
export const deviceColumns = deviceViewConfig;
export const devicegroupColumns = devicegroupViewConfig;
export const deviceprimaryuserColumns = deviceprimaryuserViewConfig;

export function showFields(config, rpt, showmap) {
  const i = rpt.split("_")[1] || "0";
  return _.filter(config, item => showmap[item.property][i] === "y");
}

export function showColumns(config, rpt, showmap) {
  const i = rpt.split("_")[1] || "0";
  return _.map(
    _.filter(config, item => showmap[item.property][i] !== "n"),
    item => ({ ...item, show: showmap[item.property][i] === "y" }),
  );
}
