<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>CR-2.2 Förteckning över ChromeOS enheter</h1>
            </div>
            <div class="row-flex space-top-sm">
              <RFilter configName="veda" v-bind:addEmpty="true" v-model="filters.veda.selected" width="370" />
              <RFilter configName="gsuite_chromeos_firmwareversion" v-model="filters.firmwareversion.selected" />
              <DatePeriodSelector style="margin-left: auto" v-model="filters.SyncPeriod" name="Senaste synkronisering" qpsParamName="syncdate" />
              <RFilter configName="gsuite_chromeos_model" v-model="filters.model.selected" />
              <RFilter configName="gsuite_chromeos_osversion" v-model="filters.osversion.selected" />
              <DatePeriodSelector style="margin-left: auto" v-model="filters.EnrollPeriod" name="Senaste registrering" qpsParamName="enrolldate" />
              <RFilter configName="invoice_reference" v-model="filters.invoicereference" />
            </div>
            <div class="row-flex space-top-sm"></div>
          </div>
        </div>
        <div class="row-4-col space-top">
          <ValueBox class="row-item" :value="chartData.deviceCount" text="Enheter" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
          <ValueBox class="row-item" :value="chartData.latestOsCount" text="Har senaste OS versionen" valueColor="#5d237d" textColor="#5d237d" backgroundColor="#f5f3ee" />
        </div>
        <div class="row-1-col space-top">
          <div class="row-item">
            <h2>OS version</h2>
            <BarChart
              class="padding-top-sm"
              :chartData="chartData.operatingSystemBarChartData"
              :displayXGridLines="false"
              :displayYGridLines="true"
              fontColor="#444444"
              gridLinesColor="#F1F1F1"
              :enableSorting="true"
              :xSortingFormat="operatingSystemxSortingFormat"
            />
          </div>
        </div>
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="reportData" :columns="columns" :disableFiltering="false" csvFilename="CR-2-2 Export - Förteckning över ChromeOS enheter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

function routeToCr1(arg) {
  arg.root.$router.push({
    path: "/cr1",
    query: { sn: arg.object.Serialnumber, cos: arg.object.CosNumber },
  });
}

export default {
  name: "Cr2-2",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      reportData: [],
      crossfilter: null,
      chartData: null,
      latestOsVersion: null,
      columns: [
        {
          property: "VedaName",
          displayName: "Förvaltningsnamn",
          show: true,
        },
        {
          property: "VedaId",
          displayName: "FörvaltningsId",
          show: false,
        },
        {
          property: "InvoiceReference",
          displayName: "Fakturareferens",
          show: true,
        },
        {
          property: "UserPrincipalName",
          displayName: "Senaste användare",
          show: true,
        },
        {
          property: "UserId",
          displayName: "Senaste användare UID",
          show: false,
        },
        {
          property: "CosNumber",
          displayName: "COS",
          show: true,
          onclick: routeToCr1,
        },
        {
          property: "Serialnumber",
          displayName: "Serienummer",
          show: true,
          onclick: routeToCr1,
        },
        {
          property: "Status",
          displayName: "Status",
          show: false,
        },
        {
          property: "LastEnroll",
          displayName: "Senaste registrering",
          show: false,
        },
        {
          property: "LastSynchronization",
          displayName: "Senaste synkronisering",
          show: true,
        },
        {
          property: "Model",
          displayName: "Model",
          show: true,
        },
        {
          property: "OperatingSystemVersion",
          displayName: "OS version",
          show: true,
        },
        {
          property: "FirmwareVersion",
          displayName: "Firmwareversion",
          show: false,
        },
        {
          property: "PlatformVersion",
          displayName: "Plattformsversion",
          show: false,
        },
        {
          property: "AutoUpdateExpirationDate",
          displayName: "Slutdatum för automatisk uppdatering",
          show: false,
        },
        {
          property: "GsuiteOUPath",
          displayName: "Sökväg OU-grupp",
          show: true,
        },
      ],
      filters: {
        platformversion: { selected: [] },
        osversion: { selected: [] },
        firmwareversion: { selected: [] },
        model: { selected: [] },
        veda: { selected: [] },
        EnrollPeriod: [],
        SyncPeriod: [],
        invoicereference: "",
      },
    };
  },
  watch: {
    "filters.EnrollPeriod"() {
      this.crossfilter.filterDimensionArrayContains("LastEnrollDimension", this.filters.EnrollPeriod);
      this.calculateReportComponentsData();
    },
    "filters.SyncPeriod"() {
      this.crossfilter.filterDimensionArrayContains("LastSynchronizationDimension", this.filters.SyncPeriod);
      this.calculateReportComponentsData();
    },
    "filters.veda.selected"() {
      this.crossfilter.filterDimensionArrayContains("VedaDimension", this.filters.veda.selected);
      this.calculateReportComponentsData();
    },
    "filters.model.selected"() {
      this.crossfilter.filterDimensionArrayContains("ModelDimension", this.filters.model.selected);
      this.calculateReportComponentsData();
    },
    "filters.osversion.selected"() {
      this.crossfilter.filterDimensionArrayContains("OsVersionDimension", this.filters.osversion.selected);
      this.calculateReportComponentsData();
    },
    "filters.firmwareversion.selected"() {
      this.crossfilter.filterDimensionArrayContains("FirmwareVersionDimension", this.filters.firmwareversion.selected);
      this.calculateReportComponentsData();
    },
    "filters.platformversion.selected"() {
      this.crossfilter.filterDimensionArrayContains("PlatformVersionDimension", this.filters.platformversion.selected);
      this.calculateReportComponentsData();
    },
    "filters.invoicereference"() {
      this.crossfilter.filterDimensionExact("InvoiceReferenceDimension", this.filters.invoicereference);
      this.calculateReportComponentsData();
    },
  },
  methods: {
    operatingSystemxSortingFormat(arr) {
      return arr.map(part =>
        part
          .split(".")
          /* eslint-disable-next-line no-magic-numbers */
          .map(versionPart => `0000${versionPart}`.slice(-4))
          .join("."),
      );
    },
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.reportData);

      this.crossfilter.createDimensionByProperty("VedaDimension", "VedaId");

      this.crossfilter.createDimensionByMomentOnProperty("LastEnrollDimension", "LastEnroll", "YYYY-MM-DD", "YYYY-MM-DD");

      this.crossfilter.createDimensionByMomentOnProperty("LastSynchronizationDimension", "LastSynchronization", "YYYY-MM-DD", "YYYY-MM-DD");

      this.crossfilter.createDimensionByProperty("ModelDimension", "Model");

      this.crossfilter.createDimensionByProperty("OsVersionDimension", "OperatingSystemVersion");

      this.crossfilter.createDimensionByProperty("OsVersionChartDimension", "OperatingSystemVersion");
      this.crossfilter.createDimensionByProperty("FirmwareVersionDimension", "FirmwareVersion");
      this.crossfilter.createDimensionByProperty("PlatformVersionDimension", "PlatformVersion");
      this.crossfilter.createDimensionByProperty("InvoiceReferenceDimension", "InvoiceReference");
    },

    calculateReportComponentsData() {
      this.reportData = this.crossfilter.allFiltered();

      const operatingSystemBarChartData = this.crossfilter.barCharReduceCount({ dimensionName: "OsVersionChartDimension", reverse: true });

      const latestOsCount = this.crossfilter.countOnValue("OsVersionChartDimension", this.latestOsVersion, true);
      const deviceCount = this.reportData.length;

      const lastOsQuote = latestOsCount / deviceCount;

      const chartData = {
        deviceCount: deviceCount.toString(),
        operatingSystemBarChartData,
        latestOsCount: `${isNaN(lastOsQuote) ? "0" : (lastOsQuote * 100).toFixed(0)}%`,
      };

      this.chartData = chartData;
    },
    // enrollDatePeriodFilterChanged(activeDatePeriodFilter) {
    //   this.filters.EnrollPeriod.start = activeDatePeriodFilter.start;
    //   this.filters.EnrollPeriod.end = activeDatePeriodFilter.end;

    //   var days = momentHandler.daysBetweenDates({
    //     startDateString: this.filters.EnrollPeriod.start, - ?
    //     endDateString: this.filters.EnrollPeriod.end,
    //     inputFormat: "YYYY-MM-DD",
    //     labelFormat: "YYYY-MM-DD",
    //     valueFormat: "YYYY-MM-DD",
    //   });

    //   this.crossfilter.filterDimensionArrayContains(
    //     "LastEnrollDimension",
    //     days.map(item => item.value),
    //   );

    //   this.calculateReportComponentsData();
    // },
    // syncDatePeriodFilterChanged(activeDatePeriodFilter) {
    //   this.filters.SyncPeriod.start = activeDatePeriodFilter.start;
    //   this.filters.SyncPeriod.end = activeDatePeriodFilter.end;
    //   var days = momentHandler.daysBetweenDates({
    //     startDateString: this.filters.SyncPeriod.start,
    //     endDateString: this.filters.SyncPeriod.end,
    //     inputFormat: "YYYY-MM-DD",
    //     labelFormat: "YYYY-MM-DD",
    //     valueFormat: "YYYY-MM-DD",
    //   });

    //   this.crossfilter.startsWithfilterDimensionArray(
    //     "LastSynchronizationDimension",
    //     days.map(item => item.value),
    //   );
    //   this.calculateReportComponentsData();
    // },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/gsuite/ChromeDevices"), this.$http.get("/api/reporting/gsuite/filter/ChromeOsOperatingSystemVersion")]).then(
      this.$http.spread((gsuiteDevicesResponse, gsuiteOsVersionsResponse) => {
        this.reportData = gsuiteDevicesResponse.data;
        const toprecord = gsuiteOsVersionsResponse.data[0] || {};
        this.latestOsVersion = toprecord.value || "";
        this.initCrossfilter();
        this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>

<style>
#canvas-barchart {
  height: 250px !important;
  width: auto !important;
}
</style>
