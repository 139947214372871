

export function single(val) {
  const len = val.length;
  if (len === 0) {
    return "none";
  }
  if (len > 1) {
    return "too many";
  }
  return val[0];
}
